import {IStore} from '../interfaces/store';

// interface IStoreProps {
//   trafficRoutes: IRoute[];
// }

export function trafficRoutesSelector(
    state: IStore
  ) {
  return (state.traffic.routes);
}

import React, {useEffect, useRef, useState} from 'react';

import EcologyOverlayInfowindow from './ecology-overlay-infowindow';
import {IEcologyLayer} from '../../interfaces/ecology';
import {Ecologies} from '../../constants/ecologies';
import {ViewIds} from '../../constants/views';
import fetchEcologyLayerInformation, {
  removeEcologyLayerInformation
} from '../../actions/ecology/fetch-ecology-layer-information';

import {TileWMS, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer, Layer} from 'ol/layer';
import {Coordinate} from 'ol/coordinate';
import Overlay from 'ol/Overlay';

import {Style, Stroke} from 'ol/style';
import {GeoJSON} from 'ol/format';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

import {useDispatch, useSelector} from 'react-redux';
import ecologyLayerInformationSelector from '../../selectors/ecology-layer-information';

import {toLonLat} from "ol/proj";

interface IProps {
  ecologyLayers: Map<ViewIds, IEcologyLayer>;
  view: ViewIds;
  isEcologyView: boolean;
  mapCanvas: ol.Map | null;
  mapPopup: div;
}

const clickHandler = (
  dispatch,
  setToolTipPosition,
  mapCanvas,
  ecology
): ((event: any) => void) => (event): void => {
  dispatch(removeEcologyLayerInformation());

  const layers = ecology.data.layers
    .map(({id}) => `projectcheck:${id}`)
    .join(',');
  const {width, height} = mapCanvas.getTargetElement().getBoundingClientRect();

  setToolTipPosition(event.coordinate);
  const extentMerkator = mapCanvas.getView().calculateExtent();
  const extentWGS84 = toLonLat([extentMerkator[0],extentMerkator[1]]).concat(toLonLat([extentMerkator[2],extentMerkator[3]]));
  dispatch(
    fetchEcologyLayerInformation({
      bounds: extentWGS84,
      layers,
      x: event.pixel[0],
      y: event.pixel[1],
      width,
      height
    })
  );
};

const EcologyOverlay = ({
  isEcologyView,
  view,
  mapCanvas
}: IProps): JSX.Element | null => {
  const ecology = Ecologies.get(view);
  const dispatch = useDispatch();
  const [tooltipPosition, setToolTipPosition] = useState<Coordinate>([0, 0]);
  const ecologyLayers = useRef<Layer[] | VectorLayer | null>(null);
  const listener = useRef<EventListener | null>(null);
  const data = useSelector(ecologyLayerInformationSelector);
  const [infowindow, setInfowindow] = useState<Overlay | null>(null);

  useEffect((): (() => void) => {
    if (ecology && mapCanvas && isEcologyView) {
      if (ecology.serviceType === 'wms') {
        const layers: Layer[] = [];
        for (var l of ecology.data.layers) {
            layers.push(
                new TileLayer({
                    source: new TileWMS({
                        url: ecology.data.url, 
                        params: {'LAYERS': l.id, 'TILED': true}
                    }),
                    title: l.name,
                    type: 'ecology',
                    crossOrigin: 'anonymous'
                })
            )
        }
        ecologyLayers.current = layers;
        for (var m of layers) {
            mapCanvas.addLayer(m);
        }
        if (!listener.current) {
          listener.current = clickHandler(dispatch, setToolTipPosition, mapCanvas, ecology);
          mapCanvas.on(
            'click',
            listener.current
          )
        }
      }
      else {
        const highvoltageStyle = new Style({
          stroke: new Stroke({
              color: "black",
              //colors.primaryColor
              width: 3.5, 
              //l.data.count*3,
              lineCap: 'round',
              lineJoin: 'bevel'
          })
        })
      
        ecologyLayers.current = new VectorLayer({
            source: new VectorSource({
              format: new GeoJSON(),
              url: function (extent) {
                return (
                  ecology.data.url + 
                  'service=WFS&' +
                  'version=1.1.0&request=GetFeature&typename=' + 
                  'projektcheck_wfs:51005_ax_leitung' + 
                  '&outputFormat=application/json&srsname=EPSG:3857&' +
                  'bbox=' +
                  extent.join(',') +
                  ',EPSG:3857'
                );
              },
              strategy: bboxStrategy,
            }),
            opacity: 0.7,
            title: 'Hochspannungsfreileitungen',
            style: highvoltageStyle
        });
        mapCanvas.addLayer(ecologyLayers.current);
      }

    }
    
    return (): void => {
      if (ecologyLayers.current && mapCanvas) {
        if (ecologyLayers.current instanceof TileWMS || ecologyLayers.current instanceof VectorLayer) {
            mapCanvas.removeLayer(ecologyLayers.current);
        }
        else {
            for (var l of ecologyLayers.current) {
                mapCanvas.removeLayer(l);
            }
        }
        ecologyLayers.current = null;
      }

      if (listener.current) {
        mapCanvas.un(
            'click',
            listener.current
        )
        listener.current = null;
      }
      dispatch(removeEcologyLayerInformation());
    };
  }, [ecology, mapCanvas, isEcologyView]);
  

  if (
    data && mapCanvas && isEcologyView
    //&& mapPopup && mapPopup.current
    ) {
    return (
      <EcologyOverlayInfowindow
        data={data}
        mapCanvas={mapCanvas}
        position={tooltipPosition}
        infowindow={infowindow},
        setInfowindow={setInfowindow}
      />
    );
  }

  return null;
};

export default EcologyOverlay;

import {IMunicipality} from '../interfaces/municipality';

type rawMunicipalities = [string, string, number, number, number, number]; // eslint-disable-line no-unused-vars, max-len
const enum keys {
  id,
  name,
  north,
  east,
  south,
  west
}

/**
 * Load the municipalities JSON and return those with a Promise
 */
export default function(): Promise<IMunicipality[]> {
  return new Promise((resolve, reject) => {
    const basename = process.env.BASE_NAME;
    const url = `${basename}/data/municipalities.json`;

    fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then((json: any) => {
        const municipalities: IMunicipality[] = json.map(
          (rawMunicipality: rawMunicipalities) => ({
            id: rawMunicipality[keys.id],
            name: rawMunicipality[keys.name],
            bounds: {
              north: rawMunicipality[keys.north],
              east: rawMunicipality[keys.east],
              south: rawMunicipality[keys.south],
              west: rawMunicipality[keys.west]
            }
          })
        );

        resolve(municipalities);
      })
      .catch(error => reject(error));
  });
}

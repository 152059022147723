import {IAction} from '../../interfaces/action';
import {SELECT_MUNICIPALITY} from '../../constants/actions';

/**
 * Reset the municipality
 */
export default function(): IAction {
  return {
    type: SELECT_MUNICIPALITY,
    municipality: null
  };
}

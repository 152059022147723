import {
  Industries,
  colorMap,
  labelMap
} from '../../../constants/workplace-development';
import React, {useEffect} from 'react';
import sizeme from 'react-sizeme';
import {RadialChart} from 'react-vis';

import Legend from '../../shared/legend';
import Loading from '../../shared/loading/loading';

interface IRadialData {
  key: Industries;
  angle: number;
  color: string;
  label: string;
  legendLabel: string;
}

interface IProps {
  data: Map<Industries, number> | null;
  size: {width: number; height: number};
}

import styles from './styles.styl';

/**
 * Pie Chart for the workplace structure
 */
const PieChart = ({data, size}: IProps): JSX.Element => {
  if (!data) {
    return <Loading />;
  }

  const radialData: IRadialData[] = [];
  let total = 0;

  data.forEach(value => {
    total += value;
  });

  data.forEach((value: number, key: Industries) => {
    radialData.push({
      key,
      color: colorMap.get(key) || '',
      legendLabel: labelMap.get(key) || '',
      label: `${Math.round((value / total) * 100)}%`,
      angle: value
    });
  });

  useEffect(() => {
    document
      .querySelectorAll('.rv-xy-plot__series--label-text')
      .forEach(text => {
        //@ts-ignore Property 'getBBox' does not exist on type 'Element'.
        const SVGRect = text.getBBox();
        const rect = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'rect'
        );

        rect.setAttribute('x', (Number(SVGRect.x) - 5).toString());
        rect.setAttribute('y', (Number(SVGRect.y) - 5).toString());
        rect.setAttribute('width', SVGRect.width + 10);
        rect.setAttribute('height', SVGRect.height + 10);
        rect.setAttribute('ry', '5');
        rect.setAttribute('rx', '5');
        rect.setAttribute('fill', '#f2f2f2');
        rect.setAttribute('stroke', '#ccc');

        if (text.parentElement) {
          text.parentElement.insertBefore(rect, text);
        }
      });
  });
  return (
    <>
      <p className="intro">Mittlere Branchenstruktur vergleichbarer Flächen</p>

      <div className={styles.pieContainer}>
        <RadialChart
          width={size.width - 450}
          height={200}
          margin={{left: 60, right: 20, top: 10, bottom: 60}}
          colorType="literal"
          data={radialData}
          showLabels
        />

        <Legend
          className={styles.pieLegend}
          entries={radialData.map(entry => ({
            color: entry.color,
            description: entry.legendLabel
          }))}
        />
      </div>
    </>
  );
};

export default sizeme()(PieChart);

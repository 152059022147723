import {createEmpty} from 'ol/extent';
import {transformExtent, get} from 'ol/proj';

export const zoom = 7;

export const center: ol.coordinate = [10.7837579, 51.5210461]; //lonlat for ol

// export const bounds: ol.extent = transformExtent([6,48,15,54], get('EPSG:4326'), get('EPSG:3857'));
export const bounds: ol.extent = [5,46,15,56];

export const styles: Object[] = [
  {featureType: 'poi.attraction', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.business', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.medical', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.school', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}]}
];
import {IAction} from '../../interfaces/action';
import {IMunicipality} from '../../interfaces/municipality';

import {
  FETCH_MUNICIPALITIES,
  FETCH_MUNICIPALITIES_SUCCESS,
  FETCH_MUNICIPALITIES_ERROR
} from '../../constants/actions';
import fetchApi from '../../apis/fetch-municipalities';

/**
 * Fetching the municipalities
 */
export function fetchMunicipalities(): IAction {
  return {
    type: FETCH_MUNICIPALITIES
  };
}

/**
 * Success while fetching the municipalities
 */
export function fetchAlarmsSuccess(municipalities: IMunicipality[]): IAction {
  return {
    type: FETCH_MUNICIPALITIES_SUCCESS,
    municipalities
  };
}

/**
 * Error while fetching the municipalities
 */
export function fetchAlarmsError(error: Error): IAction {
  return {
    type: FETCH_MUNICIPALITIES_ERROR,
    error
  };
}

/**
 * Fetch the municipalities
 */
export default function(
  fetchMunicipalitiesRemotely: () => Promise<IMunicipality[]> = fetchApi
): (dispatch: any) => Promise<any> {
  return async (dispatch): Promise<any> => {
    dispatch(fetchMunicipalities());

    try {
      const municipalities = await fetchMunicipalitiesRemotely();
      return dispatch(fetchAlarmsSuccess(municipalities));
    } catch (error) {
      return dispatch(fetchAlarmsError(error));
    }
  };
}

// Global polyfills to support IE11
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/array/flat';
import 'core-js/features/number/is-finite';

import 'whatwg-fetch';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import store from './reducers/store';

import App from './components/app';

import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://matomo.ils-geomonitoring.de/',
  siteId: process.env.MATOMO_ID,
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST',
    logAllContentBlocksOnPage: true
  }
});

render(
    <MatomoProvider value={instance}>
        <Provider store={store}>
            <App />
        </Provider>
    </MatomoProvider>,
    document.querySelector('.app-container')
);

import React from 'react';
import {useSelector} from 'react-redux';

import {
  selectFollowUpCosts,
  compareValueSelector,
  costsDataByObject
} from '../../../selectors/followup-costs';
import {areaTypeIdSelector, totalUnitsSelector} from '../../../selectors/area';

import totalWorkplacesSelector from '../../../selectors/total-workplaces';

import FollowupCosts from './followup-costs';

/**
 * The followup costs container connecting to the store
 */
const FollowupCostsContainer = (): JSX.Element => {
  const areaTypeId = useSelector(areaTypeIdSelector);
  const compareCosts = useSelector(compareValueSelector);
  const data = useSelector(selectFollowUpCosts);
  const totalApartments = useSelector(totalUnitsSelector);
  const totalWorkplaces = useSelector(totalWorkplacesSelector);
  const followupCostsDataByObject = useSelector(costsDataByObject);

  const followUpProps = {
    areaTypeId,
    compareCosts,
    data,
    followupCostsDataByObject,
    totalApartments,
    totalWorkplaces
  };

  return <FollowupCosts {...followUpProps} />;
};

export default FollowupCostsContainer;

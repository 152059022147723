import {IAction} from '../interfaces/action';
import {
  FETCH_ISOCHRONES,
  FETCH_ISOCHRONES_SUCCESS,
  SELECT_AREA_SHAPE,
  DELETE_AREA_SHAPE,
  SELECT_MUNICIPALITY
} from '../constants/actions';

/**
 * Initial state
 */
const initialState: GeoJSON.FeatureCollection<
GeoJSON.MultiPolygon
> | null = null;

/**
 * Handle the isochrones
 */
export default function(
  state: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> | null = initialState,
  action: IAction
): GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> | null {
  switch (action.type) {
    case FETCH_ISOCHRONES:
      return initialState;

    case FETCH_ISOCHRONES_SUCCESS:
      return action.isochrones || state;

    case SELECT_AREA_SHAPE:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    case SELECT_MUNICIPALITY:
      return initialState;

    default:
      return state;
  }
}

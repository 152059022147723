import {IArea, IStore} from '../interfaces/store';
import {IMunicipality} from '../interfaces/municipality';

export function municipalitiesSelector(state: IStore): Array<IMunicipality> {
  return state.municipalities;
}

export function municipalitySelector(state: IStore): IMunicipality | null {
  return state.municipality;
}

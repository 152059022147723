import {IAction} from '../interfaces/action';
import {OPEN_LEGEND, CLOSE_LEGEND} from '../constants/actions';

/**
 * Initial state
 */
const initialState = false;

export default function(
  state: boolean = initialState,
  action: IAction
): boolean {
  switch (action.type) {
    case OPEN_LEGEND:
      return true;

    case CLOSE_LEGEND:
      return false;

    default:
      return state;
  }
}

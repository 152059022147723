import React, {ReactNode} from 'react';
import styles from './styles.styl';

type IProps = {
  title: ReactNode;
  children: ReactNode;
};

const PrintPage = ({title, children}: IProps): JSX.Element => (
  <section>
    <header>
      <h2>{title}</h2>
    </header>
    <article>{children}</article>
    <footer>
      <img
        src="./images/logo.png"
        alt="Projekt-Check — Flächenplanungen vorprüfen"
        className={styles.footerLogo}
      />
    </footer>
  </section>
);

export default PrintPage;

import {IAction} from '../../interfaces/action';
import {SELECT_AREA_INFLUX} from '../../constants/actions';

/**
 * Select an area influx
 */
export default function(areaInflux: number): IAction {
  return {
    type: SELECT_AREA_INFLUX,
    areaInflux
  };
}

import React from 'react';
import {useSelector} from 'react-redux';
import {informationVideos} from '../../constants/information-videos';
import {informationContents} from '../../constants/information-contents';
import {ViewTitles} from '../../constants/views';
import {viewSelector} from '../../selectors/view';
import {areaTypeIdSelector} from '../../selectors/area';

import InformationTabs from './information-tabs';
import Information from './information';

const InformationContainer = (): JSX.Element | null => {
  const areaTypeId = useSelector(areaTypeIdSelector);
  const view = useSelector(viewSelector);

  if (!areaTypeId) {
    return null;
  }
  const informationContent = informationContents[`${view}#${areaTypeId}`];

  if (!informationContent) {
    return null;
  }
  const title = ViewTitles.get(view);
  const informationVideo = informationVideos.get(`${view}#${areaTypeId}`);

  return informationVideo ? (
    <InformationTabs
      content={informationContent}
      videoId={informationVideo}
      view={view}
      title={title}
    />
  ) : (
    <Information title={title} content={informationContent} />
  );
};

export default InformationContainer;

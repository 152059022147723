import {IAction} from '../../interfaces/action';
import {
  SELECT_AREA_SIZE,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE
} from '../../constants/actions';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected area size
 */
export default function(
  state: number | null = initialState,
  action: IAction
): number | null {
  switch (action.type) {
    case SELECT_AREA_SIZE:
      return action.areaSize ? action.areaSize : null;

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}

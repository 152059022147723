/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {useStore, useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Icon from '../../shared/icon';
import Button from '../../shared/button/button';
import {AreaTypeIds} from '../../../constants/area-types';
import {areaTypeIdSelector} from '../../../selectors/area';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import addTrafficRoute from '../../../actions/traffic/add-traffic-route'; //is this equivalent to "import {addTrafficRoute} ..." ???

import styles from './print-dialog.styl';

interface IPrintDialogProps {
  setShowSidebarText: (selected: boolean) => void;
}

const getMethodologyPdfFilename = (areaTypeId): string | null => {
  switch (areaTypeId) {
    case AreaTypeIds.Residential:
      return 'Methodikerlaeuterung_WebCheck_Wohnen.pdf';
    case AreaTypeIds.Industrial:
      return 'Methodikerlaeuterung_WebCheck_Gewerbe.pdf';
    case AreaTypeIds.Retail:
      return 'Methodikerlaeuterung_WebCheck_Einzelhandel.pdf';
  }
  return null;
};

const PrintDialog = ({
  setShowSidebarText
}: IPrintDialogProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const state = useStore();
  const areaTypeId = useSelector(areaTypeIdSelector);

  const slimTraffic = (routes) => {
    for (const route of routes) {
      route.destination
      for (const pathId in route.path) {
          const lats = route.path[pathId].values_.geometry.flatCoordinates.filter((v, i, ar) => (i % 2 == 0));
          const lons = route.path[pathId].values_.geometry.flatCoordinates.filter((v, i, ar) => (i % 2 == 1));
          const newCoords = []
          for (const i in lats) {
            newCoords.push([lats[i],lons[i]])
          }
          route.path[pathId] = newCoords;
      }
    }
    return routes;
  }

  const showPrintView = (): void => {
    // eslint-disable-next-line
    const {ecologyLayers, isLoading, ...rest} = state.getState();

/*    if (rest.traffic.routes[0] && rest.traffic.routes[0].geojsonpath) {
      rest.traffic.routes = slimTraffic(rest.traffic.routes);
    }*/
    if (rest.traffic.routes[0] && rest.traffic.routes[0].geojsonpath) {
        window.localStorage.setItem('printData', JSON.stringify(rest));
        window.open(
            '#/print',
            //'_self' //for devs
        );
    } else {
        if (rest.traffic.routes[0] && rest.traffic.routes[0].destination.hasOwnProperty('lat')) {
            console.log('this is an old google maps file, we use our converter');
        }
        else {
            rest.traffic.routes = [];
            for (const route of rest.traffic.routes) {
              dispatch(addTrafficRoute(rest.traffic.routes, rest.area.center, route.destination)); //runs routes through ors (not load it by construction of LineString Features) TODO
            }
            window.localStorage.setItem('printData', JSON.stringify(rest));
            window.open(
                '#/print',
                '_self' //for devs
            );
        }
    }
  };

  useEffect(() => {
    setShowSidebarText(true);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <header className={styles.header}>
          <div className={styles.backButton}>
            <Button
              className={styles.icon}
              variant="icon"
              onClick={(): void => {
                setShowSidebarText(false);
                navigate(-1);
              }}>
              <ArrowBackIcon />
            </Button>
          </div>
          <div>zurück</div>
        </header>
        <div className={styles.content}>
          <h1>Planung als PDF ausdrucken</h1>

          <p>
            Hier können Sie Ihre Planung und Analyseergebnisse in einem
            PDF-Dokument herunterladen.
          </p>
          <p className={styles.printLandscapeDisclaimer}>
            Die Formatierung dieses PDF ist auf ein Querformat ausgelegt. Bitte
            passen Sie ggf. Ihre Druckeinstellungen entsprechend an. Ergänzend
            können Sie sich ein PDF-Dokument mit methodischen Erläuterungen und
            Quellenangaben herunterladen.
          </p>
          <p>
            Achtung: Wenn Sie (oder andere) später an Ihrer Planung
            weiterarbeiten wollen, nutzen Sie bitte zusätzlich die Funktion
            „Speichern / Exportieren“, um eine wieder einlesbare Projektdatei zu
            erzeugen. Die hier erzeugten PDF-Dokumente ermöglichen hingegen kein
            späteres Wiedereinlesen Ihrer Planung.
          </p>
          <div className={styles.buttonGroup}>
            <Button
              white
              grow
              onClick={(): void => {
                showPrintView();
              }}>
              <Icon type="printer" /> Planung in PDF drucken
            </Button>
            <Button
              white
              grow
              onClick={(): void => {
                const filename = getMethodologyPdfFilename(areaTypeId);
                if (filename) {
                  saveAs(`docs/${filename}`, filename);
                }
              }}>
              <Icon type="download" /> Methodik herunterladen
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintDialog;

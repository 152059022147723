export const steps = {
  municipality: {
    id: 'municipality',
    number: 1,
    name: 'Ort'
  },
  area: {
    id: 'area',
    number: 2,
    name: 'Fläche'
  },
  details: {
    id: 'details',
    number: 3,
    name: 'Flächen-Detail'
  },
  usage: {
    id: 'usage',
    number: 4,
    name: 'Nutzung'
  },
  done: {
    number: 5
  }
};

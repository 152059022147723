import {IAction} from '../../interfaces/action';
import {SET_AREA_INFLUX_SELLINGSPACE} from '../../constants/actions';

/**
 * Select an area influx sellingspace
 */
export default function(areaInflux: number): IAction {
  return {
    type: SET_AREA_INFLUX_SELLINGSPACE,
    areaInflux
  };
}

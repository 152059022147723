import {IAction} from '../../interfaces/action';
import {
  FETCH_MUNICIPALITY_DATA,
  FETCH_MUNICIPALITY_DATA_SUCCESS,
  FETCH_MUNICIPALITY_DATA_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for municipality data
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_MUNICIPALITY_DATA:
      return true;

    case FETCH_MUNICIPALITY_DATA_SUCCESS:
      return false;

    case FETCH_MUNICIPALITY_DATA_ERROR:
      return false;

    default:
      return state;
  }
}

import React from 'react';

import {useSelector} from 'react-redux';
import {areaSelector} from '../../../selectors/area';

import styles from './styles.styl';

const PrintIntro = (): JSX.Element => {
  const now = new Date();
  const area = useSelector(areaSelector);

  return (
    <header className={styles.container}>
      <img
        src="./images/logo.png"
        alt="Projekt-Check — Flächenplanungen vorprüfen"
      />
      <div className={styles.titleContainer}>
        <p className={styles.subTitle}>Analyse für Projekt</p>
        <h1>{area.name}</h1>
        <p className={styles.subTitle}>
          Erstellungsdatum: {now.toLocaleDateString('de-DE')}
        </p>
      </div>
      <div className={styles.footer}>
        Hinweis: Methodische Erläuterungen zu den nachfolgenden Analysen finden
        sich in einem gesonderten Dokument. Dieses finden Sie in Web-Check unter
        der Option &quot;PDF drucken&quot;.
      </div>
    </header>
  );
};

export default PrintIntro;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import AreaName from './area-name/area-name';
import AreaSize from './area-size/area-size';

import {areaSelector} from '../../../selectors/area';

import selectAreaName from '../../../actions/area/select-area-name';
import selectAreaSize from '../../../actions/area/select-area-size';

import styles from './configuration-details.styl';
import AreaShapeDeleteButton from '../area-shape-delete-button/area-shape-delete-button';

const ConfigurationDetails = (): JSX.Element => {
  const area = useSelector(areaSelector);

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <AreaName
          areaName={area.name}
          onChange={(name): void => {
            dispatch(selectAreaName(name));
          }}
        />
        <AreaSize
          size={area.size}
          onChange={(size): void => {
            dispatch(selectAreaSize(size));
          }}
        />
        <AreaShapeDeleteButton />
      </div>
    </div>
  );
};

export default ConfigurationDetails;

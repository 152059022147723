import {IMunicipality} from '../../interfaces/municipality';
import {IAction} from '../../interfaces/action';
import {FETCH_MUNICIPALITIES_SUCCESS} from '../../constants/actions';

/**
 * Initial state
 */
const initialState: IMunicipality[] = [];

/**
 * Handle the selected municipalities
 */
export default function(
  state: IMunicipality[] = initialState,
  action: IAction
): IMunicipality[] {
  switch (action.type) {
    case FETCH_MUNICIPALITIES_SUCCESS:
      return action.municipalities || state;
    default:
      return state;
  }
}

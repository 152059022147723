import React from 'react';
import {IFollowupCosts} from '../../../interfaces/followup-costs';
import {IGraph} from '../../../interfaces/graph';
import {IGraphDatum} from '../../../interfaces/graph-datum';
import {AreaTypeIds} from '../../../constants/area-types';
import {FollowupCostsPhases} from '../../../constants/followup-costs';
import {
  NetworkElementGroupIds,
  networkElementGroupLabels
} from '../../../constants/network-elements';
import sizeme from 'react-sizeme';
import equal from 'deep-equal';
import Tooltip from '@mui/material/Tooltip';
import Icon from '../../shared/icon';
import IconButton from '@mui/material/IconButton';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint
} from 'react-vis';

import Legend from '../../shared/legend';
import Loading from '../../shared/loading/loading';
import colors from '../../../constants/colors';
import getMaxValue from '../../../libs/get-max-stacked-graphs-value';

interface IProps {
  areaTypeId: AreaTypeIds | null;
  data: Map<NetworkElementGroupIds, IFollowupCosts> | null;
  size: {width: number; height: number};
  print?: boolean;
}
interface IState {
  domain: [number, number];
  graphs: IGraph<FollowupCostsPhases, number, string>[];
  hoveredValue: IGraphDatum<number, number> | null;
}

/**
 * Mapping of colors to the cost phases
 */
export const colorMap = new Map<FollowupCostsPhases, string>();
colorMap.set(FollowupCostsPhases.Initial, colors.primaryColor);
colorMap.set(FollowupCostsPhases.Maintenance, colors.lightRed);

/**
 * Mapping of labels to the cost phases
 */
export const labelMap = new Map<FollowupCostsPhases, string>();
labelMap.set(FollowupCostsPhases.Initial, 'Erstmalige Herstellung');
labelMap.set(
  FollowupCostsPhases.Maintenance,
  'Betrieb, Unterhaltung und Erneuerung für die nächsten 25 Jahre'
);

/**
 * Chart for the followup costs by phase
 */
class ByCostPhase extends React.Component<IProps, IState> {
  /**
   * The initial component state
   */
  state: IState = {
    domain: [0, 0],
    graphs: [],
    hoveredValue: null
  };

  /**
   * Setup the component
   */
  constructor(props: IProps) {
    super(props);

    this.handleValueMouseOver = this.handleValueMouseOver.bind(this);
    this.handleValueMouseOut = this.handleValueMouseOut.bind(this);
  }

  /**
   * The component will be mounted
   */
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.updateState(this.props);
  }

  /**
   * There are new props on their way
   */
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    this.updateState(nextProps);
  }

  /**
   * There are new props on their way
   */
  updateState(props: IProps): void {
    if (!props.data) {
      return;
    }

    const graphs = this.calculateGraphs(props.data);
    const maxValue: number = getMaxValue(graphs, 'x');
    const roundTo: number = maxValue > 10000 ? 10000 : 1000;
    const minDomain = 0;
    const maxDomain: number = Math.floor(maxValue / roundTo + 1) * roundTo;

    this.setState({
      domain: [minDomain, maxDomain],
      graphs
    });
  }

  /**
   * Calculate the graphs from the data
   */
  calculateGraphs(
    rawData: Map<NetworkElementGroupIds, IFollowupCosts>
  ): IGraph<FollowupCostsPhases, number, string>[] {
    const graphs: IGraph<FollowupCostsPhases, number, string>[] = [];
    const buckets = new Map<
      FollowupCostsPhases,
      IGraphDatum<number, string>[]
    >();

    rawData.forEach(
      (costs: IFollowupCosts, id: NetworkElementGroupIds): void => {
        costs.forEach((value: number, costPhase: FollowupCostsPhases): void => {
          const bucket: IGraphDatum<number, string>[] =
            buckets.get(costPhase) || [];

          bucket.push({
            x: value,
            y: networkElementGroupLabels.get(id) || ''
          });

          buckets.set(costPhase, bucket);
        });
      }
    );

    buckets.forEach((data, key): void => {
      graphs.push({key, data: data.reverse()});
    });

    return graphs;
  }

  /**
   * Whether the component should update or not
   */
  shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
    if (
      nextProps.size.width !== this.props.size.width ||
      nextProps.size.height !== this.props.size.height ||
      nextProps.areaTypeId !== this.props.areaTypeId ||
      !equal(nextState.domain, this.state.domain) ||
      !equal(nextState.graphs, this.state.graphs) ||
      !equal(nextState.hoveredValue, this.state.hoveredValue)
    ) {
      return true;
    }

    return false;
  }

  /**
   * When the series get’s hovered
   */
  handleValueMouseOver(hoveredValue: IGraphDatum<number, number>): void {
    this.setState({hoveredValue});
  }

  /**
   * When the series get’s un-hovered
   */
  handleValueMouseOut(): void {
    this.setState({hoveredValue: null});
  }

  /**
   * Render the hint
   */
  renderHint(): JSX.Element | null {
    const {hoveredValue} = this.state;

    if (!hoveredValue) {
      return null;
    }

    const x0 = hoveredValue.x0 || 0,
      absolute = hoveredValue.x - x0,
      roundedAbsolute = Math.round(absolute / 1000) * 1000,
      value: IGraphDatum<number, number> = {
        y: hoveredValue.y,
        x: absolute / 2 + x0
      };

    return (
      <Hint
        value={value}
        align={{
          horizontal: Hint.ALIGN.RIGHT,
          vertical: Hint.ALIGN.TOP
        }}>
        <div className="visualization__hint visualization__hint--bar-chart">
          <span className="visualization__hint__value">
            {`${Number(roundedAbsolute).toLocaleString('de')}€`}
          </span>
        </div>
      </Hint>
    );
  }

  /**
   * Render the Component
   */
  render(): JSX.Element {
    if (!this.state.graphs.length) {
      return <Loading />;
    }

    const {domain, graphs} = this.state;
    const title = 'Gesamtkosten der inneren Erschließung';

    const marginXYPlot = this.props.print
      ? {left: 90, right: 10, top: 10, bottom: 90}
      : {left: 100, right: 20, top: 10, bottom: 90};

    return (
      <>
        {!this.props.print && (
          <>
            <p>
              Schätzung der Kosten für die innere Erschließung im Falle einer
              Neuerschließung
              <Tooltip
                disableFocusListener
                disableTouchListener
                arrow
                title={`Die Schätzung beruht auf einer Auswertung des
                        mittleren Infrastrukturbedarfs vergleichbarer Plangebiete
                        (Neuausweisungen, keine Nachnutzungen). Das so geschätzte
                        Mengengerüst wurde mit regionalen Kostenkennwerten für die
                        verschiedenen Kostenphasen (erstmalige Herstellung, Betrieb
                        und Unterhaltung, Erneuerung) unter Annahme typischer
                        technischer Lebensdauern der einzelnen Bauteile multipliziert.`}>
                <IconButton
                  style={{
                    right: 'initial',
                    padding: '2px',
                    boxShadow: 'none',
                    width: 'auto'
                  }}
                  disableRipple
                  size="small">
                  <Icon type="info" />
                </IconButton>
              </Tooltip>
            </p>
          </>
        )}

        <XYPlot
          width={this.props.print ? 700 : this.props.size.width}
          height={250}
          margin={marginXYPlot}
          xDomain={domain}
          yType="ordinal"
          stackBy="x">
          <VerticalGridLines />

          {graphs.map(
            (graph): JSX.Element => (
              <HorizontalBarSeries
                key={graph.key}
                data={graph.data}
                color={colorMap.get(graph.key)}
                onValueMouseOver={this.handleValueMouseOver}
                onValueMouseOut={this.handleValueMouseOut}
              />
            )
          )}

          <XAxis
            tickTotal={4}
            tickFormat={(tick): string =>
              `${Number(tick).toLocaleString('de')}€`
            }
          />

          <span className="visualization--x-axis-label">{title}</span>

          <YAxis className="visualization__axis" />

          {this.renderHint()}
        </XYPlot>

        {!this.props.print && (
          <Legend
            entries={graphs.map(graph => ({
              color: colorMap.get(graph.key) || '',
              description: labelMap.get(graph.key) || ''
            }))}
          />
        )}
      </>
    );
  }
}

export default sizeme()(ByCostPhase);

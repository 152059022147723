import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, Route, Routes, useNavigate} from 'react-router-dom';
import {showLegendSelector} from '../../selectors/show-legend';

import useMediaQuery from '@mui/material/useMediaQuery';
// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';

import HeaderContainer from '../header/header-container';
import ProfiHint from './profi-hint/profi-hint';
import SelectedItemButton from './selected-item-button/selected-item-button';
import MenuActions from './menu-actions/menu-actions';
// import Map from '../map/map';
import Map from '../map/map';
import PrintDialog from './print-dialog/print-dialog';
import DownloadDialog from './download-dialog/download-dialog';

import {menuItems} from '../../constants/menu-items';
import MenuItems from './menu-items/menu-items';
import styles from './analyze-menu.styl';

interface IProps {
  onSidebarTextShown: () => void;
}

/**
 * The analyze menu on the left
 */
//eslint-disable-next-line complexity
const AnalyzeMenu = ({onSidebarTextShown}: IProps): JSX.Element | null => {
  const [showSidebarMenu, setShowSidebarMenu] = useState(true);
  const [showSidebarText, setShowSidebarText] = useState(false);
  const [itemSelected, setItemSelected] = useState('');
  const showLegend = useSelector(showLegendSelector);
  const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });
  const fullscreenOverlayOpenCharts = Boolean(
    useMatch('/views/:any/charts')
    );
  const fullscreenOverlayOpenInfo = Boolean(
      useMatch('/views/:any/info')
    );
  const navigate = useNavigate();
  
  return (
    <>
      {(showSidebarMenu || showSidebarText || isDesktop) && (
        <div className={styles.container}>
          {!showSidebarText && (
            <div>
              <HeaderContainer />
              <div>
                <MenuItems
                  items={menuItems}
                  menuItemSelected={(itemName): void => {
                    setItemSelected(itemName);
                    setShowSidebarMenu(false);
                  }}
                />
              </div>
              <div className={styles.menuSeparator}></div>
              <div className={styles.containerMenuActions}>
                <MenuActions />
              </div>
            </div>
          )}
          <Routes>
            <Route index
              element={
                <ProfiHint
                  setShowSidebarText={setShowSidebarText}
                  isDesktop={isDesktop}
                />
              }
            />
            <Route
              path={'export/print'}
              element = {
                <PrintDialog
                  setShowSidebarText={(text): void => {
                    onSidebarTextShown();
                    setShowSidebarText(text);
                  }}
                />
              }
            />
            <Route path={'export/download'}
              element={
                <DownloadDialog
                  setShowSidebarText={(text): void => {
                    onSidebarTextShown();
                    setShowSidebarText(text);
                  }}
                />
              }
            />
          </Routes>
        </div>
      )}
      {isDesktop===false && <Map
          hide={showSidebarMenu || showSidebarText || fullscreenOverlayOpenCharts || fullscreenOverlayOpenInfo}
        />}

      {itemSelected && !showSidebarText && !showLegend && (
        <SelectedItemButton
          onClick={(): void => {
            setShowSidebarMenu(!showSidebarMenu);
            if (!showSidebarMenu) {
              navigate('/views');
            }
          }}
          onInfoClick={(): void => {
            setShowSidebarMenu(false);
          }}
          title={itemSelected}
          isOpened={!showSidebarMenu}
        />
      )}
    </>
  );
};

export default AnalyzeMenu;

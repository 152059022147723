import {IStore} from '../../interfaces/store';
import {Dispatch} from 'redux';
import fileDialog from 'file-dialog';

import {IMPORT_SETTINGS, IMPORT_SETTINGS_ERROR} from '../../constants/actions';
import {IAction} from '../../interfaces/action';
import setAreaConfig from '../area/set-area-config';
import {areaConfigs} from '../../constants/area-config';
import getShapeBounds from '../../libs/get-shape-bounds';

import addTrafficRoute from '../../actions/traffic/add-traffic-route'; //is this equivalent to "import {addTrafficRoute} ..." ???

import {fromLonLat} from "ol/proj";

export const importSettingsActionCreator = (settings): IAction => ({
  type: IMPORT_SETTINGS,
  settings
});

const importSettingsErrorActionCreator = (error): IAction => ({
  type: IMPORT_SETTINGS_ERROR,
  error
});

export const importSettings = (): ((
  dispatch: Dispatch,
  getState: () => IStore
) => void) => {
  
  const convertGoogleStorage = (shape) => {
    if(shape[0].lng) {
        const newShape = [];
        for (const line of shape) {
            newShape.push(fromLonLat([line.lng, line.lat]))
        }
        return newShape
    } else {
        return shape
    }
  }
  
    
  return (dispatch, getState): void => {
    fileDialog().then((files): void => {
      
      const state: IStore = getState();
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event) {
          // try {
            // @ts-ignore Property 'result' does not exist on type 'EventTarget'.
            const settings: IStore = JSON.parse(event.target.result);
          
            settings.area.shape = convertGoogleStorage(settings.area.shape);
            if (settings.area.shape) {
                const mapBounds = getShapeBounds(settings.area.shape, 2);
                if (settings.area.center.lng) { //if old save-file
                  settings.area.center = [settings.area.center.lng, settings.area.center.lat]
                }
                const routes = JSON.parse(JSON.stringify(settings.traffic.routes));
                if (routes[0] && routes[0].destination.hasOwnProperty('lat')) {
                    console.log('The uploaded file is an old google maps file, we use our converter, but any newly saved project file won\'t work on the old google maps based system.');
                    if (routes[0].destination.lng) {
                        for (const routeId in routes) {
                          routes[routeId].destination = [
                            routes[routeId].destination.lng,
                            routes[routeId].destination.lat
                          ]
                        }
                    }
                } 
                if (routes[0] && (routes[0].geojsonpath || routes[0].path)) {
                    //todo: import traffic routes from GeoJSON instead of rerouting using ORS.
                    settings.traffic.routes = [];
                    for (const route of routes) {
                        dispatch(addTrafficRoute(settings.traffic.routes, settings.area.center, route.destination)); //runs routes through ors (not load it by construction of LineString Features) TODO
                    }
                }
                if (settings.areaConfig && !settings.areaConfig.proportions && settings.areaConfig.efhProportion) {
                  settings.areaConfig.proportions = [settings.areaConfig.efhProportion, 1-settings.areaConfig.efhProportion];
                }
                dispatch(
                    importSettingsActionCreator({
                      ...settings,
                      map: {...settings.map, bounds: mapBounds}
                    })
                );
            } else {
                dispatch(importSettingsActionCreator(settings));
            }

            if (!settings.areaConfig && settings.area.densityId) {
                const areaConfig = areaConfigs[settings.area.densityId]; 
                dispatch(setAreaConfig(areaConfig));
            }
          // } catch (error) {
          //   dispatch(importSettingsErrorActionCreator(error));
          // }
        }
      };
      reader.readAsText(files[0]);
    });
  };
};

import {IStore} from '../interfaces/store';
import {poiCategories} from '../constants/pois';
import {poiCategoryIds} from '../constants/location-competition';

const locationCompetitionCategoryKeys = poiCategories
  .filter(category => poiCategoryIds.includes(category.id))
  .map(category => category.key);

/**
 * The filtered POIs for the location competition
 */
export default function(
  state: IStore
): GeoJSON.FeatureCollection<GeoJSON.Point> | null {
  const {pois} = state;

  if (!pois) {
    return null;
  }

  return Object.assign({}, pois, {
    features: pois.features.filter(poi => {
      const category = poi.properties && poi.properties.projektcheck_category;

      return locationCompetitionCategoryKeys.includes(category);
    })
  });
}

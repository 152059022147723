import {IEcologyLayer} from '../../interfaces/ecology';
import {IAction} from '../../interfaces/action';
import {FETCH_ECOLOGY_LAYER_SUCCESS} from '../../constants/actions';
import {ViewIds} from '../../constants/views';

/**
 * Initial state
 */
const initialState: Map<ViewIds, IEcologyLayer> = new Map();

/**
 * Handle the ecology layers
 */
export default function(
  state: Map<ViewIds, IEcologyLayer> = initialState,
  action: IAction
): Map<ViewIds, IEcologyLayer> {
  switch (action.type) {
    case FETCH_ECOLOGY_LAYER_SUCCESS: {
      if (!action.ecologyLayer || !action.viewId) {
        return state;
      }

      const clonedState = new Map(state);
      clonedState.set(action.viewId, action.ecologyLayer);
      return clonedState;
    }

    default:
      return state;
  }
}

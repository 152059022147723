import React from 'react';

import LegendColor from '../print/legend-color/legend-color';
import {getTotal} from '../../libs/math-functions';

import {IMunicipalityData} from '../../interfaces/municipality-data';

const areaColors = {
  gewerbe: '#686E72',
  verkehr: '#BACCD3',
  wald: '#5BC16D',
  landwirtschaft: '#C2E372',
  siedlung: '#F5BB18',
  erholung: '#FF8B60',
  wohnen: '#CD6781'
};

import styles from './recent-development.styl';

interface IProps {
  print?: boolean;
  municipalityName: string;
  municipalityData: IMunicipalityData | null;
  allToCompareName: string;
}

const AreaUsageTable = ({
  print,
  municipalityName,
  municipalityData,
  allToCompareName
}: IProps): JSX.Element | null => {
  if (!municipalityData) {
    return null;
  }

  const areaTableData: {
    type?: string;
    muni?: number;
    percent: number;
    all?: number;
    allPercent?: number;
    color?: string;
  }[] = [];

  const total = getTotal(municipalityData.areaUsage.data[0]);
  Object.keys(municipalityData.areaUsage.data[0]).forEach(type => {
    areaTableData.push({
      type,
      // @ts-ignore already checked and used multiple times
      muni: municipalityData.areaUsage.data[0][type],
      percent: Math.floor(
        (municipalityData?.areaUsage.data[0][type] / total) * 100
      ),
      color: areaColors[type]
    });
  });

  const allTotal = getTotal(municipalityData.areaUsage.data[1]);
  Object.keys(municipalityData.areaUsage.data[1]).forEach((type, index) => {
    areaTableData[index] = {
      ...areaTableData[index],
      // @ts-ignore already checked and used multiple times
      all: municipalityData.areaUsage.data[1][type],
      allPercent: Math.floor(
        (municipalityData?.areaUsage.data[1][type] / allTotal) * 100
      )
    };
  });

  return (
    <table>
      <thead>
        <tr>
          <th className={styles.tableHeader}></th>
          <th className={styles.tableHeader}></th>
          <th className={styles.tableHeader}>{municipalityName}</th>
          <th className={styles.tableHeader}>{allToCompareName}</th>
        </tr>
      </thead>
      <tbody>
        {areaTableData.map(item => {
          const allPercent = `${
            item.allPercent && item?.allPercent <= 0 ? '<1' : item?.allPercent
          } %`;
          return (
            item.type !== 'name' && (
              <tr key={item.type}>
                <td className={styles.name}>
                  <LegendColor color={item.color} />
                  {item.type}
                </td>
                <td>{item?.muni?.toLocaleString('de')} ha</td>
                <td>{item?.percent <= 0 ? '<1' : item?.percent} %</td>
                <td>
                  {print ? allPercent : `${item?.all?.toLocaleString('de')} ha`}
                </td>
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
};

export default AreaUsageTable;

import React from 'react';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {NetworkElementGroupIds} from '../../../constants/network-elements';
import Comparison from '../../chart-views/followup-costs/comparison';

import {totalUnitsSelector} from '../../../selectors/area';
import totalWorkplacesSelector from '../../../selectors/total-workplaces';
import {compareValueSelector} from '../../../selectors/followup-costs';

import {IAreaConfig} from '../../../constants/area-config';
import {IFollowupCosts} from '../../../interfaces/followup-costs';
import {useSelector} from 'react-redux';
import {AreaTypeIds} from '../../../constants/area-types';

type IProps = {
  areaConfig: IAreaConfig;
  data: Map<NetworkElementGroupIds, IFollowupCosts>;
};

import styles from './styles.styl';
const CostComparison = ({areaConfig, data}: IProps): JSX.Element => {
  const compareCosts = useSelector(compareValueSelector);
  const totalApartments = useSelector(totalUnitsSelector);
  const totalWorkplaces = useSelector(totalWorkplacesSelector);

  let totalCosts = 0;

  data.forEach((costs: IFollowupCosts) => {
    costs.forEach(cost => {
      totalCosts += cost;
    });
  });

  return (
    <PrintPage
      title={
        'Folgekosten im Bereich der technischen Infrastrukturen (Fortsetzung)'
      }>
      <PrintParagraph
        aside={
          <h3>
            Vergleich der geschätzten Erschliessungskosten mit dem
            Bundesdurchschnitt
          </h3>
        }
        main={
          <>
            <p>
              Geschätzte Kosten der Erschließung des Plangebiets im Vergleich
              zum Bundesschnitt
            </p>
            <p>
              Eine Erläuterung der Herleitung des Bundesschnitts findet sich im
              separaten Dokument &quot;Methodik&quot;.
            </p>
            <Comparison
              data={data}
              compareCosts={compareCosts}
              areaTypeId={areaConfig.areaTypeId}
              totalApartments={totalApartments}
              totalWorkplaces={totalWorkplaces}
              print
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th className={styles.followupCostsHeader}>
                    Kosten der inneren Erschließung pro{' '}
                    {areaConfig.areaTypeId === AreaTypeIds.Residential
                      ? 'Wohneinheit'
                      : 'Arbeitsplatz'}{' '}
                    (erstmalige Herstellung sowie Betrieb, Unterhaltung und
                    Erneuerung für die nächsten 25 Jahre)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Plangebiet <br />
                    (alle vier Netze)
                  </td>
                  <td>
                    {areaConfig.areaTypeId === AreaTypeIds.Residential &&
                      Math.round(totalCosts / totalApartments).toLocaleString(
                        'de'
                      )}
                    {areaConfig.areaTypeId === AreaTypeIds.Industrial &&
                      Math.round(totalCosts / totalWorkplaces).toLocaleString(
                        'de'
                      )}{' '}
                    € /{' '}
                    {areaConfig.areaTypeId === AreaTypeIds.Residential
                      ? 'Wohneinheit'
                      : 'Arbeitsplatz'}
                  </td>
                </tr>

                <tr>
                  <td>
                    Bundesschnitt <br />
                    (alle vier Netze)
                  </td>
                  <td>
                    {compareCosts?.toLocaleString('de')} € /{' '}
                    {areaConfig.areaTypeId === AreaTypeIds.Residential
                      ? 'Wohneinheit'
                      : 'Arbeitsplatz'}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        }
      />
    </PrintPage>
  );
};

export default CostComparison;


import {toLonLat} from "ol/proj";

/**
 * Get the center of a polygon / a lot of points
 *
 * inspired by @turf/centroid which doesn't want to get imported.
 */
export default function(
  rawPoints: ol.coordinate[]
): ol.coordinate[] {
  const points: ol.coordinate[] = [...rawPoints];
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  const equalFirstAndLast: boolean =
    firstPoint[1] === lastPoint[1] && firstPoint[0] === lastPoint[0];

  if (equalFirstAndLast) {
    points.pop();
  }

  const length: number = points.length;
  let latSum = 0;
  let lngSum = 0;

  points.forEach(point => {
    latSum += point[1];
    lngSum += point[0];
  });

  return [
    lngSum / length,
    latSum / length
  ];
}

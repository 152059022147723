/**
 * Load the population development JSON and return those with a Promise
 */
export default function(): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = 'data/occupants-structure.json';

    fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(error => reject(error));
  });
}

import {IAction} from '../interfaces/action';
import {CLOSE_LEGEND} from '../constants/actions';

/**
 * Close the legend
 */
export default function(): IAction {
  return {
    type: CLOSE_LEGEND
  };
}

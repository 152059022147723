import React, {useEffect, useRef, useState} from 'react';
import {AreaConfigIds, areaConfigs} from '../../../../constants/area-config';
import {AreaTypeIds} from '../../../../constants/area-types';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';

import styles from './area-density.styl';

interface IProps {
  areaTypeId: AreaTypeIds | null;
  densityId: AreaConfigIds | null;
  disabled: boolean;
  onChange: (densityId: AreaConfigIds) => void;
}

const areaDensityLabel = areaConfig => (
  <div className={styles.option}>
    <div
      className={styles.image}
      style={{backgroundImage: `url(${areaConfig.imagePath})`}}
    />
    <div className={styles.data}>
      <div>{areaConfig.name}</div>
      <small className={styles.note}>{areaConfig.subtitle}</small>
    </div>
  </div>
);

const allOptions = areaConfigs.map(areaConfig => {
  return {
    value: areaConfig.id,
    areaTypeId: areaConfig.areaTypeId,
    selectedLabel: areaConfig.name,
    label: areaDensityLabel(areaConfig)
  };
});

/**
 * The configuration section for the area density
 */
const AreaDensity = ({
  disabled,
  densityId,
  areaTypeId,
  onChange
}: IProps): JSX.Element => {
  const options = allOptions.filter(option => option.areaTypeId === areaTypeId);
  const selectedOption =
    options.find(option => option.value === densityId) || null;
  const inputLabel = useRef<HTMLLabelElement>(null);
  // const [labelWidth, setLabelWidth] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);

  // useEffect(() => {
  //   if (inputLabel.current) {
  //     setLabelWidth(inputLabel.current.offsetWidth);
  //   }
  // }, []);

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      style={{marginBottom: '16px'}}>
      <InputLabel ref={inputLabel}>Gebietstyp</InputLabel>
      <Select
        variant="standard"
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        value={selectedOption ? selectedOption.value : ''}
        label="Gebietstyp"
        // labelWidth={labelWidth}
        onChange={(data): void => onChange(Number(data.target.value))}>
        {options.map(option => (
          <MenuItem
            style={{whiteSpace: 'normal'}}
            key={String(option.value)}
            value={option.value}>
            {option === selectedOption && !selectOpen
              ? option.selectedLabel
              : option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AreaDensity;

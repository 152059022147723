import React, {useState, useEffect} from 'react';

import {IMunicipalityData, IEmployed} from '../../interfaces/municipality-data';
import {IGraphDatum} from '../../interfaces/graph-datum';
import {IYearValue} from '../../interfaces/year-value';
import sizeme from 'react-sizeme';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
// import {
//   XYPlot,
//   XAxis,
//   YAxis,
//   HorizontalGridLines,
//   LineSeries,
//   Hint
// } from 'react-vis';

import {
  LineChart as LineChartRe,
  Line as LineRe, 
  XAxis as XAxisRe, 
  YAxis as YAxisRe, 
  CartesianGrid, Tooltip, Legend, 
  ResponsiveContainer,
  // ReferenceLine
} from 'recharts';

// import Legend from '../shared/legend';
import Loading from '../shared/loading/loading';
import createInvisibleLineData from '../../libs/create-invisible-line-data';
import clsx from 'clsx';
import colors from '../../constants/colors';
import styles from './recent-development.styl';

interface IEmployedGraphs {
  readonly x: number;
  readonly y: number;
  readonly inbound: number;
  readonly outbound: number;
  readonly inner: number;
}[];

interface IProps {
  data: IMunicipalityData | null;
  size: {width: number; height: number};
  print?: boolean;
}
interface IState {
  minYear: number;
  maxYear: number;
  domain: [number, number];
  tickTotal: number;
  graphs: IEmployedGraphs;
  invisibleLineData: IGraphDatum<number, number>[];
  hoveredValues: null | {
    readonly inbound: IGraphDatum<number, number> | null;
    readonly outbound: IGraphDatum<number, number> | null;
    readonly inner: IGraphDatum<number, number> | null;
  };
  hoveredSeries: string | null;
}

const COLORS = {
  inner: colors.primaryColorHex,
  inbound: colors.lightBlue,
  outbound: colors.darkGreen
};

/**
 * Map the data to the graph structure
 */
function mapData(yearValue: IYearValue): IGraphDatum<number, number> {
  return {
    x: yearValue.year,
    y: yearValue.value
  };
}
/**
 * There are new props on their way
 */
const useData = (
  data: IMunicipalityData | null
): IState => {
  const [invisibleLineData, setInvisibleLineData] = useState< IGraphDatum<number, number>[] | null >(null);
  const [maxYear, setMaxYear] = useState<IYearValue>({value: 0, year: 0});
  const [minYear, setMinYear] = useState<IYearValue>({value: 0, year: 0});
  const [domain, setDomain] = useState<[number, number] | null>(null);
  const [tickTotal, setTickTotal] = useState<number | null>(null);
  const [graphs, setGraphs] = useState< IEmployedGraphs | any[] |  null>(null);

  useEffect((): void => {
    if (data) {
      const employedData: IEmployed = data.employed;
      const employedJoined: IEmployedGraphs | any[] = Array();
      employedData.inbound.forEach(
        (inbound) => {
          employedJoined.push({
            x: inbound.year,
            y: inbound.value,
            inbound: inbound.value,
            outbound:  employedData.outbound.filter(outbound => outbound.year==inbound.year)[0].value,
            inner: employedData.inner.filter(inner => inner.year==inbound.year)[0].value
          })
        }
      )
      setGraphs(employedJoined)

      const allData: IYearValue[] = [
        ...employedData.inbound,
        ...employedData.outbound,
        ...employedData.inner
      ];

      const maxByValue: IYearValue = maxBy(allData, 'value') || allData[0];
      const roundTo: number = maxByValue.value > 10000 ? 10000 : 1000;
      const minDomain = 0;
      const maxDomain: number =
        Math.floor(maxByValue.value / roundTo + 1) * roundTo;

      setDomain([minDomain, maxDomain]);
      setTickTotal(Math.min((maxDomain - minDomain) / roundTo, 10));

      setMinYear(minBy(allData, 'year') || allData[0]);
      setMaxYear(maxBy(allData, 'year') || allData[allData.length - 1]);
    }
  }, [data]);

  useEffect((): void => {
    if (minYear && maxYear) {
      setInvisibleLineData(createInvisibleLineData(minYear.year, maxYear.year));
    }
  }, [minYear, maxYear]);

  return {
    minYear: minYear.year,
    maxYear: maxYear.year,
    domain,
    tickTotal,
    graphs,
    invisibleLineData
  };
};

/**
 * Render the Component
 */
const Employed = ({data, size, print}: IProps): JSX.Element => {
  const [hoveredValue, setHoveredValue] = useState<{
    inbound: IGraphDatum<number, number> | null;
    outbound: IGraphDatum<number, number> | null;
    inner: IGraphDatum<number, number> | null;
  } | null>(null);
  const [hoveredSeries, setHoveredSeries] = useState<string | null>(null);

  const {
    minYear,
    maxYear,
    domain,
    tickTotal,
    graphs,
    invisibleLineData
  } = useData(data);

  if (
    !minYear ||
    !maxYear ||
    !domain ||
    !tickTotal ||
    !graphs ||
    !invisibleLineData
  ) {
    return <Loading />;
  }

  const marginXYPlot = print
  ? {left: 80, right: 30, top: 10, bottom: 40}
  : {left: 100, right: 20, top: 10, bottom: 40};

  return (
    <div>
      <p>
        Entwicklung der Zahl der Beschäftigten {minYear} bis {maxYear}
      </p>
      <p>differenziert nach Wohn- und Arbeitsort.</p>
      {/* <ResponsiveContainer> */}
        <LineChartRe
            width={size.width}
            height={400}
            data={graphs}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxisRe dataKey="x" />
            <YAxisRe domain={domain}/>
            <Tooltip />
            <Legend />
            <LineRe
              name="Einpendler" 
              type="linear" 
              dataKey="y" //or outbound, but then recharts throws an error regarding scale.
              stroke={COLORS.inbound}
              activeDot={{ r: 8 }}
            />
            <LineRe
              name="Auspendler" 
              type="linear" 
              dataKey="outbound"
              stroke={COLORS.outbound}
              activeDot={{ r: 8 }}
            />
            <LineRe
              name="Wohnsitz und Arbeitsstätte i. d. Gem." 
              type="linear" 
              dataKey="inner"
              stroke={COLORS.inner}
              activeDot={{ r: 8 }}
            />
        </LineChartRe>
      {/* </ResponsiveContainer> */}

      {!print && (
        <p className={clsx(styles.note, styles.hideOnMobile)}>
          Wenn Sie mit der Maus über das Diagramm fahren, werden Ihnen die
          Einzelwerte angezeigt.
        </p>
      )}
    </div>
  );
};

export default sizeme()(Employed);
// export default Employed;

import {IFollowupCosts} from '../../../interfaces/followup-costs';
import {IGraph} from '../../../interfaces/graph';
import {IGraphDatum} from '../../../interfaces/graph-datum';
import {AreaTypeIds} from '../../../constants/area-types';
import {
  FollowupCostsPhases,
  FollowupCostsObjects
} from '../../../constants/followup-costs';
import {
  NetworkElementGroupIds,
  networkElementGroupLabels
} from '../../../constants/network-elements';
import React from 'react';
import sizeme from 'react-sizeme';
import equal from 'deep-equal';
import {EnumValues} from 'enum-values';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint
} from 'react-vis';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '../../shared/icon';
import Legend from '../../shared/legend';
import Loading from '../../shared/loading/loading';
import colors from '../../../constants/colors';
import getMaxValue from '../../../libs/get-max-stacked-graphs-value';

interface IProps {
  areaTypeId: AreaTypeIds | null;
  followupCostsDataByObject: any;
  data: Map<NetworkElementGroupIds, IFollowupCosts> | null;
  size: {width: number; height: number};
  print?: boolean;
}
interface IState {
  domain: [number, number];
  graphs: IGraph<FollowupCostsObjects, number, string>[];
  hoveredValue: IGraphDatum<number, number> | null;
}

/**
 * Mapping of colors to the cost objects
 */
export const colorMap = new Map<FollowupCostsObjects, string>();
colorMap.set(FollowupCostsObjects.Landowner, colors.primaryColor);
colorMap.set(FollowupCostsObjects.Community, colors.lightRed);
colorMap.set(FollowupCostsObjects.GeneralPublic, colors.lightGreen);

/**
 * Mapping of labels to the cost objects
 */
export const labelMap = new Map<FollowupCostsObjects, string>();
labelMap.set(FollowupCostsObjects.Landowner, 'Grundstücksbesitzer');
labelMap.set(FollowupCostsObjects.Community, 'Gemeinde');
labelMap.set(
  FollowupCostsObjects.GeneralPublic,
  'Allgemeinheit der Tarifkunden'
);

/**
 * Calculate the graphs from the data
 */
export const calculateGraphs = (
  rawData: Map<NetworkElementGroupIds, IFollowupCosts>,
  costsWeighting: any
): IGraph<FollowupCostsObjects, number, string>[] => {
  const graphs: IGraph<FollowupCostsObjects, number, string>[] = [];
  const buckets = new Map<
    FollowupCostsObjects,
    IGraphDatum<number, string>[]
  >();

  rawData.forEach((costs: IFollowupCosts, id: NetworkElementGroupIds) => {
    const weightByGroup = costsWeighting[id];

    EnumValues.getValues(FollowupCostsObjects).forEach((costObject: number) => {
      const bucket: IGraphDatum<number, string>[] =
          buckets.get(costObject) || [],
        initialWeight = weightByGroup[FollowupCostsPhases.Initial][costObject],
        maintenanceWeight =
          weightByGroup[FollowupCostsPhases.Maintenance][costObject],
        initialCosts = costs.get(FollowupCostsPhases.Initial) || 0,
        maintenanceCosts = costs.get(FollowupCostsPhases.Maintenance) || 0,
        value =
          initialWeight * initialCosts + maintenanceWeight * maintenanceCosts;

      bucket.push({
        x: value,
        y: networkElementGroupLabels.get(id) || ''
      });

      buckets.set(costObject, bucket);
    });
  });

  buckets.forEach((data, key) => {
    graphs.push({key, data: data.reverse()});
  });

  return graphs;
};
/**
 * Chart for the followup costs by object
 */
class ByCostObject extends React.Component<IProps, IState> {
  /**
   * The initial component state
   */
  state: IState = {
    domain: [0, 0],
    graphs: [],
    hoveredValue: null
  };

  /**
   * Setup the component
   */
  constructor(props: IProps) {
    super(props);

    this.handleValueMouseOver = this.handleValueMouseOver.bind(this);
    this.handleValueMouseOut = this.handleValueMouseOut.bind(this);
  }

  /**
   * The component will be mounted
   */
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.updateState(this.props);
  }

  /**
   * There are new props on their way
   */
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    this.updateState(nextProps);
  }

  /**
   * There are new props on their way
   */
  updateState(props: IProps): void {
    const {data, areaTypeId, followupCostsDataByObject} = props;

    if (!data || !areaTypeId || !followupCostsDataByObject) {
      return;
    }

    const costsWeighting = followupCostsDataByObject[areaTypeId],
      graphs = calculateGraphs(data, costsWeighting),
      maxValue: number = getMaxValue(graphs, 'x'),
      roundTo: number = maxValue > 10000 ? 10000 : 1000,
      minDomain = 0,
      maxDomain: number = Math.floor(maxValue / roundTo + 1) * roundTo;

    this.setState({
      domain: [minDomain, maxDomain],
      graphs
    });
  }

  /**
   * Whether the component should update or not
   */
  shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
    if (
      nextProps.size.width !== this.props.size.width ||
      nextProps.size.height !== this.props.size.height ||
      nextProps.areaTypeId !== this.props.areaTypeId ||
      !equal(nextState.domain, this.state.domain) ||
      !equal(nextState.graphs, this.state.graphs) ||
      !equal(nextState.hoveredValue, this.state.hoveredValue)
    ) {
      return true;
    }

    return false;
  }

  /**
   * When the series get’s hovered
   */
  handleValueMouseOver(hoveredValue: IGraphDatum<number, number>): void {
    this.setState({hoveredValue});
  }

  /**
   * When the series get’s un-hovered
   */
  handleValueMouseOut(): void {
    this.setState({hoveredValue: null});
  }

  /**
   * Render the hint
   */
  renderHint(): JSX.Element | null {
    const {hoveredValue} = this.state;

    if (!hoveredValue) {
      return null;
    }

    const x0 = hoveredValue.x0 || 0,
      absolute = hoveredValue.x - x0,
      roundedAbsolute = Math.round(absolute / 1000) * 1000,
      value: IGraphDatum<number, number> = {
        y: hoveredValue.y,
        x: absolute / 2 + x0
      };

    return (
      <Hint
        value={value}
        align={{
          horizontal: Hint.ALIGN.RIGHT,
          vertical: Hint.ALIGN.TOP
        }}>
        <div className="visualization__hint visualization__hint--bar-chart">
          <span className="visualization__hint__value">
            {`${Number(roundedAbsolute).toLocaleString('de')}€`}
          </span>
        </div>
      </Hint>
    );
  }

  /**
   * Render the Component
   */
  render(): JSX.Element {
    if (!this.state.graphs.length) {
      return <Loading />;
    }

    const {domain, graphs} = this.state;
    const title = 'Gesamtkosten der inneren Erschließung';

    const marginXYPlot = this.props.print
    ? {left: 90, right: 10, top: 10, bottom: 90}
    : {left: 100, right: 20, top: 10, bottom: 90};

    const tooltipText = (
      <span>
        Die im Diagramm „nach Kostenphase“ dargestellten Gesamtkosten wurden
        unter den folgenden Annahmen auf die drei Kostenträger aufgeteilt:
        <br />
        Straße: Erstmalige Herstellung: 90% Grundstücksbesitzer, 10% Gemeinde
        (Beiträge nach §127ff BauGB), Betrieb, Unterhaltung und Erneuerung: 5%
        Grundstücksbesitzer, 95% Gemeinde (Straßenreinigungsgebühr, keine
        Erhebung von Erneuerungsbeiträgen nach KAG)
        <br />
        Kanalisation: Erstmalige Herstellung: 75% Grundstücksbesitzer, 25%
        Allgemeinheit der Tarifkunden (Kostendeckungsgrad der
        Anschlussbeiträge), Betrieb, Unterhaltung und Erneuerung: 100%
        Allgemeinheit der Tarifkunden (kostendeckende Gebührenfinanzierung)
        <br />
        Trinkwasser und Elektrizität: 100% Allgemeinheit der Tarifkunden
        (vollständige Gebührenfinanzierung in allen Kostenphasen)
      </span>
    );

    return (
      <>
        {!this.props.print && (
          <p>
            Schätzung der Aufteilung der Gesamtkosten (Neuerschließung)
            <Tooltip
              title={tooltipText}
              arrow
              disableFocusListener
              disableTouchListener>
              <IconButton
                style={{
                  right: 'initial',
                  padding: '2px',
                  boxShadow: 'none',
                  width: 'auto'
                }}
                disableRipple
                size="small">
                <Icon type="info" />
              </IconButton>
            </Tooltip>
          </p>
        )}
        <XYPlot
          width={this.props.print ? 700 : this.props.size.width}
          height={250}
          margin={marginXYPlot}
          xDomain={domain}
          yType="ordinal"
          stackBy="x">
          <VerticalGridLines />

          {graphs.map(
            (graph): JSX.Element => (
              <HorizontalBarSeries
                key={graph.key}
                data={graph.data}
                color={colorMap.get(graph.key)}
                onValueMouseOver={this.handleValueMouseOver}
                onValueMouseOut={this.handleValueMouseOut}
              />
            )
          )}

          <XAxis
            tickTotal={4}
            tickFormat={tick => `${Number(tick).toLocaleString('de')}€`}
          />

          <span className="visualization--x-axis-label">{title}</span>

          <YAxis className="visualization__axis" />

          {this.renderHint()}
        </XYPlot>
        {!this.props.print && (
          <Legend
            entries={graphs.map(graph => ({
              color: colorMap.get(graph.key) || '',
              description: labelMap.get(graph.key) || ''
            }))}
          />
        )}
      </>
    );
  }
}

export default sizeme()(ByCostObject);

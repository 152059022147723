import {IGraph} from '../interfaces/graph';
import max from 'lodash-es/max';
import toArray from 'lodash-es/toArray';

/**
 * Get the maximum summarized value for the graphs
 */
export default function(
  graphs: IGraph<any, any, any>[],
  stackBy = 'y'
): number {
  const summarizedValues = {};
  const collectKey: string = stackBy === 'y' ? 'x' : 'y';

  graphs.forEach(graph => {
    graph.data.forEach(entry => {
      if (!summarizedValues[entry[collectKey]]) {
        summarizedValues[entry[collectKey]] = 0;
      }

      summarizedValues[entry[collectKey]] += entry[stackBy];
    });
  });

  return max(toArray<number>(summarizedValues)) || 0;
}

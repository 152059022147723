import {IGraphDatum} from '../interfaces/graph-datum';

/**
 * Create the data for an invisible line that can be used for hovering
 */
export default function createInvisibleLineData(
  minYear: number,
  maxYear: number
): IGraphDatum<number, number>[] {
  const invisibleLine: IGraphDatum<number, number>[] = [];

  for (let i: number = minYear; i <= maxYear; i++) {
    invisibleLine.push({x: i, y: 0});
  }

  return invisibleLine;
}

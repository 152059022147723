import React from 'react';
import {AreaTypeIds, areaTypes} from '../../../constants/area-types';

import MapOverview from '../map/map-overview';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {areaConfigsById} from '../../../constants/area-config';
import {useSelector} from 'react-redux';
import {areaSelector, areaConfigSelector} from '../../../selectors/area';
import {municipalitySelector} from '../../../selectors/configuration-municipality';

type IProps = {
  onInit: () => void;
};

const ProjectDefinition = ({onInit}: IProps): JSX.Element | null => {
  const area = useSelector(areaSelector);
  const areaConfig = useSelector(areaConfigSelector);
  const municipality = useSelector(municipalitySelector);

  const isNotRetail = area.typeId !== AreaTypeIds.Retail;

  if (!areaConfig) {
    return null;
  }

  return (
    <PrintPage title="Projektdefinition">
      <PrintParagraph
        aside={
          <dl className="summary">
            <dt>Gemeinde</dt>
            <dd>{municipality && municipality.name}</dd>

            <dt>Flächenname</dt>
            <dd>{area.name}</dd>

            <dt>Flächengröße</dt>
            <dd>{area.size?.toLocaleString('de')} ha</dd>

            <dt>Nutzungsart</dt>
            <dd>{areaConfigsById[areaConfig.id].name}</dd>

            <dt>Gebiettyp</dt>
            <dd>{areaTypes[areaConfig.areaTypeId - 1].name || ''}</dd>

            {isNotRetail && (
              <>
                <dt>Angenommene Dauer des Bezugs des Gebiets</dt>
                <dd>
                  {area.influx} {Number(area.influx) > 1 ? 'Jahre' : 'Jahr'}
                </dd>
              </>
            )}
          </dl>
        }
        main={
          // A timeout to give the maps and layers some extra time to finish up
          <MapOverview
            area={area}
            onInit={(): void => {
              setTimeout(onInit, 5000);
            }}
          />
        }
      />
    </PrintPage>
  );
};

export default ProjectDefinition;

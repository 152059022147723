import {IBase} from '../interfaces/baselayer';

/*
01  Schleswig-Holstein
02  Freie und Hansestadt Hamburg
03  Niedersachsen
04  Freie Hansestadt Bremen
05  Nordrhein-Westfalen
06  Hessen
07  Rheinland-Pfalz
08  Baden-Württemberg
09  Freistaat Bayern
10  Saarland
11  Berlin
12  Brandenburg
13  Mecklenburg-Vorpommern
14  Freistaat Sachsen
15  Sachsen-Anhalt
16  Freistaat Thüringen

17  Bundesweit


Need to implement something like this: https://openlayers.org/en/latest/examples/layer-clipping.html
*/

export const ROADMAP = 'roadmap';
export const SATELLITE = 'satellite';
export const HYBRID = 'hybrid';


export enum VGIds {
    none,
    vg250,
    vg250wfs,
    vg250_land
}

export const VGTitles = new Map<BGIds, string>();
VGTitles.set(VGIds.None, 'none');
VGTitles.set(VGIds.vg250, 'Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wms)');
VGTitles.set(VGIds.vg250wfs, 'Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wfs)');
VGTitles.set(VGIds.vg250_land, 'Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wfs)');

export const BaselayerVG = new Map<VGIds, IBase>();
BaselayerVG.set(
    VGIds.vg250, 
    {
        shortName: 'vg250',
        description: 'WMS Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wms_vg250-ew)',
        copyright: '© GeoBasis-DE / BKG, Statistisches Bundesamt (Destatis)',
        serviceType: 'wms',
        data: {
          url: 'https://ils-geomonitoring.de/geoserver/ows',
          layers: [
            {
              id: 'bkg_vg250_gem',
              name: 'geonode:bkg_vg250_gem'
            }
          ]
        }
    }
);
BaselayerVG.set(
    VGIds.vg250wfs, 
    {
        shortName: 'vg250wfs',
        description: 'WFS Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wfs_vg250-ew)',
        copyright: '© GeoBasis-DE / BKG, Statistisches Bundesamt (Destatis)',
        serviceType: 'wfs',
        data: {
          url: 'https://sgx.geodatenzentrum.de/wfs_vg250-ew',
          layers: [
            {
              id: 'vg250-ew:vg250_gem',
              name: 'vg250-ew:vg250_gem'
            }
          ]
        }
    }
);
BaselayerVG.set(
    VGIds.vg250_land, 
    {
        shortName: 'vg250_land',
        description: 'WFS Verwaltungsgebiete 1:250 000 mit Einwohnerzahlen, Stand 31.12. (wfs_vg250-ew)',
        copyright: '© GeoBasis-DE / BKG, Statistisches Bundesamt (Destatis)',
        serviceType: 'wfs',
        data: {
          url: 'https://sgx.geodatenzentrum.de/wfs_vg250-ew',
          layers: [
            {
              id: 'vg250-ew:vg250_lan',
              name: 'vg250-ew:vg250_lan'
            }
          ]
        }
    }
);

export enum BGIds {
    None,
    TopPlusOpen,
    OSM,
    TopPlusOpenColor
}

export const BGTitles = new Map<BGIds, string>();

BGTitles.set(BGIds.None, 'none');
BGTitles.set(BGIds.TopPlusOpen, 'TopPlusOpen (BKG)');
BGTitles.set(BGIds.OSM, 'Open Street Map');
BGTitles.set(BGIds.TopPlusOpenColor, 'TopPlusOpen Color (BKG)');

//"https://projektcheck.gs.ils-geomonitoring.de/projektcheck/wms?service=WMS&version=1.1.0&request=GetMap&layers=projektcheck%253Aweb"

export const BaselayerBG = new Map<BGIds, IBase>();
BaselayerBG.set(
    BGIds.TopPlusOpen, 
    {
        shortName: 'TPO',
        description: 'TopPlusOpen Grau (BKG)',
        copyright: '© Bundesamt für Kartographie und Geodäsie 2021 (<a href="https://sgx.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf">BKG</a>)',
        serviceType: 'wms',
        data: {
          url: 'https://ils-geomonitoring.de/geoserver/ows',
          layers: [
            {
              id: 'web_grau',
              name: 'web_grau'
            }
          ]
        }
    }
);
BaselayerBG.set(
    BGIds.OSM, 
    {
        shortName: 'OSM',
        description: 'Open Street Map',
        copyright: '© Open Street Map Contributors (ODbL 1.0)',
        serviceType: 'osm',
        data: {
          url: [
            'https://a.tile.openstreetmap.de/${z}/${x}/${y}.png',
            'https://b.tile.openstreetmap.de/${z}/${x}/${y}.png',
            'https://c.tile.openstreetmap.de/${z}/${x}/${y}.png'
          ],
          layers: []
        }
    }
);
BaselayerBG.set(
    BGIds.TopPlusOpenColor, 
    {
        shortName: 'TPO',
        description: 'TopPlusOpen Farbe (BKG)',
        copyright: '© Bundesamt für Kartographie und Geodäsie 2021 (<a href="https://sgx.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf">BKG</a>)',
        serviceType: 'wms',
        data: {
          url: 'https://projektcheck.gs.ils-geomonitoring.de/projektcheck/wms',
          layers: [
            {
              id: 'projektcheck:web',
              name: 'projektcheck:web'
            }
          ]
        }
    }
);

/**
 * Available view IDs
 */
export enum DOPIds {
  None,
  SchleswigHolstein,
  Hamburg,
  Niedersachsen,
  Bremen,
  NordrheinWestfalen,
  Hessen,
  RheinlandPfalz,
  BadenWuerttemberg,
  Bayern,
  Saarland,
  Berlin,
  Brandenburg,
  MecklenburgVorpommern,
  Sachsen,
  SachsenAnhalt,
  Thueringen,
  Sentinel2
}

export const DOPTitles = new Map<DOPIds, string>();

DOPTitles.set(DOPIds.None, 'none');
DOPTitles.set(DOPIds.SchleswigHolstein, 'Schleswig-Holstein');
DOPTitles.set(DOPIds.Hamburg, 'Hamburg');
DOPTitles.set(DOPIds.Niedersachsen, 'Niedersachsen');
DOPTitles.set(DOPIds.Bremen, 'Bremen');
DOPTitles.set(DOPIds.NordrheinWestfalen, 'Nordrhein-Westfalen');
DOPTitles.set(DOPIds.Hessen, 'Hessen');
DOPTitles.set(DOPIds.RheinlandPfalz, 'Rheinland-Pfalz');
DOPTitles.set(DOPIds.BadenWuerttemberg, 'Baden-Württemberg');
DOPTitles.set(DOPIds.Bayern, 'Bayern');
DOPTitles.set(DOPIds.Saarland, 'Saarland');
DOPTitles.set(DOPIds.Berlin, 'Berlin');
DOPTitles.set(DOPIds.Brandenburg, 'Brandenburg');
DOPTitles.set(DOPIds.MecklenburgVorpommern, 'Mecklenburg-Vorpommern');
DOPTitles.set(DOPIds.Sachsen, 'Sachsen');
DOPTitles.set(DOPIds.SachsenAnhalt, 'Sachsen-Anhalt');
DOPTitles.set(DOPIds.Thueringen, 'Thüringen');
DOPTitles.set(DOPIds.Sentinel2, 'Sentinel-Sattelitenbilder vom Bundesamt für Kartographie und Geodüsie (BKG)');





/**
 * The various DOP underlays
 */
export const BaselayerDOP = new Map<DOPIds, IBase>();
BaselayerDOP.set(
    DOPIds.SchleswigHolstein, 
    {
        shortName: 'DOP_SH',
        description: 'Digitale Orthophotos für Schleswig-Holstein',
        copyright: 'kostenpflichtig',
        serviceType: 'wms',
        data: {
          url: '', //https://service.gdi-sh.de/WMS_SH_DOP20col_KF?service=wms&request=getCapabilities&version=1.3.0 //kostenpflichtig!!!
          layers: [
            {
              id: '', //SH_DOP20_1
              name: '' //SH_DOP20_1
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Hamburg, 
    {
        shortName: 'DOP_HH',
        description: 'Digitale Orthophotos für die Freie und Hansestadt Hamburg',
        copyright: '© Freie und Hansestadt Hamburg, Landesbetrieb Geoinformation und Vermessung (LGV)',
        serviceType: 'wms',
        data: {
          url: 'https://geodienste.hamburg.de/HH_WMS_DOP',
          layers: [
            {
              id: 'DOP',
              name: 'DOP'
            }
          ]
        }
    }
);

BaselayerDOP.set(
    DOPIds.Niedersachsen, 
    {
        shortName: 'DOP_NDS',
        description: 'Digitale Orthophotos für Niedersachsen',
        copyright: '© LGLN, <a href="https://www.govdata.de/dl-de/by-2-0">dl-de-by-2.0</a>',
        serviceType: 'wms',
        data: {
          url: 'https://www.geobasisdaten.niedersachsen.de/doorman/noauth/wms_ni_dop?version=1.3.0&service=wms',
          layers: [
            {
              id: 'dop20',
              name: 'dop20'
            }
          ]
        }
    }
);


BaselayerDOP.set(
    DOPIds.Bremen, 
    {
        shortName: 'DOP_HB',
        description: 'Digitale Orthophotos für die Freie Hansestadt Bremen',
        copyright: '?',
        serviceType: 'wms',
        data: {
          url: 'gdi1.geo.bremen.de/mapproxy/Apps4Bremen/service',
          layers: [
            {
              id: 'Orthophotos_2012',
              name: 'Orthophotos_2012'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.NordrheinWestfalen, 
    {
        shortName: 'DOP_NRW',
        description: 'Digitale Orthophotos für Nordrhein-Westfalen',
        copyright: '', //https://www.govdata.de/dl-de/zero-2-0
        serviceType: 'wms',
        data: {
          url: 'https://www.wms.nrw.de/geobasis/wms_nw_dop',
          layers: [
            {
              id: 'nw_dop_rgb',
              name: 'nw_dop_rgb'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Hessen, 
    {
        shortName: 'DOP_HE',
        description: 'Digitale Orthophotos für Hessen',
        copyright: '© HVBG',
        serviceType: 'wms',
        data: {
          url: 'https://www.gds-srv.hessen.de/cgi-bin/lika-services///ogc-free-images.ows',
          layers: [
            {
              id: 'he_dop_rgb',
              name: 'he_dop_rgb'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.RheinlandPfalz, 
    {
        shortName: 'DOP_RP',
        description: 'Digitale Orthophotos für Rheinland-Pfalz (40cm)',
        copyright: '© GeoBasis-DE / LVermGeoRP 2021',
        serviceType: 'wms',
        data: {
          url: 'https://geo4.service24.rlp.de/wms/dop_basis.fcgi',
          layers: [
            {
              id: 'rp_dop',
              name: 'rp_dop'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.BadenWuerttemberg, 
    {
        shortName: 'DOP_BW',
        description: 'Digitale Orthophotos für Baden-Württemberg',
        copyright: 'kostenpflichtig',
        serviceType: 'wms',
        data: {
          url: '',
          layers: [
            {
              id: '',
              name: ''
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Bayern, 
    {
        shortName: 'DOP_BAY',
        description: 'Digitale Orthophotos für Bayern (80cm)',
        copyright: '© Landesamt für Digitalisierung, Breitband und Vermessung',
        serviceType: 'wms',
        data: {
          url: 'https://geoservices.bayern.de/wms/v2/ogc_dop80_oa.cgi?service=wms&request=getCapabilities&version=1.1.1',
          version: '1.1.1',
          layers: [
            {
              id: 'by_dop80c',
              name: 'by_dop80c'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Saarland, 
    {
        shortName: 'DOP_SAAR',
        description: 'Digitale Orthophotos für das Saarland',
        copyright: 'kostenpflichtig',
        serviceType: 'wms',
        data: {
          url: '', //https://geoportal.saarland.de/freewms/dop //kostenpflichtig!!!
          layers: [
            {
              id: '', //WMS_SL_DOP
              name: '' //WMS_SL_DOP
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Berlin, 
    {
        shortName: 'DOP_B',
        description: 'Digitale Orthophotos für Berlin',
        copyright: '© Geoportal Berlin, <a href="https://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>',
        serviceType: 'wms',
        data: {
          url: 'https://isk.geobasis-bb.de/mapproxy/dop20c/service/wms',
          layers: [
            {
              id: 'bebb_dop20c',
              name: 'bebb_dop20c'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Brandenburg, 
    {
        shortName: 'DOP_BB',
        description: 'Digitale Orthophotos für Brandenburg',
        copyright: '© GeoBasis-DE/LGB, <a href="https://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>',
        serviceType: 'wms',
        data: {
          url: 'https://isk.geobasis-bb.de/mapproxy/dop20c/service/wms',
          layers: [
            {
              id: 'bebb_dop20c',
              name: 'bebb_dop20c'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.MecklenburgVorpommern, 
    {
        shortName: 'DOP_MV',
        description: 'Digitale Orthophotos für Mecklenburg-Vorpommern',
        copyright: 'kostenpflichtig', //© GeoBasis-DE/M-V 2021', //https://www.geoportal-mv.de/portal/Suche/Metadatenuebersicht/Details/Digitale%20Orthophotos%20(DOP)%20MV/bcfae1e9-1e22-47dd-92ea-1901308b9b74
        serviceType: 'wms',
        data: {
          url: '' //http://www.geodaten-mv.de/dienste/adv_dop',
          layers: [
            {
              id: 'mv_dop',
              name: 'mv_dop'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Sachsen, 
    {
        shortName: 'DOP_SN',
        description: 'Digitale Orthophotos für den Freistaat Sachsen',
        copyright: '© <a href="https://www.landesvermessung.sachsen.de/benutzungshinweise-5557.html">GeoSN</a>',
        serviceType: 'wms',
        data: {
          url: 'https://geodienste.sachsen.de/wms_geosn_dop-rgb/guest',
          layers: [
            {
              id: 'sn_dop_020',
              name: 'sn_dop_020'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.SachsenAnhalt, 
    {
        shortName: 'DOP_SAN',
        description: 'Digitale Orthophotos für Sachsen-Anhalt',
        copyright: '© Landesamt fuer Vermessung und Geoinformation Sachsen-Anhalt',
        serviceType: 'wms',
        data: {
          url: 'https://www.geodatenportal.sachsen-anhalt.de/wss/service/ST_LVermGeo_DOP_WMS_OpenData/guest',
          layers: [
            {
              id: 'lsa_lvermgeo_dop20_2',
              name: 'lsa_lvermgeo_dop20_2'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Thueringen, 
    {
        shortName: 'DOP_TH',
        description: 'Digitale Orthophotos für Thüringen',
        copyright: '© GDI-Th, <a href="https://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>',
        serviceType: 'wms',
        data: {
          url: 'https://www.geoproxy.geoportal-th.de/geoproxy/services/DOP20',
          layers: [
            {
              id: 'th_dop',
              name: 'th_dop'
            }
          ]
        }
    }
);
BaselayerDOP.set(
    DOPIds.Sentinel2,
    {
        shortName: 'DOP_SENTINEL',
        description: 'Sentinel-Satellitenbilder vom Bundesamt für Kartographie und Geodäsie (BKG)',
        copyright: '© EU (Sentinel-2), verarb. d. <a href="https://gdz.bkg.bund.de/index.php/default/wms-deutschlandmosaik-aus-sentinel-2-daten-wms-sentinel2-de.html">BKG</a> 2020',
        serviceType: 'wms',
        data: {
          url: 'https://sg.geodatenzentrum.de/wms_sentinel2_de?request=GetCapabilities&service=wms',
          layers: [
            {
              id: 'rgb',
              name: 'Sentinel2-DE RGB'
            }
          ]
        }
    }
);

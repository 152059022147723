import {IAction} from '../../interfaces/action';
import {
  SELECT_AREA_SHAPE,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE
} from '../../constants/actions';
import getPolygonCenter from '../../libs/get-polygon-center';
import {toLonLat as toLonLat} from 'ol/proj';
// import * as centroid from '@turf/centroid'

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected area center
 */
export default function(
  state: ol.coordinate | null = initialState,
  action: IAction
): ol.coordinate | null {
  switch (action.type) {
    case SELECT_AREA_SHAPE: {
      if (!action.areaShape) {
        return state;
      }
      function toLonLat_wrapper(coordinate, instance) {
        return toLonLat(coordinate)
      }
      const cent = getPolygonCenter(action.areaShape.map(toLonLat_wrapper));
//       const cent2 = centroid.centroid(action.areaShape)
      
      return cent
    }

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}

import {IAction} from '../../interfaces/action';
import {SELECT_AREA_NAME} from '../../constants/actions';

/**
 * Select an area name
 */
export default function(areaName: string): IAction {
  return {
    type: SELECT_AREA_NAME,
    areaName
  };
}

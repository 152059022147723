import React, {useState, useRef, useEffect} from 'react';
import {Ecologies} from '../../../constants/ecologies';
import {profiHintContent} from '../../../constants/profi-hint-contents';
import {ViewIds} from '../../../constants/views';
import Button from '../../shared/button/button';
import {useSelector} from 'react-redux';
import {IStore} from '../../../interfaces/store';

import MenuItemButton from '../menu-item-button/menu-item-button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from './profi-hint.styl';

interface IProfiHintProps {
  setShowSidebarText: (selected: boolean) => void;
  isDesktop: boolean;
}

const ProfiHint = ({
  setShowSidebarText,
  isDesktop
}: IProfiHintProps): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const view = useSelector((state: IStore) => state.view);

  const isEcologyAndNotHighVoltage: boolean =
    Ecologies.has(view) && view !== ViewIds.EcologyHighVoltageSupplyLines;
  const data = isDesktop
    ? profiHintContent[isEcologyAndNotHighVoltage ? 'Ecology' : `View${view}`]
    : profiHintContent.Mobile;

  const button: JSX.Element = (
    <Button
      className={styles.icon}
      variant="icon"
      onClick={(): void => {
        setShowSidebarText(false);
        setIsOpen(false);
      }}>
      <ArrowBackIcon />
    </Button>
  );

  useEffect(() => {
    if (container.current && isOpen) {
      container.current.scrollIntoView();
    }
  }, [isOpen]);

  if (!data) {
    return null;
  }

  return (
    <div className={styles.container} ref={container}>
      {!isOpen ? (
        <div
          className={styles.button}
          onClick={(): void => {
            setShowSidebarText(true);
            setIsOpen(true);
          }}>
          <MenuItemButton
            onClick={(): void => {}}
            title={String(data.title)}
            isActive={false}
            hasInformation={false}
          />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <header className={styles.header}>
            <div className={styles.backButton}>{button}</div>
            <div>zurück</div>
          </header>
          <div className={styles.content}>{data.content}</div>
        </div>
      )}
    </div>
  );
};

export default ProfiHint;

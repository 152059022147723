import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import {downloadSettings} from '../../../actions/settings/download-settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Button from '../../shared/button/button';
import Icon from '../../shared/icon';
import styles from './download-dialog.styl';

interface IDownloadDialogProps {
  setShowSidebarText: (selected: boolean) => void;
}

const DownloadDialog = ({
  setShowSidebarText
}: IDownloadDialogProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {trackEvent} = useMatomo();
  useEffect(() => {
    setShowSidebarText(true);
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <header className={styles.header}>
          <div className={styles.backButton}>
            <Button
              className={styles.icon}
              variant="icon"
              onClick={(): void => {
                setShowSidebarText(false);
                navigate(-1);
              }}>
              <ArrowBackIcon />
            </Button>
          </div>
          <div>zurück</div>
        </header>
        <div className={styles.content}>
          <h1>Planung speichern / exportieren</h1>

          <p>
            Sie möchten Ihre Planung zu einem späteren Zeitpunkt erneut aufrufen
            und daran weiterarbeiten? Oder möchten jemand anderem Ihren
            Arbeitsstand schicken? Mit einem Klick auf „Planung exportieren“
            haben Sie die Möglichkeit, eine Datei auf Ihren Computer
            herunterladen, in dem alle Daten Ihrer Planung und der Analysen
            enthalten sind.
          </p>
          <p>
            Die exportierte Datei hat ein JSON-Format. Sie enthält zwar weder
            Karten noch Diagramme (und ist für die meisten von uns unlesbar),
            kann aber bei einem späteren Aufruf von Web-Check über die Funktion
            „Planung importieren“ auf der Startseite wieder eingelesen werden
            und stellt Ihren aktuellen Arbeitsstand wieder her.
          </p>
          <p>
            Um anderen Ihre Planung zu zeigen, verschicken Sie einfach einen
            Link zu Web-Check (projekt-check.de/web-check) zusammen mit der
            gespeicherten JSON-Datei. Damit kann die Planung aufgerufen, nach
            eigenen Vorstellungen anpasst und in einer neuen Datei gespeichert /
            exportiert werden.
          </p>
          <p>
            Wenn Sie hingegen die Karten und Diagramme Ihres aktuellen
            Arbeitsstandes sowie methodische Erläuterungen herunterladen wollen,
            nutzen Sie bitte die Funktion „PDF drucken“.
          </p>
          <div className={styles.buttonGroup}>
            <Button
              grow
              white
              onClick={(): void => {
                // dispatch(trackEvent({"action": "Download", "category": "ga_replacement", "name": "ga_replacement"}));
                trackEvent({"action": "Download", "category": "ga_replacement", "name": "ga_replacement"});
                dispatch(downloadSettings());
              }}>
              <Icon type="download" /> Planung exportieren
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadDialog;

import {IAction} from '../../interfaces/action';

import {SET_AREA_CONFIG} from '../../constants/actions';
import {IAreaConfig} from '../../constants/area-config';

export default function setAreaConfig(areaConfig: IAreaConfig): IAction {
  return {
    type: SET_AREA_CONFIG,
    areaConfig
  };
}

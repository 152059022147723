import colors from './colors';

/**
 * Available categories
 */
export const enum PopulationCategories {
  pUnder18 = 1,
  p18To29 = 2,
  p30To44 = 3,
  p45To64 = 4,
  p65To80 = 5,
  pOver80 = 6
}

/**
 * Mapping of colors to the population categories
 */
export const colorMap = new Map<PopulationCategories, string>();
colorMap.set(PopulationCategories.pUnder18, colors.primaryColor);
colorMap.set(PopulationCategories.p18To29, colors.lightBlue);
colorMap.set(PopulationCategories.p30To44, colors.darkGreen);
colorMap.set(PopulationCategories.p45To64, colors.lightGreen);
colorMap.set(PopulationCategories.p65To80, colors.orange);
colorMap.set(PopulationCategories.pOver80, colors.lightRed);

/**
 * Mapping labels to the population categories
 */
export const labelMap = new Map<PopulationCategories, string>();
labelMap.set(PopulationCategories.pUnder18, 'unter 18 Jahre');
labelMap.set(PopulationCategories.p18To29, '18 bis 29 Jahre');
labelMap.set(PopulationCategories.p30To44, '30 bis 44 Jahre');
labelMap.set(PopulationCategories.p45To64, '45 bis 64 Jahre');
labelMap.set(PopulationCategories.p65To80, '65 bis 80 Jahre');
labelMap.set(PopulationCategories.pOver80, 'über 80 Jahre');

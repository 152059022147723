import React from 'react';
import {useParams} from 'react-router-dom';
import clsx from 'clsx';

import styles from './configuration-title.styl';

//Variables
import {steps} from '../../../config/steps';

const ConfigurationTitle = (): JSX.Element | null => {
  const {configurationStep} = useParams();

  if (!configurationStep) {
    return null;
  }

  const configurationTitleClasses = clsx(
    styles.configuration,
    configurationStep === 'area' && styles.shadowArea
  );

  return (
    <div className={configurationTitleClasses}>
      <div className={styles.stepNumber}>{steps[configurationStep].number}</div>
      <h2 className={styles.stepTitle}>{steps[configurationStep].name}</h2>
    </div>
  );
};

export default ConfigurationTitle;

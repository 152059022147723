import React from 'react';

import AreaUsage from '../../recent-development/area-usage';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {IMunicipality} from '../../../interfaces/municipality';

type IProps = {
  municipality: IMunicipality;
};

import appStyles from '../app/styles.styl';

const Area = ({municipality}: IProps): JSX.Element | null => {
  if (!municipality || !municipality.data) {
    return null;
  }

  return (
    <PrintPage title={`Bisherige Entwicklung in ${municipality.name}`}>
      <PrintParagraph
        aside={
          <>
            <h3>Flächennutzung</h3>
            <p className={appStyles.source}>
              Quelle: Regionalstatistik der Statistischen Ämter des Bundes und
              der Länder
            </p>
          </>
        }
        main={<AreaUsage data={municipality.data} print />}
      />
    </PrintPage>
  );
};
export default Area;

import {AreaTypeIds} from './area-types';

/**
 * The available area density IDs
 */
export const enum AreaConfigIds {
  E1 = 1,
  G1 = 11,
  G2 = 12,
  G3 = 13,
  G4 = 14,
  G5 = 15,
  W1 = 21,
  W2 = 22,
  W3 = 23,
  W4 = 24,
  W5 = 25,
  W6 = 26
}

export const enum AreaInflux {
  Influx1 = 1,
  Influx2 = 2,
  Influx3 = 3,
  Influx4 = 4,
  Influx5 = 5,
  Influx6 = 6,
  Influx7 = 7,
  Influx8 = 8,
  Influx9 = 9,
  Influx10 = 10
}

/**
 * An AreaType
 */
export interface IAreaConfig {
  id: AreaConfigIds;
  areaTypeId: AreaTypeIds;
  name: string;
  subtitle: string;
  shortName: string;
  density: number;
  imagePath: string;
  proportions: number[];
  // efh = Einfamilienhaus
  efhProportion: number;
  efhSize: number;
  efhUnderAgeProportion: number;
  // mfh = Mehrfamilienhaus
  mfhSize: number;
  mfhUnderAgeProportion: number;
  startYear?: number;
}

export const wegeGewerbeNachBranche = [
  2.3*0.6,
  2.1*0.7,
  3*0.6,
  3*0.4,
  3*0.4,
  5*0.4
]
export const wegeWohnenNachHaustyp = [
  3.5*0.6,
  3.5*0.45
]
export const wegeEinzelhandelNachSortiment = [
  2.0*0.4,
  2.0*0.5,
  2.0*0.5,
  2.0*0.8,
  2.0*0.8
]

// New config object to eventually deprecate the old array based one.
// It's easier to access object based configs via keys instead of array.find()

export const areaConfigsById: {[index in AreaConfigIds]: IAreaConfig} = {
  [AreaConfigIds.E1]: {
    id: AreaConfigIds.E1,
    areaTypeId: AreaTypeIds.Retail,
    name: 'Supermarkt (auch Discounter)',
    subtitle: 'Lebensmitteleinzelhandel',
    shortName: 'Supermarkt',
    density: 1,
    imagePath: 'images/area-density-e1.jpg',
    proportions: [1,0,0,0,0],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.G1]: {
    id: AreaConfigIds.G1,
    areaTypeId: AreaTypeIds.Industrial,
    name: 'Industriegebiet',
    subtitle: 'Großparzelliertes Industrie- und Gewerbegebiet',
    shortName: 'Industrie',
    density: 1,
    imagePath: 'images/area-density-g1.jpg',
    proportions: [50,0,30,10,10,0],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.G2]: {
    id: AreaConfigIds.G2,
    areaTypeId: AreaTypeIds.Industrial,
    name: 'Gewerbegebiet',
    subtitle: 'Kleinteilig strukturiertes Gewerbegebiet',
    shortName: 'Gewerbe',
    density: 1,
    imagePath: 'images/area-density-g2.jpg',
    proportions: [40,15,20,10,15,0],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.G3]: {
    id: AreaConfigIds.G3,
    areaTypeId: AreaTypeIds.Industrial,
    name: 'Technologiepark',
    subtitle: 'Technologie- und wissensorient. Gewerbegebiet',
    shortName: 'Technologie',
    density: 1,
    imagePath: 'images/area-density-g3.jpg',
    proportions: [25,0,10,50,15,0],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.G4]: {
    id: AreaConfigIds.G4,
    areaTypeId: AreaTypeIds.Industrial,
    name: 'Logistikgebiet',
    subtitle: 'Logistikorientiertes Gewerbegebiet',
    shortName: 'Logistik',
    density: 1,
    imagePath: 'images/area-density-g4.jpg',
    proportions: [0,0,90,0,10,0],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.G5]: {
    id: AreaConfigIds.G5,
    areaTypeId: AreaTypeIds.Industrial,
    name: 'Büroflächen',
    subtitle: '',
    shortName: 'Büro',
    density: 1,
    imagePath: 'images/area-density-g5.jpg',
    proportions: [0,0,0,60,15,25],
    efhProportion: 0,
    efhUnderAgeProportion: 0,
    efhSize: 0,
    mfhUnderAgeProportion: 0,
    mfhSize: 0,
    // efhLivSpace: 0,
    routes: 0,
    // mfhLivSpace: 0,
    secRoutes: 0,
  },
  [AreaConfigIds.W1]: {
    id: AreaConfigIds.W1,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Einfamilienhäuser auf großen Grundstücken',
    subtitle: 'Etwa 8 WE pro ha Bruttowohnbauland',
    shortName: '8 WE / ha',
    density: 8,
    imagePath: 'images/area-density-w1.jpg',
    proportions: [1,0],
    efhProportion: 1,
    efhSize: 2.9,
    efhUnderAgeProportion: 0.33,
    mfhSize: 1.83,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 120,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  },
  [AreaConfigIds.W2]: {
    id: AreaConfigIds.W2,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Einfamilienhausgebiet mit wenigen Doppelhäusern',
    subtitle: 'Etwa 13 WE pro ha Bruttowohnbauland',
    shortName: '13 WE / ha',
    density: 13,
    imagePath: 'images/area-density-w2.jpg',
    proportions: [1,0],
    efhProportion: 1,
    efhSize: 2.97,
    efhUnderAgeProportion: 0.33,
    mfhSize: 1.83,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 115,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  },
  [AreaConfigIds.W3]: {
    id: AreaConfigIds.W3,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Einzel-, Doppel- und Reihenhäuser',
    subtitle: 'Etwa 20 WE pro ha Bruttowohnbauland',
    shortName: '20 WE / ha',
    density: 20,
    imagePath: 'images/area-density-w3.jpg',
    proportions: [1,0],
    efhProportion: 1,
    efhSize: 2.84,
    efhUnderAgeProportion: 0.33,
    mfhSize: 1.81,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 110,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  },
  [AreaConfigIds.W4]: {
    id: AreaConfigIds.W4,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Doppel-, Reihen- und Mehrfamilienhäuser',
    subtitle: 'Etwa 30 WE pro ha Bruttowohnbauland',
    shortName: '30 WE / ha',
    density: 30,
    imagePath: 'images/area-density-w4.jpg',
    proportions: [0.6,0.4],
    efhProportion: 0.6,
    efhSize: 2.84,
    efhUnderAgeProportion: 0.33,
    mfhSize: 1.81,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 105,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  },
  [AreaConfigIds.W5]: {
    id: AreaConfigIds.W5,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Reihenhäuser und Stadtvillen',
    subtitle: 'Etwa 50 WE pro ha Bruttowohnbauland',
    shortName: '50 WE / ha',
    density: 50,
    imagePath: 'images/area-density-w5.jpg',
    proportions: [0.4,0.6],
    efhProportion: 0.4,
    efhSize: 2.77,
    efhUnderAgeProportion: 0.32,
    mfhSize: 1.81,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 100,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  },
  [AreaConfigIds.W6]: {
    id: AreaConfigIds.W6,
    areaTypeId: AreaTypeIds.Residential,
    name: 'Geschosswohnungsbau',
    subtitle: 'Etwa 80 WE pro ha Bruttowohnbauland',
    shortName: '80 WE / ha',
    density: 80,
    imagePath: 'images/area-density-w6.jpg',
    proportions: [0,1],
    efhProportion: 0,
    efhSize: 2.74,
    efhUnderAgeProportion: 0.32,
    mfhSize: 1.79,
    mfhUnderAgeProportion: 0.22,
    // efhLivSpace: 100,
    routes: 3.5,
    // mfhLivSpace: 75,
    secRoutes: 3.5,
  }
};

//TODO:
//Get rid of this and use areaConfigsById.
export const areaConfigs: IAreaConfig[] = [];
Object.getOwnPropertyNames(areaConfigsById).forEach( (key, index) => areaConfigs.push(areaConfigsById[key]));
import {useEffect, useRef, useState} from 'react';

import Overlay from 'ol/Overlay';
import {Select} from 'ol/interaction';
import {Vector as VectorLayer} from 'ol/layer';

interface IProps {
  mapCanvas: ol.Ma,
  infowindow: Overlay,
  setInfowindow: Function,
  markers: Layer
}

const ReachabilityPOIsInfowindow = ({
    mapCanvas,
    //infowindow,
    //setInfowindow,
    markers
}: IProps): null => {

    const [infowindow, setInfowindow] = useState<Overlay | null>(null);
    //const [popupOverlay, setPopupOverlay] = useState<Overlay || null>(null);
    //todo: containers must be created dynamically if none exist. setPosition seems to delete the container not only make it invisible.
    if (!mapCanvas) {
      return
    }
    
    if (!infowindow) {
        const container = document.querySelector('#popup');
        const popupOverlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
              duration: 250,
            }
        });
        mapCanvas.addOverlay(popupOverlay);
        setInfowindow(popupOverlay);
    } 
    /*else {
        if (infowindow instanceof Overlay) {
            popupOverlay = infowindow; //fallback for bugs i did earlier... trash?
        }
    }*/
    
    const [ select, setSelect ] = useState< Select| null >(null);
    
    const exitSelectMode = (select: Select): void => {
        mapCanvas.removeInteraction(select);
        setSelect(null);
      };
    
    useEffect(
        () => {
            if (!mapCanvas || !(markers instanceof VectorLayer) || select || !infowindow) {
                return
            }
            else {
                //if (isReachabilityView) {
                const selectInteraction = new Select({
                    layers: [markers],
                    style: null
                });
                selectInteraction.on('select', (event) => {
                    if ( event.selected.length > 0 ) {
                        const data = event.selected[0].getProperties();
                        const header = '<header class="poi__name">'+data.poiCategory.key+'</header>';
                        const names = '<div class="poi__type">'+data.name+'</div><br/>';
                        const inner_content = header + names;
                        infowindow.getElement().querySelector("#popup-content").innerHTML = `<div class='poi'>${inner_content}</div>`;
                        infowindow.setPosition(data.geometry.getCoordinates());
                        infowindow.setOffset( [0, -1*infowindow.getElement().clientHeight - 15] );
                    }
                });
                setSelect(selectInteraction);
                //}
            }
        },
        [ mapCanvas, infowindow ] //isReachabilityView
    );
    
    useEffect(
        () => {
            if(!select) {
                return
            }
            
            if (mapCanvas) {
                const selectexists = () => {
                    for (var interaction in mapCanvas.getInteractions()) {
                        if (interaction instanceof Select) {
                            return true
                        }
                        return false
                    }
                }
                if (!selectexists()) {
                    mapCanvas.addInteraction(select);
                }
            }
            
            return () => exitSelectMode(select);
        },
        [select]
    )
    
    useEffect((): (() => any) => {
        if (!infowindow) {
          return
        }
        //const content = container.querySelector('#popup-content');
        if (!infowindow.getElement()) {
            return null
        }
        const closer = infowindow.getElement().querySelector('#popup-closer');
        closer.onclick = function () {
           infowindow.setPosition(undefined);
           closer.blur();
           return false;
        }

        return (): void => closer.click();
    }, [infowindow]);

    useEffect((): (() => any) => {
      if (mapCanvas && infowindow) {
      }
      return 
    }, [infowindow]);

    return null;
};

export default ReachabilityPOIsInfowindow;

import React, {useState, useEffect} from 'react'; // eslint-disable-line no-unused-vars, max-len

import colors from '../../constants/colors';

import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {Fill, Stroke, Style} from 'ol/style';
import {toLonLat, fromLonLat} from "ol/proj";

import hexToRgba from 'hex-to-rgba';

interface IProps {
  isochrones: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> | null;
  mapCanvas: ol.Map | null;
  show: boolean;
}

const Isochrones = ({
  mapCanvas,
  isochrones,
  show
}: IProps): JSX.Element | null => {
  /**
   * The polygons for the isochrones on the map
   */
//   const [polygons, setPolygons] = useState<ol.MultiPolygon>([]);
  const [polygons, setPolygons] = useState<VectorLayer>(null);

  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    if (polygons instanceof VectorLayer) {
        mapCanvas.removeLayer(polygons)
    }
    if (!isochrones || !show) {
      return;
    }
    const formatter = new GeoJSON();
    const newPolygons = formatter.readFeatures(
        isochrones, 
        {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
        }
    );
    
    const isochrones_layer = new VectorLayer({
        source: new VectorSource({}),
        title: 'Travel-Isochrones',
        type: 'reachability'
    });
    
    for (var feat of newPolygons) {
        if (feat.getProperties().travelMode=='driving-car') {
            feat.setStyle(
                new Style({
                    stroke: new Stroke({
                      color: hexToRgba(colors.petrolBlue),
                      width: 3,
                    }),
                    fill: new Fill({
                      color: hexToRgba(colors.petrolBlue, 0.5),
                    }),
                })
            );
        } else if (feat.getProperties().travelMode=='cycling-regular') {
            feat.setStyle(
                new Style({
                    stroke: new Stroke({
                      color: hexToRgba(colors.darkGreen),
                      width: 3,
                    }),
                    fill: new Fill({
                      color: hexToRgba(colors.darkGreen, 0.5),
                    }),
                })
            );
        } else if (feat.getProperties().travelMode=='foot-walking') {
            feat.setStyle(
                new Style({
                    stroke: new Stroke({
                      color: hexToRgba(colors.lightRed),
                      width: 3,
                    }),
                    fill: new Fill({
                      color: hexToRgba(colors.lightRed, 0.5),
                    }),
                })
            );
        } 
        isochrones_layer.getSource().addFeature(
            feat
        )
    }
    mapCanvas.addLayer(isochrones_layer);

    setPolygons(isochrones_layer);
    
    return (): void => {
      if (isochrones_layer instanceof VectorLayer) {
        mapCanvas.removeLayer(isochrones_layer);
      }
      if (polygons instanceof VectorLayer) {
        mapCanvas.removeLayer(polygons);
      }
    };
  }, [mapCanvas, isochrones, show]);

  
  return  null;
};

export default Isochrones;

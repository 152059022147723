import {IAction} from '../interfaces/action';
import {
  FETCH_POIS,
  FETCH_POIS_SUCCESS,
  SELECT_AREA_SHAPE,
  DELETE_AREA_SHAPE,
  SELECT_MUNICIPALITY,
  SELECT_AREA_TYPE_ID
} from '../constants/actions';

/**
 * Initial state
 */
const initialState: GeoJSON.FeatureCollection<GeoJSON.Point> | null = null;

/**
 * Handle the isochrones
 */
export default function(
  // eslint-disable-line complexity
  state: GeoJSON.FeatureCollection<GeoJSON.Point> | null = initialState,
  action: IAction
): GeoJSON.FeatureCollection<GeoJSON.Point> | null {
  switch (action.type) {
    case FETCH_POIS:
      return initialState;

    case FETCH_POIS_SUCCESS:
      return action.pois || state;

    case SELECT_AREA_SHAPE:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    case SELECT_MUNICIPALITY:
      return initialState;

    case SELECT_AREA_TYPE_ID:
      return initialState;

    default:
      return state;
  }
}

import {IAction} from '../../interfaces/action';

import {UPDATE_AREA_CONFIG} from '../../constants/actions';
import {IAreaConfig} from '../../constants/area-config';

export default function updateAreaConfig(
  areaConfig: Partial<IAreaConfig>
): IAction {
  return {
    type: UPDATE_AREA_CONFIG,
    areaConfig
  };
}

import React, {useState} from 'react';
import {ViewIds} from '../../constants/views';
import {Tab, Tabs} from '@mui/material';
import CloseButton from './information-close-button';
import styles from './information.styl';

interface IProps {
  content: string | JSX.Element | JSX.Element[];
  videoId: string;
  view: ViewIds;
  title?: string;
}

const InformationTabs = ({
  content,
  videoId,
  view,
  title
}: IProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h1>Info: {title}</h1>
      </header>

      <div className={styles.tabContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_, newValue): void => {
            setSelectedTab(newValue);
          }}>
          <Tab disableRipple label={'Erläuterungen'} />
          <Tab disableRipple label={'Video'} />
        </Tabs>
      </div>
      <div className={styles.selectedTab}>
        {selectedTab === 0 && (
          <div className={styles.contentContainer}>{content}</div>
        )}
        {selectedTab === 1 && (
          <div className={styles.youtube}>
            <iframe
              src={
                `https://www.youtube-nocookie.com/embed/${videoId}` +
                '?rel=0&amp;showinfo=0'
              }
              frameBorder="0"
              allowFullScreen
            />
          </div>
        )}

        {view !== ViewIds.MunicipalIncome && <CloseButton />}
      </div>
    </section>
  );
};

export default InformationTabs;

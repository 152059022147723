import React, {useRef} from 'react';
import {useParams, Link, useNavigate, useLocation} from 'react-router-dom';

import { useMatomo } from '@jonkoops/matomo-tracker-react';

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {startAnalyse} from '../../../actions/settings/start-analyse';

import {steps} from '../../../config/steps';
import {useDispatch} from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

interface IProps {
  stepOnBlocked: boolean;
}

const useStyles = makeStyles({
  root: {
    fontSize: '15px',
    fontWeight: 700
  }
});

const Stepper = ({stepOnBlocked}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackEvent } = useMatomo();
  const {configurationStep} = useParams();
  const previousRoute = useRef(null);

  const stepsArray = Object.keys(steps);
  const activeStepNumber =
    configurationStep && steps[configurationStep].number - 1;

  const navigateNextTarget = `/config/${stepsArray[activeStepNumber + 1]}`;
  const navigateBackTarget = `/config/${stepsArray[activeStepNumber - 1]}`;
  const classes = useStyles();

  const pathname = useLocation().pathname;
  
  return (
    <MobileStepper
      color="primary"
      variant="dots"
      steps={4}
      position="static"
      activeStep={activeStepNumber}
      nextButton={
        <Button
          className={classes.root}
          disableRipple
          size="large"
          color="primary"
          component={Link}
          onClick={(): void => {
            if (
              previousRoute.current === '/config/details' &&
              pathname === '/config/usage'
            ) {
              // dispatch(trackEvent({"action": "START_ANALYSE", "category": "ga_replacement", "name": "ga_replacement"}));
              trackEvent({"action": "START_ANALYSE", "category": "ga_replacement", "name": "ga_replacement"});
              dispatch(startAnalyse());
            }

            previousRoute.current = pathname;
          }}
          to={navigateNextTarget}
          disabled={stepOnBlocked}>
          Weiter
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="large"
          className={classes.root}
          disableRipple
          color="primary"
          component={Link}
          to={navigateBackTarget}
          disabled={activeStepNumber === 0}>
          <KeyboardArrowLeft />
          Zurück
        </Button>
      }
    />
  );
};

export default Stepper;

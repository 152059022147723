import {IStore} from '../interfaces/store';
import {AreaTypeIds} from '../constants/area-types';

/**
 * The total workplaces for an industrial area
 */
export default function(state: IStore): number {
  const {area, municipality} = state;

  if (
    !area.typeId ||
    !area.densityId ||
    !area.size ||
    !municipality ||
    !municipality.data ||
    area.typeId !== AreaTypeIds.Industrial
  ) {
    return 0;
  }

  const areaDensityData =
    municipality.data.workplaceDevelopment[area.densityId];
  let workplaces = 0;

  Object.keys(areaDensityData).forEach((key: string) => {
    workplaces += areaDensityData[key];
  });

  return workplaces * area.size;
}

import {IAction} from '../interfaces/action';
import {ViewIds} from '../constants/views';
import {SELECT_VIEW} from '../constants/actions';

/**
 * Initial state
 */
const initialState: ViewIds = ViewIds.None;

/**
 * Handle the current selected view
 */
export default function(
  state: ViewIds = initialState,
  action: IAction
): ViewIds {
  switch (action.type) {
    case SELECT_VIEW:
      if (typeof action.viewId === 'undefined') {
        return state;
      }
      return action.viewId;
    default:
      return state;
  }
}

import {IStore} from '../interfaces/store';
import {AreaTypeIds} from '../constants/area-types';
import {steps} from '../config/steps';

/**
 * Whether the configuration can advance or not
 */
export default function(state: IStore): number {
  // eslint-disable-line complexity, max-len
  const {municipality, area} = state;

  if (
    municipality &&
    area.name &&
    area.shape &&
    area.size &&
    area.densityId &&
    area.typeId &&
    (area.influx || area.typeId === AreaTypeIds.Retail)
  ) {
    return steps.done.number;
  } else if (municipality && area.name && area.shape && area.size) {
    return steps.usage.number;
  } else if (municipality && area.shape) {
    return steps.details.number;
  } else if (municipality) {
    return steps.area.number;
  }
  return steps.municipality.number;
}

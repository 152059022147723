import { IEcology, IEcologyLayer } from '../interfaces/ecology';
import { XMLParser } from 'fast-xml-parser';

/**
 * Load the ecology layer data
 */
export default function (ecology: IEcology): Promise<IEcologyLayer> {
  return new Promise((resolve, reject): void => {
    const urlOptions = {
      service: 'WMS',
      request: 'GetCapabilities'
    };

    const urlOptionsAsQueryString: string = Object.entries(urlOptions)
      .map((keyValuePair: string[]) => keyValuePair.join('='))
      .join('&');

    const url = `${ecology.data.url}?${urlOptionsAsQueryString}`;

    fetch(url, { mode: 'cors' })
      .then(response => response.text())
      .then((xml: string) => {
        const parser = new XMLParser();
        const data = parser.parse(xml);
//         } else {
//           const data = xmlParser.parse(xml);
//         }
        if (data.hasOwnProperty('WMS_Capabilities')) {
            const layers = data.WMS_Capabilities.Capability.Layer.Layer;
            const dateInAbstract = data.WMS_Capabilities.Service.Abstract.match(
                /(\d{2,4}\.?){3}/
              )
        }
        else if (data.hasOwnProperty('wfs:WFS_Capabilities')) {
            const layers = data["wfs:WFS_Capabilities"].FeatureTypeList.FeatureType;
            const dateInAbstract = data["wfs:WFS_Capabilities"]["ows:ServiceIdentification"]["ows:Abstract"].match(
                /(\d{2,4}\.?){3}/
              )
        }
        if (!layers || !Array.isArray(layers)) {
          reject(new Error('Wrong data loaded.'));
          return;
        }
        if (dateInAbstract === null || dateInAbstract=='') {
            resolve({} as IEcologyLayer);
        } else {
            // eslint-disable-next-line
            resolve({
              year: dateInAbstract[0]
            } as IEcologyLayer);
        }

        
      })
      .catch(error => reject(error));
  });
}

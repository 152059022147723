import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useMatch} from 'react-router-dom';

import Button from '../shared/button/button';
import Icon from '../shared/icon';

import {areaTypeIdSelector} from '../../selectors/area';
import {viewSelector} from '../../selectors/view';
import clearTrafficRoutes from '../../actions/traffic/clear-traffic-routes';

import {AreaTypeIds} from '../../constants/area-types';
import {ViewIds} from '../../constants/views';
import InfoOverlay from '../information-overlay/information-overlay';
import styles from './traffic-info-overlay.styl';

const TrafficInfoOverlay = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const areaTypeId = useSelector(areaTypeIdSelector);
  const view = useSelector(viewSelector);

  const infoTrafficIsOpen = Boolean(
    useMatch(`/views/${ViewIds.TrafficEnvironment}/info`)
  );

  return (
    <div className={styles.container}>
      {!infoTrafficIsOpen && (
        <InfoOverlay
          infoContent={
            <div className={styles.content}>
              <div className={styles.infoButtonContainer}>
                {areaTypeId === AreaTypeIds.Residential
                  ? `Klicken Sie auf Einrichtungen oder Kreuzungen in der Karte, 
                     um Zielpunkte zu setzen. Rechtsklicken Sie auf einen Punkt um 
                     diesen zu entfernen.`
                  : `Klicken Sie auf die Karte, um Herkunftspunkte zu setzen. 
                     Rechtsklicken Sie auf einen Punkt um diesen zu entfernen.`}
                <Button
                  variant="link"
                  white
                  className={styles.infoButton}
                  onClick={(): void => {
                    navigate(`/views/${view}/info`);
                  }}>
                  &nbsp;Erläuterung:&nbsp;
                  <Icon
                    type="info"
                    className={styles.infoButtonIcon}
                  />
                </Button>
              </div>
              <div className={styles.deleteButtonContainer}>
                <Button
                  variant="link"
                  withIcon
                  white
                  className={styles.deleteButton}
                  onClick={(): void => {
                    dispatch(clearTrafficRoutes());
                  }}>
                  <Icon
                    type="trash"
                    className={styles.deleteButtonIcon}
                  />
                  <span>Alle löschen</span>
                </Button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default TrafficInfoOverlay;

import {IAction} from '../../interfaces/action';
import {SELECT_AREA_SIZE} from '../../constants/actions';

/**
 * Select an area size
 */
export default function(areaSize: number | null): IAction {
  return {
    type: SELECT_AREA_SIZE,
    areaSize
  };
}

import React, {useState, useEffect} from 'react'; // eslint-disable-line no-unused-vars, max-len
import {polygonOptions} from '../../config/area-shape'; // eslint-disable-line no-unused-vars, max-len
import {useMatch} from 'react-router-dom';


import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Draw, Modify} from 'ol/interaction';
// import {Select} from 'ol/interaction';
import {OSM, Vector as VectorSource} from 'ol/source';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {toLonLat, fromLonLat} from "ol/proj";
// import {GeometryType} from 'ol/geom';

interface IProps {
  mapCanvas: ol.Map | null;
  shape: ol.coordinate[] | null;
  onChange?: (shape: ol.coordinate[]) => void;
  hide?: boolean;
}

const AreaShape = ({
  mapCanvas,
  shape,
  onChange,
  hide,
  edit=true
}: IProps): JSX.Element | null => {
  if (typeof edit =='undefined') { let edit=true;}
  if (edit) {
    const [ draw, setDraw ] = useState< Draw| null >(null);
    const [ editable, setEditable ] = useState(edit);
  }

  const [polygon, setPolygon] = useState<ol.Polygon | null>(null);

  const [areaLayer, setAreaLayer] = useState<ol.Layer |null>(null)
  const [modifyConst, setModifyConst] = useState<ol.Layer |null>(null)
  // const areaSource = new VectorSource();
  // let areaLayer = new VectorLayer({
  //   source: new VectorSource(),
  //   title: 'Projektfläche',
  //   type: 'data',
  //   zIndex: 999,
  //   style: new Style({
  //     fill: new Fill({
  //       color: 'rgba(255, 255, 255, 0.2)',
  //     }),
  //     stroke: new Stroke({
  //       color: '#ffcc33',
  //       width: 4,
  //     }),
  //     image: new CircleStyle({
  //       radius: 7,
  //       fill: new Fill({
  //         color: '#ffcc33',
  //       }),
  //     }),
  //   }),
  // });

  const modifyexists = () => {
    for (var interaction in mapCanvas.getInteractions()) {
        if (interaction instanceof Modify) {
            return true
        }
        return false
    }
  }

  const startModifymanager = (): void => {
    if (modifyConst instanceof Modify) {
      modifyConst.addEventListener(
          'modifyend', 
          newPolygon => {
              handlePolygonCreated(newPolygon);
          }
      );
      window.removeEventListener('touchstart', startModifymanager, false);
    }
  }

  /**
   * is area current configuration step
   */
  const isStepArea = useMatch('config/area');
  
  /**
   * Enable the editing mode
   */
  const enableEditingMode = (map: ol.Map): void => {
    // eslint-disable-line no-unused-vars, max-len
    if (!mapCanvas) {
        return
    }
  }

  /**
   * Exit the editing mode
   */
  const exitEditingMode = (draw: Draw): void => {
    mapCanvas.removeInteraction(draw);
    setDraw(null);
  };


  /**
   * When the polygon got created
   */
  const handlePolygonCreated = (newPolygonEvent: Draw.DrawEvent): void => {
    if (newPolygonEvent.type == 'drawend') {
        const newPolygon = newPolygonEvent.feature;
    } else if (newPolygonEvent.type == 'modifyend') {
        const newPolygon = newPolygonEvent.features.getArray()[0];
    }
    setPolygon(newPolygon);
    
    if (onChange) {
      onChange(newPolygon.getGeometry().getCoordinates()[0]);
    }
  };

  useEffect(
    () => {
      const localAreaLayer = new VectorLayer({
        source: new VectorSource({title: 'Projektflächenquelle'}),
        title: 'Projektfläche',
        type: 'data',
        zIndex: 999,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 4,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });

      const localmodifyConst = new Modify({
        source: localAreaLayer.getSource()
      });
    
      setAreaLayer(
        localAreaLayer
      )
      setModifyConst(
        localmodifyConst
      )
    },
    []
  );
  
  useEffect(
    () => {
      if (editable && modifyConst instanceof Modify) {
        const startModifymanager = (): void => {
          if (modifyConst instanceof Modify && (!modifyConst.listeners_ || !modifyConst.listeners_.modifyend.length>=1)
            //(!modifyConst.getListeners() || !modifyConst.getListeners().includes('modifyend')) //not implemented in ol :/
          ) {
            modifyConst.addEventListener(
                'modifyend', 
                newPolygon => {
                    handlePolygonCreated(newPolygon);
                }
            );
            window.removeEventListener('touchstart', startModifymanager, false);
          }
        }
        if ('ontouchstart' in window) {
            window.addEventListener('touchstart', startModifymanager);
        } else {
          startModifymanager();
        }
      }
    },
    [modifyConst]
  );

  useEffect(
    () => { 
      if (mapCanvas && areaLayer && !polygon && shape) {
                  
        if (!mapCanvas.getLayers().getArray().includes(areaLayer)) {
          mapCanvas.addLayer(areaLayer);
          if (editable && !modifyexists()) {
            mapCanvas.addInteraction(modifyConst);
          }
        }
        
        const newPolygon = new Feature({
          geometry: new Polygon([shape])
        })
        setPolygon(newPolygon);
        areaLayer.getSource().addFeature(newPolygon);
      } else if (mapCanvas && areaLayer && !shape && polygon)  {
        areaLayer.getSource().clear();
        setPolygon(null);
      }
    },
    [shape, areaLayer]
  );

  useEffect(
    () => {
      if (!mapCanvas || draw) {
        return
      }
      else {
        if (isStepArea) {
          if (!mapCanvas.getLayers().getArray().includes(areaLayer)) {
            const areaLayer_ = mapCanvas.getLayers().getArray().filter( (obj) => {obj.get('title')=='Projektflaeche'}).pop();
            if (!areaLayer_) {
              mapCanvas.addLayer(areaLayer);
            } else { 
              areaLayer = areaLayer_ //should never happen
            }
          }
          if (editable && !modifyexists()) {
            mapCanvas.addInteraction(modifyConst);
            setDraw(
              new Draw({
                    source: areaLayer.getSource(),
                    type: "Polygon",
                    dragVertexDelay: 0,
                })
            );
          };
          // if (!mapCanvas.getLayers().getArray().includes(areaLayer)) {
          //   mapCanvas.addLayer(areaLayer);
          // }
        }
      }
    },
    [ mapCanvas, isStepArea, areaLayer, polygon ]
  );

  useEffect(
      () => {
          if(!draw) {
              return
          }
          
          if (mapCanvas && !polygon && editable) {
              const drawexists = () => {
                  for (var interaction in mapCanvas.getInteractions()) {
                      if (interaction instanceof Draw) {
                          return true
                      }
                      return false
                  }
              }
              if (!drawexists()) {
                  mapCanvas.addInteraction(draw);
              }
          }
      },
      [draw, polygon]
  );
  
  useEffect( 
    () => {
        if (edit && draw instanceof Draw) {
            const startDrawingmanager = (): void => {
                draw.addEventListener(
                    'drawend', 
                    newPolygon => {
                        handlePolygonCreated(newPolygon);
                        exitEditingMode(draw);
                    }
                );
                window.removeEventListener('touchstart', startDrawingmanager, false);
//                     window.removeEventListener('touchstart', startModifyingmanager, false);
            };
            
            if ('ontouchstart' in window) {
                window.addEventListener('touchstart', startDrawingmanager);
            } else {
                startDrawingmanager();
            }
        }
    },
    [draw]
  );

  return null;
};


export default AreaShape;

import colors from './colors';

/**
 * Available industries
 */
export const enum Industries {
  Manufacturing = 1,
  Construction,
  Logistics,
  FinancialInsurance,
  ServiceActivities,
  Administration
}

/**
 * Mapping of colors to the population categories
 */
export const colorMap = new Map<Industries, string>();
colorMap.set(Industries.Manufacturing, colors.primaryColor);
colorMap.set(Industries.Construction, colors.petrolBlue);
colorMap.set(Industries.Logistics, colors.turquois);
colorMap.set(Industries.FinancialInsurance, colors.green);
colorMap.set(Industries.Administration, colors.yellow);
colorMap.set(Industries.ServiceActivities, colors.lightRed);

/**
 * Mapping labels to the population categories
 */
export const labelMap = new Map<Industries, string>();
labelMap.set(Industries.Manufacturing, 'Verarbeitendes Gewerbe');
labelMap.set(Industries.Construction, 'Baugewerbe');
labelMap.set(
  Industries.Logistics,
  'Groß- und Einzelhandel, Logistik, Kfz-Handel'
);
labelMap.set(
  Industries.FinancialInsurance,
  'Finanzen, Versicherungen, IuK, wissensorientierte Dienstleistungen'
);
labelMap.set(
  Industries.ServiceActivities,
  'Sonstige unternehmensorientierte Dienstleistungen'
);
labelMap.set(Industries.Administration, 'Öffentliche Verwaltung');

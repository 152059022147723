import {IMunicipality} from '../../interfaces/municipality';
import {IAction} from '../../interfaces/action';
import {
  SELECT_MUNICIPALITY,
  FETCH_MUNICIPALITY_DATA_SUCCESS
} from '../../constants/actions';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected municipality
 */
export default function(
  state: IMunicipality | null = initialState,
  action: IAction
): IMunicipality | null {
  switch (action.type) {
    case SELECT_MUNICIPALITY:
      if (typeof action.municipality === 'undefined') {
        return state;
      }

      return action.municipality;

    case FETCH_MUNICIPALITY_DATA_SUCCESS:
      if (
        state &&
        action.municipalityId &&
        state.id === action.municipalityId
      ) {
        return Object.assign({}, state, {
          data: action.municipalityData
        });
      }

      return state;

    default:
      return state;
  }
}

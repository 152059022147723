/**
 * Colors used in the app
 */
export default {
//   primaryColor: '#346E9F',
  primaryColor: [52, 110, 159, 0.5],
  primaryColorHex: "#346f9f",
  petrolBlue: '#55bfdd',
  lightBlue: '#96DEFF',
  turquois: '#55ddbf',
  darkGreen: '#5BC16D',
  green: '#6cdd55',
  lightGreen: '#C2E372',
  yellow: '#ecea52',
  orange: '#F5BB18',
  lightRed: '#FF8B60',
  red: '#ec7152'
};

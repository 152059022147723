import {AreaConfigIds} from './area-config';
import {NetworkElementIds} from './network-elements';

/**
 * The followup costs time phases
 */
export const enum FollowupCostsPhases {
  Initial = 1,
  Maintenance
}

export enum FollowupCostsObjects {
  Landowner = 1,
  Community,
  GeneralPublic
}

/**
 * The reference values for the followup costs
 */
export const ReferenceValues = {
  [AreaConfigIds.W1]: {
    [NetworkElementIds.Street1]: 150.4,
    [NetworkElementIds.Street2]: 28.2,
    [NetworkElementIds.Street3]: 0,
    [NetworkElementIds.Sewerage1]: 177.4,
    [NetworkElementIds.Sewerage2]: 5.8,
    [NetworkElementIds.Sewerage3]: 0,
    [NetworkElementIds.DrinkingWater1]: 89.2,
    [NetworkElementIds.DrinkingWater2]: 127,
    [NetworkElementIds.DrinkingWater3]: 7,
    [NetworkElementIds.Electricity1]: 232.2,
    [NetworkElementIds.Electricity2]: 0,
    [NetworkElementIds.Electricity3]: 0
  },
  [AreaConfigIds.W2]: {
    [NetworkElementIds.Street1]: 143.6,
    [NetworkElementIds.Street2]: 34.6,
    [NetworkElementIds.Street3]: 0,
    [NetworkElementIds.Sewerage1]: 175.2,
    [NetworkElementIds.Sewerage2]: 6.2,
    [NetworkElementIds.Sewerage3]: 1.4,
    [NetworkElementIds.DrinkingWater1]: 81.4,
    [NetworkElementIds.DrinkingWater2]: 132.8,
    [NetworkElementIds.DrinkingWater3]: 8.6,
    [NetworkElementIds.Electricity1]: 231.8,
    [NetworkElementIds.Electricity2]: 0.4,
    [NetworkElementIds.Electricity3]: 0
  },
  [AreaConfigIds.W3]: {
    [NetworkElementIds.Street1]: 123.6,
    [NetworkElementIds.Street2]: 50.8,
    [NetworkElementIds.Street3]: 2.8,
    [NetworkElementIds.Sewerage1]: 171.6,
    [NetworkElementIds.Sewerage2]: 7,
    [NetworkElementIds.Sewerage3]: 2.8,
    [NetworkElementIds.DrinkingWater1]: 70.6,
    [NetworkElementIds.DrinkingWater2]: 139.4,
    [NetworkElementIds.DrinkingWater3]: 11.2,
    [NetworkElementIds.Electricity1]: 230,
    [NetworkElementIds.Electricity2]: 2.2,
    [NetworkElementIds.Electricity3]: 0
  },
  [AreaConfigIds.W4]: {
    [NetworkElementIds.Street1]: 68.8,
    [NetworkElementIds.Street2]: 89,
    [NetworkElementIds.Street3]: 15,
    [NetworkElementIds.Sewerage1]: 165.8,
    [NetworkElementIds.Sewerage2]: 8.6,
    [NetworkElementIds.Sewerage3]: 2.8,
    [NetworkElementIds.DrinkingWater1]: 64.4,
    [NetworkElementIds.DrinkingWater2]: 137.8,
    [NetworkElementIds.DrinkingWater3]: 13.8,
    [NetworkElementIds.Electricity1]: 224.8,
    [NetworkElementIds.Electricity2]: 7.4,
    [NetworkElementIds.Electricity3]: 0.1
  },
  [AreaConfigIds.W5]: {
    [NetworkElementIds.Street1]: 45.6,
    [NetworkElementIds.Street2]: 105.2,
    [NetworkElementIds.Street3]: 20.4,
    [NetworkElementIds.Sewerage1]: 163.2,
    [NetworkElementIds.Sewerage2]: 9.4,
    [NetworkElementIds.Sewerage3]: 2.8,
    [NetworkElementIds.DrinkingWater1]: 61.8,
    [NetworkElementIds.DrinkingWater2]: 137.2,
    [NetworkElementIds.DrinkingWater3]: 14.8,
    [NetworkElementIds.Electricity1]: 222.6,
    [NetworkElementIds.Electricity2]: 9.6,
    [NetworkElementIds.Electricity3]: 0.2
  },
  [AreaConfigIds.W6]: {
    [NetworkElementIds.Street1]: 45.6,
    [NetworkElementIds.Street2]: 105.2,
    [NetworkElementIds.Street3]: 20.4,
    [NetworkElementIds.Sewerage1]: 163.2,
    [NetworkElementIds.Sewerage2]: 9.4,
    [NetworkElementIds.Sewerage3]: 2.8,
    [NetworkElementIds.DrinkingWater1]: 61.8,
    [NetworkElementIds.DrinkingWater2]: 137.2,
    [NetworkElementIds.DrinkingWater3]: 14.8,
    [NetworkElementIds.Electricity1]: 222.6,
    [NetworkElementIds.Electricity2]: 9.6,
    [NetworkElementIds.Electricity3]: 0.3
  },
  [AreaConfigIds.G1]: {
    [NetworkElementIds.Street1]: 0,
    [NetworkElementIds.Street2]: 73.8,
    [NetworkElementIds.Street3]: 16.2,
    [NetworkElementIds.Sewerage1]: 0,
    [NetworkElementIds.Sewerage2]: 64.4,
    [NetworkElementIds.Sewerage3]: 27.6,
    [NetworkElementIds.DrinkingWater1]: 0,
    [NetworkElementIds.DrinkingWater2]: 78.4,
    [NetworkElementIds.DrinkingWater3]: 33.6,
    [NetworkElementIds.Electricity1]: 93.6,
    [NetworkElementIds.Electricity2]: 23.4,
    [NetworkElementIds.Electricity3]: 1.5
  },
  [AreaConfigIds.G2]: {
    [NetworkElementIds.Street1]: 0,
    [NetworkElementIds.Street2]: 104.92,
    [NetworkElementIds.Street3]: 17.08,
    [NetworkElementIds.Sewerage1]: 0,
    [NetworkElementIds.Sewerage2]: 100,
    [NetworkElementIds.Sewerage3]: 25,
    [NetworkElementIds.DrinkingWater1]: 0,
    [NetworkElementIds.DrinkingWater2]: 121.6,
    [NetworkElementIds.DrinkingWater3]: 30.4,
    [NetworkElementIds.Electricity1]: 126.4,
    [NetworkElementIds.Electricity2]: 31.6,
    [NetworkElementIds.Electricity3]: 1.2
  },
  [AreaConfigIds.G3]: {
    [NetworkElementIds.Street1]: 0,
    [NetworkElementIds.Street2]: 115.28,
    [NetworkElementIds.Street3]: 15.72,
    [NetworkElementIds.Sewerage1]: 0,
    [NetworkElementIds.Sewerage2]: 121.5,
    [NetworkElementIds.Sewerage3]: 13.5,
    [NetworkElementIds.DrinkingWater1]: 0,
    [NetworkElementIds.DrinkingWater2]: 147.6,
    [NetworkElementIds.DrinkingWater3]: 16.4,
    [NetworkElementIds.Electricity1]: 153.9,
    [NetworkElementIds.Electricity2]: 17.1,
    [NetworkElementIds.Electricity3]: 1.2
  },
  [AreaConfigIds.G4]: {
    [NetworkElementIds.Street1]: 0,
    [NetworkElementIds.Street2]: 59.25,
    [NetworkElementIds.Street3]: 19.75,
    [NetworkElementIds.Sewerage1]: 0,
    [NetworkElementIds.Sewerage2]: 64.8,
    [NetworkElementIds.Sewerage3]: 16.2,
    [NetworkElementIds.DrinkingWater1]: 0,
    [NetworkElementIds.DrinkingWater2]: 79.2,
    [NetworkElementIds.DrinkingWater3]: 19.8,
    [NetworkElementIds.Electricity1]: 82.4,
    [NetworkElementIds.Electricity2]: 20.6,
    [NetworkElementIds.Electricity3]: 1.5
  },
  [AreaConfigIds.G5]: {
    [NetworkElementIds.Street1]: 0,
    [NetworkElementIds.Street2]: 130.83,
    [NetworkElementIds.Street3]: 16.17,
    [NetworkElementIds.Sewerage1]: 0,
    [NetworkElementIds.Sewerage2]: 135,
    [NetworkElementIds.Sewerage3]: 15,
    [NetworkElementIds.DrinkingWater1]: 0,
    [NetworkElementIds.DrinkingWater2]: 164.7,
    [NetworkElementIds.DrinkingWater3]: 18.3,
    [NetworkElementIds.Electricity1]: 171.9,
    [NetworkElementIds.Electricity2]: 19.1,
    [NetworkElementIds.Electricity3]: 1
  }
};

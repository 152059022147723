import {IAction} from '../../interfaces/action';
import {
  SELECT_AREA_SHAPE,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE
} from '../../constants/actions';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected area shape
 */
export default function(
  state: ol.coordinate[] | null = initialState,
  action: IAction
): ol.coordinate[] | null {
  switch (action.type) {
    case SELECT_AREA_SHAPE:
      return action.areaShape || state;

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}

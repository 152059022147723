import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//WebCheck
  // interfaces
import {IRoute} from '../../interfaces/routes';
  //constants
import colors from '../../constants/colors';
import {
  areaConfigsById, 
  wegeGewerbeNachBranche, 
  wegeWohnenNachHaustyp, 
  wegeEinzelhandelNachSortiment
} from '../../constants/area-config';
  //selectors
import {populationStructureSelector} from '../../selectors/population-structure';
import {workplaceStructureSelector} from '../../selectors/workplace-structure';
import {trafficRoutesSelector} from '../../selectors/traffic-routes-selector';
import {areaSelector, areaCenterSelector} from '../../selectors/area';
  //actions
import addTrafficRoute from '../../actions/traffic/add-traffic-route';
import removeTrafficRoute from '../../actions/traffic/remove-traffic-route';

import getRouteApi from '../../apis/get-route';

//OpenLayers
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {toLonLat as toLonLat_flat, fromLonLat as fromLonLat_flat} from "ol/proj";
import * as olProj from 'ol/proj';
import {Point, LineString} from 'ol/geom';
import Feature from 'ol/Feature';
import {Coordinate} from 'ol/coordinate';
import Map from 'ol/Map.js';
import {Style, Circle, Fill, Stroke, Text} from 'ol/style';
import {WKT} from 'ol/format';
import GeoJSON from 'ol/format/GeoJSON.js';
// import {transform} from 'ol/proj';
import {buffer as extBuffer} from 'ol/extent';

//ngraph
import createGraph from 'ngraph.graph';

//geohash
import Geohash from 'latlon-geohash';
import { removeTrafficRoute } from '../../actions/traffic/remove-traffic-route';


interface IProps {
  view: number;
  show: boolean;
  mapCanvas: Map | null;
  // trafficRoutes: IRoute[] | null; //useSelector instead
  // setTrafficRoutes?;
  // addNewTrafficRoute?: (destination: Coordinate) => void;
  // removeRoute: (route: IRoute) => void;
  edit?: Boolean;
}

const TrafficRoutes = ({
  show,
  view,
  mapCanvas,
  // trafficRoutes,
  // setTrafficRoutes,
  // addNewTrafficRoute,
  // removeRoute,
  edit=true
}: IProps): JSX.Element | null => {

  const dispatch = useDispatch();
  
  const [markersLayer, setMarkersLayer] = useState<VectorLayer| null>(null);
  const [polylinesLayer, setPolylinesLayer] = useState<VectorLayer|null>(null);

  /**
   * These are refs, but actually they don't need to be, don't they?
   */
  const routeCreator = useRef<EventListener | null>(null);
  const markerRightclickListener = useRef<EventListener | null>(null);
  const markerClickListener = useRef<EventListener | null>(null);
  
  /**
   * Selectors
   */
  const trafficRoutesS = useSelector(trafficRoutesSelector);
  const populationStructure = useSelector(populationStructureSelector);
  const workplaceStructure = useSelector(workplaceStructureSelector);
  const area = useSelector(areaSelector);
  const areaCenter = useSelector(areaCenterSelector);

  const graph = createGraph();
  
  const onMapClick = (event: MouseEvent) => {
    if (addNewTrafficRoute) {
      addNewTrafficRoute(toLonLat_flat(event.coordinate));
    }
  }

  const addNewTrafficRoute = (destination: Coordinate): void => {
    if (!areaCenter) {
      return;
    }
    dispatch(addTrafficRoute(trafficRoutesS, areaCenter, destination));
  };

  const removeThisTrafficRoute = (route) => {
    dispatch(removeTrafficRoute(
        trafficRoutesS, 
        route
      ))
  };

  const deleteMarker = (e) => {
    e.preventDefault();
    const feature = markersLayer.getSource().getClosestFeatureToCoordinate(e.coordinate);
    if (!feature){
    } else {
      removeThisTrafficRoute(feature.get('route'));
      markersLayer.getSource().removeFeature(feature);
    }
  }

  const infoWindow = (e) => {
    e.preventDefault();
    if (mapCanvas) {
      var callbackReturn = mapCanvas.forEachFeatureAtPixel(
          e.pixel,
          (feature, layer) => {
              return feature;
          },
          {
              layerFilter: (candidate) => {
                if (candidate===markersLayer) {
                  return true} else {return false}
              },
              hitTolerance: 10,
          }
      );
    }
  }

  const clearRoutes = () => {
    if (markersLayer) {
      markersLayer.getSource().clear();
      markersLayer.getSource().changed();
    }
    if (polylinesLayer) {
      polylinesLayer.getSource().clear();
      polylinesLayer.getSource().changed();
    }
  }

  const reRenderGraph = () => {

  if (
    (!populationStructure && !workplaceStructure && !(area.densityId===1)) 
    || !trafficRoutesS 
  ) {
      return
    }
    if (trafficRoutesS.length===0) {
      clearRoutes();
    } else {
    let wege_total: number;
      let iterator: (number[] | null);
      let wege_pro_einheit: number = 0;
      let units: (number) = 0
      
      if (mapCanvas && view === 4 && area.densityId && area.influx) {
        if (populationStructure && ([21,22,23,24,25].includes(area.densityId))) {
          iterator = wegeWohnenNachHaustyp;
          populationStructure.forEach( (cohorte) => { units+=cohorte[area.influx].value } );
        } else if (workplaceStructure && ([11,12,13,14,15].includes(area.densityId))) {
          iterator = wegeGewerbeNachBranche;
          workplaceStructure.forEach( (cohorte) => { units+=cohorte } );
        } else if (area.densityId === 1) {
          iterator = wegeEinzelhandelNachSortiment;
          units = area.influx? area.influx: 0;
        } else {
          iterator = null;
        }
      
        for (const i in iterator) {
          wege_pro_einheit += (
            areaConfigsById[area.densityId]['proportions'][i] / areaConfigsById[area.densityId]['proportions'].reduce((a,b)=>a+b)
          ) * iterator[i];
        }
        wege_total = units * wege_pro_einheit;
        
        const wege_per_route = wege_total / trafficRoutesS.length;
        renderGraph(
          wege_per_route);
      }
    }
  }

  const renderGraph = (
    wege_per_route: number
  ): void => {
    // https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
    const stringDivider = function (str, width, spaceReplacer) {
        if (str.length > width) {
          let p = width;
          while (p > 0 && str[p] != ' ' && str[p] != '-') {
            p--;
          }
          if (p > 0) {
            let left;
            if (str.substring(p, p + 1) == '-') {
              left = str.substring(0, p + 1);
            } else {
              left = str.substring(0, p);
            }
            const right = str.substring(p + 1);
            return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
          }
        }
        return str;
    }
    
    const getText = function (feature, resolution) {
        const type = "wrap";
        const maxResolution = 75;
        let text = feature.get('weight');
      
        if (resolution > maxResolution) {
          text = '';
        } else if (
          type == 'wrap'
        ) {
          text = stringDivider(text, 16, '\n');
        }
      
        return text;
    }
      
    const createTextStyle = function (feature, resolution) {
        const align = "center";
        const baseline = "bottom";
        const size = '16px';
        const height = '1.2';
        const offsetX = 0;
        const offsetY = 0;
        const weight = "bold";
        const placement = "line";
        const maxAngle = "360";
        const overflow = false;
        const rotation = 0.0;
        const font = weight + ' ' + size + '/' + height + ' ' + 'Courier New';
        const fillColor = "#000000"
        const outlineColor = "#ffffff";
        const outlineWidth = 0.5;
      
        return new Text({
            textAlign: align == '' ? undefined : align,
            textBaseline: baseline,
            font: font,
            text: getText(feature, resolution),
            fill: new Fill({color: fillColor}),
            stroke: new Stroke({color: outlineColor, width: outlineWidth}),
            offsetX: offsetX,
            offsetY: offsetY,
            placement: placement,
            maxAngle: maxAngle,
            overflow: overflow,
            rotation: rotation,
        });
    }
  
    const routeToGraph = (route: IRoute, wege_per_route): void => {
      const existing = markersLayer.getSource().getFeaturesAtCoordinate(olProj.fromLonLat(route.destination));
      if (existing && existing.length === 0) {
        const coordinates: Coordinate[] = route.destination;
        const marker: Feature = new Feature({
            geometry: new Point(olProj.fromLonLat(coordinates)),
            name: Geohash.encode(route.destination[1],route.destination[0]),
            route: route, //as a hint what to delete on delete
        });
        markersLayer.getSource().addFeature(marker);
      }
      const geojsonformat = new GeoJSON({'dataProjection': 'EPSG:4326'});
      let f = geojsonformat.readFeature(route.geojsonpath);
      let last, existinglink, current, phash = undefined;
      
      f.getGeometry().transform('EPSG:4326','EPSG:3857').getCoordinates().forEach(
        p => {
          const coords = olProj.toLonLat(p);
          phash = Geohash.encode(coords[1], coords[0]);
          if (phash === undefined) {
          //                               console.log('Error with geohash:' + p);
          }
          else {
              current = graph.getNode(phash);
              if (!current) {
                  graph.addNode(phash, {coordinates: p, count: 1} );
                  current = graph.getNode(phash);
              } else {
                  current.data.count +=1 //Update data
              }
              if (last === undefined || last === null) {
              } else {
                  existinglink = graph.getLink(current.id, last.id)
                  if (existinglink === undefined || existinglink === null) {
                      var wkt_linestring = new WKT();
                      existinglink = graph.addLink(
                          current.id, 
                          last.id, 
                          {
                              count: 1, 
                              wkt: wkt_linestring.writeGeometry(
                                  new LineString( 
                                      [current.data.coordinates, last.data.coordinates]
                                  )
                              ) ,
                              weight: wege_per_route
                          }
                      );
                  } else {
                      existinglink.data.count += 1;
                      existinglink.data.weight = wege_per_route*existinglink.data.count;
                  }
              }
              last = graph.getNode(phash);
          }
        }
      )
    }
  
    graph.clear();

    if (!polylinesLayer || !markersLayer ) {
      createLayers(polylinesLayer, markersLayer);
    }
    if (
        (
          polylinesLayer 
        ) && (
          markersLayer 
        )
        && trafficRoutesS 
      ) {
      trafficRoutesS.forEach(
          (route: IRoute) => routeToGraph(route, wege_per_route)
      );
    
      polylinesLayer.getSource().clear();
      polylinesLayer.getSource().changed();
      
      graph.forEachLink(l => {
        const coordinates = [
            graph.getNode(l.fromId).data.coordinates, 
            graph.getNode(l.toId).data.coordinates
        ];
        var polyline: Feature = new Feature({
            geometry: new LineString(coordinates),
            count: l.data.count,
            weight: l.data.weight.toFixed(2).toString(),
        });
        polyline.setStyle(
          function (feature, resolution) {
            return new Style({
                stroke: new Stroke({
                    color: "black",
                    //colors.primaryColor
                    width: l.data.weight/(wege_per_route*trafficRoutesS.length)*25 / resolution * 2, //l.data.count*3,
                    opacity: 0.7,
                    lineCap: 'round',
                    lineJoin: 'bevel'
                }),
                text: createTextStyle(feature, resolution)
            })
          }
        );
        polylinesLayer.getSource().addFeature(polyline);
      });

      mapCanvas.getView().fit(
        extBuffer(
          polylinesLayer.getSource().getExtent(),
          250
        ), 
        {duration: 750}
      );
    }
  }

  const createLayers = (poly=false, mark=false) => {
    if (!mark) {
      const markerStyle = new Style({
        image: new Circle({
          radius: 12,
          fill: new Fill({
            color: "white"
          }),
          stroke: new Stroke({
            color: "black",
            width: 2,
          }),
        })
      });
      const markers = new VectorLayer({
        source: new VectorSource(),
        title: 'Quell-/Ziel-Orte',
        shortname: 'trafficsources',
        type: 'traffic',
        style: markerStyle,
        opacity: 0.6,
      });
      mapCanvas.addLayer(markers); // references this the state or a copy? another useEffect-Block necessary?
      setMarkersLayer(markers);
    };
    
    if (!poly) {
      const polylines = new VectorLayer({
          source: new VectorSource(),
          title: 'Verkehrsrouten',
          type: 'traffic',
          shortname: 'trafficRoutes'
      });  
      mapCanvas.addLayer(polylines);
      setPolylinesLayer(polylines);
    }
  }
  
  useEffect(
    (): (() => void) => {
      if (
        mapCanvas 
        && markersLayer
      ) {
        reRenderGraph();
      };
    }, 
    [mapCanvas, trafficRoutesS, area, populationStructure, workplaceStructure, markersLayer]
  );

  useEffect(
    () => {
      if (show && areaCenter && trafficRoutesS.length>0) {
        for (var trafficRoute of trafficRoutesS) {
          //todo: fix this, so it won't rerender n times (depending on n routes)
          dispatch(
            addTrafficRoute(trafficRoutesS, areaCenter, trafficRoute.destination)
          );
          removeThisTrafficRoute(trafficRoute);
        }
      }
    },
    [areaCenter]
  )

  
  
  useEffect(
    () => {
      if (mapCanvas) {
        if (show) {
          
          if (
            (
              // !mapCanvas.getLayers().getArray().includes(markersRef.current)
              !mapCanvas.getLayers().getArray().includes(markersLayer)
            ) || 
            (
              // !mapCanvas.getLayers().getArray().includes(polylinesRef.current)
              !mapCanvas.getLayers().getArray().includes(polylinesLayer)
            )
          ) {
            // createLayers(polylinesRef.current, markersRef.current);
            // createLayers(polylinesLayer, markersRef.current);
            createLayers(polylinesLayer, markersLayer);
          }
        }
      }
      
      return (): void => {
        if (mapCanvas) {
          if (polylinesLayer) {
            mapCanvas.removeLayer(polylinesLayer);
            setPolylinesLayer(null);
          }
          if (markersLayer) {
            mapCanvas.removeLayer(markersLayer);
            setMarkersLayer(null)
          }
        }
      }
    },
    [show, view]
  );


  useEffect(
    () => {
      if (mapCanvas && polylinesLayer && markersLayer) {
        if (show) {
          if (edit) {
            if (!routeCreator.current) {
              const routeCreatorTmp = mapCanvas.on(
                'singleclick',
                onMapClick
              );
              routeCreatorTmp.name ='routecreate';
              routeCreator.current = routeCreatorTmp;
            }
        
            if (!markerRightclickListener.current) {
              const markerRightclickListenerTmp = mapCanvas.on(
                  'contextmenu', 
                  deleteMarker
              );
              markerRightclickListenerTmp.name = 'markerdelete';
              markerRightclickListener.current = markerRightclickListenerTmp;
            }
            
            if (!markerClickListener.current) {
              const markerClickListenerTmp = mapCanvas.on(
                'singleclick',
                infoWindow
              );
              markerClickListenerTmp.name ='infowindow';
              markerClickListener.current = markerClickListenerTmp;
            }
          }
        }
      }

      return (): void => {
        if (mapCanvas && polylinesLayer && markersLayer) {
          if (routeCreator.current) {
            mapCanvas.un('singleclick', routeCreator.current.listener)
            routeCreator.current = null;
          }
          
          if (markerRightclickListener.current) {
            mapCanvas.un('contextmenu', markerRightclickListener.current.listener)
            markerRightclickListener.current = null;
          }
        
          if (markerClickListener.current) {
            mapCanvas.un('singleclick', markerClickListener.current.listener)
            markerClickListener.current = null;
          }
  
          clearRoutes();
  
          if (mapCanvas && mapCanvas.getLayers()) {
            for (var s of mapCanvas.getLayers().getArray()) {
              s.changed();
            }
          }
  
        }
      }
    }, 
    [show, view, polylinesLayer, markersLayer]
  );

  return null;
}

export default TrafficRoutes;

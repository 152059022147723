import {IMunicipality} from '../../../../interfaces/municipality';
import React, {useEffect, useState, useRef} from 'react';
import Awesomplete from 'awesomplete';
import TextField from '@mui/material/TextField';
import styles from './municipality-input.styl';

interface IProps {
  municipalities: IMunicipality[];
  municipality: IMunicipality | null;
  onSelectMunicipality: (municipality: IMunicipality) => void;
  onResetMunicipality: () => void;
}

/**
 * The configuration section for the municipality
 */
export default ({
  municipalities,
  municipality,
  onSelectMunicipality,
  onResetMunicipality
}: IProps): JSX.Element => {
  const [municipalityName, setMunicipalityName] = useState('');
  /**
   * The autocomplete input
   */
  const awesompleteInput = useRef<HTMLInputElement>(null);

  /**
   * Get the names of the municipalities
   */
  const getMunicipalitiesNames = (items: IMunicipality[]): string[] => {
    return items.map((item: IMunicipality) => item.name);
  };

  /**
   * Handle the change of the input
   */
  const handleChange = (): void => {
    if (awesompleteInput.current) {
      const typedMunicipalityName: string = awesompleteInput.current.value;

      if (typedMunicipalityName.length === 0) {
        onResetMunicipality();
      }

      setMunicipalityName(typedMunicipalityName);
    }
  };

  /**
   * Handle the blur of the input
   */
  const handleBlur = (): void => {
    if (awesompleteInput.current) {
      const typedMunicipalityName: string = awesompleteInput.current.value;

      if (municipality && typedMunicipalityName !== municipality.name) {
        onResetMunicipality();
      }
    }
  };

  /**
   * Handle the selection of a municipality
   */
  const handleSelect = (): void => {
    if (awesompleteInput.current) {
      const typedMunicipalityName: string = awesompleteInput.current.value;
      const selectedMunicipality = municipalities.find(
        (possibleMunicipality: IMunicipality) =>
          possibleMunicipality.name === typedMunicipalityName
      );

      if (!selectedMunicipality) {
        return;
      }

      setMunicipalityName(municipalityName);
      onSelectMunicipality(selectedMunicipality);
    }
  };

  useEffect((): (() => void) => {
    /**
     * The autocomplete instance
     */
    let awesomplete: Awesomplete = null;
    if (awesompleteInput.current) {
      awesompleteInput.current.value = municipality ? municipality.name : '';

      awesomplete = new Awesomplete(awesompleteInput.current, {
        autoFirst: true,
        maxItems: 100,
        filter: Awesomplete.FILTER_STARTSWITH,
        sort: (a: {label: string}, b: {label: string}) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        },
        list: getMunicipalitiesNames(municipalities)
      });

      awesompleteInput.current.addEventListener(
        'awesomplete-selectcomplete',
        () => handleSelect()
      );
    }

    awesomplete.list = getMunicipalitiesNames(municipalities);

    if (municipality) {
      if (municipality.name !== municipalityName) {
        setMunicipalityName(municipality.name);
      }
    } else {
      setMunicipalityName('');
    }

    return (): void => {
      if (awesompleteInput.current) {
        awesompleteInput.current.removeEventListener(
          'awesomplete-selectcomplete',
          () => handleSelect()
        );
      }
      awesomplete.destroy();
    };
  }, [municipalities]);

  return (
    <TextField
      classes={{root: styles.awesomplete}}
      id="municipality-input"
      label="Kommune"
      inputRef={awesompleteInput}
      InputLabelProps={{
        shrink: true
      }}
      value={awesompleteInput.current ? awesompleteInput.current.value : ''}
      InputProps={{
        placeholder: 'Wo liegt die Flächenausweisung',
        onChange: (): void => handleChange(),
        onBlur: (): void => handleBlur()
      }}
      variant="outlined"
    />
  );
};

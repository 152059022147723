import {IAction} from '../interfaces/action';
import {
  OPEN_INFORMATION,
  CLOSE_INFORMATION,
  SELECT_VIEW
} from '../constants/actions';
import {ViewIds} from '../constants/views';

/**
 * Initial state
 */
const initialState = false;

/**
 * Handle whether to show information or not
 */
export default function(
  state: boolean = initialState,
  action: IAction,
  storage: Storage = window.localStorage
): boolean {
  switch (action.type) {
    case OPEN_INFORMATION:
      return true;

    case CLOSE_INFORMATION:
      return false;

    case SELECT_VIEW:
      if (
        action.viewId !== ViewIds.MunicipalIncome &&
        storage.getItem(`view${action.viewId}`)
      ) {
        return false;
      }

      storage.setItem(`view${action.viewId}`, JSON.stringify(true));
      return true;

    default:
      return state;
  }
}

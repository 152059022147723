import { createTheme, adaptV4Theme } from '@mui/material/styles';
// @ts-ignore
import palette from '../../styles/palette.json';

const defaultTheme = createTheme();

const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      sm: 700
    }
  },
  palette: {
    primary: {
      main: palette.primary
    },
    secondary: {
      main: palette.secondary
    }
  },
  overrides: {
    MuiMobileStepper: {
      root: {
        background: 'none',
        width: '100%'
      }
    },
    MuiTab: {
      wrapper: {
        fontSize: '1.0rem'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.9em"
      }
    }
  },
  typography: {
    fontFamily: ['"Droid Sans"', 'Arial', 'Helvetica', 'sans-serif'].join(',')
  }
}));

export default theme;

import {IAction} from '../../interfaces/action';
import {
  FETCH_ISOCHRONES,
  FETCH_ISOCHRONES_SUCCESS,
  FETCH_ISOCHRONES_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for the isochrones
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_ISOCHRONES:
      return true;

    case FETCH_ISOCHRONES_SUCCESS:
      return false;

    case FETCH_ISOCHRONES_ERROR:
      return false;

    default:
      return state;
  }
}

import React, {useEffect} from 'react';
import {HashRouter, Routes, Route} from 'react-router-dom';

import AppInteractive from './app-interactive';
import AppPrint from './print/app/app';

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


/**
 * The main app container
 */
const App = (): JSX.Element => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Routes>
            <Route path="/*" element={<AppInteractive/>}/>
            <Route path="print" element={<AppPrint/>}/>
          </Routes>
        </HashRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

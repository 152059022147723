import {useEffect, useRef} from 'react';

import Overlay from 'ol/Overlay';

interface IProps {
  data: {
    [_: string]: string[];
  };
  mapCanvas: ol.Map;
  position: ol.coordinate;
}

const EcologyOverlayInfowindow = ({
  data,
  mapCanvas,
  position,
  infowindow,
  setInfowindow,
  
}: IProps): null => {

    //const [popupOverlay, setPopupOverlay] = useState<Overlay || null>(null);
    //todo: containers must be created dynamically if none exist. setPosition seems to delete the container not only make it invisible.
    if (!mapCanvas) {
      return
    }

    if (!infowindow) {
        const popupOverlay = new Overlay({
            element: document.querySelector('#popup');,
            autoPan: true,
            autoPanAnimation: {
              duration: 250,
            },
        });
        mapCanvas.addOverlay(popupOverlay);
        const closer = popupOverlay.getElement().querySelector('#popup-closer');
        closer.onclick = function () {
           popupOverlay.setPosition(undefined);
           closer.blur();
           return false;
        }
        setInfowindow(popupOverlay);
    } else {
        if (infowindow instanceof Overlay) {
            const popupOverlay = infowindow; //this is trash, isn't it?
        }
    }
    /*
    useEffect((): (() => any) => {
        if (!infowindow) {
          return
        }
        //const content = container.querySelector('#popup-content');

        return (): void => closer.click();
    }, [infowindow]);
    */

    useEffect((): (() => any) => {
      if (mapCanvas && data && infowindow) {
        const inner_content = Object.keys(data)
          .map((key): string => {
            const header = `<header class="poi__name">${key}</header>`;

            const names = data[key]
              .map(
                (name): string =>
                  `<div class="poi__type">
              ${name}
            </div>`
              )
              .join('<br>');

            return header + names;
          })
          .join('<br>');
        infowindow.getElement().querySelector("#popup-content").innerHTML = `<div class='poi'>${inner_content}</div>`;
        infowindow.setPosition(position);
        infowindow.setOffset( [0, -1*infowindow.getElement().clientHeight - 15] );

      }
      if (infowindow) {
        return (): void => infowindow.getElement().querySelector('#popup-closer').click(); 
      }
    }, [data, infowindow]);

    return null;
};

export default EcologyOverlayInfowindow;

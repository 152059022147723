import {IAction} from '../../interfaces/action';
import {SELECT_MUNICIPALITY, UPDATE_MAP_BOUNDS} from '../../constants/actions';
import {bounds as initialState} from '../../config/map';

/**
 * Handle the current map bounds
 */
export default function(
  state: ol.extent = initialState,
  action: IAction
): ol.extent {
  switch (action.type) {
    case UPDATE_MAP_BOUNDS:
      if (!action.mapBounds) {
        return state;
      }

      return action.mapBounds;
    case SELECT_MUNICIPALITY:
      if (!action.municipality) {
        return state;
      }

      return action.municipality.bounds;

    default:
      return state;
  }
}

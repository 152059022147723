import {IAction} from '../../interfaces/action';
import {
  FETCH_MUNICIPALITIES,
  FETCH_MUNICIPALITIES_SUCCESS,
  FETCH_MUNICIPALITIES_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for municipalities
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_MUNICIPALITIES:
      return true;

    case FETCH_MUNICIPALITIES_SUCCESS:
      return false;

    case FETCH_MUNICIPALITIES_ERROR:
      return false;

    default:
      return state;
  }
}

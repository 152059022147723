import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';

import {AreaTypeIds} from '../../../constants/area-types';
import {NetworkElementGroupIds} from '../../../constants/network-elements';
import {IFollowupCosts} from '../../../interfaces/followup-costs';

import ByCostPhase from './by-cost-phase';
import ByCostObject from './by-cost-object';
import Comparison from './comparison';
import Assumptions from './assumptions';

import styles from '../chart-views.styl';

export interface IProps {
  readonly areaTypeId: AreaTypeIds | null;
  readonly compareCosts: number | null;
  readonly data: Map<NetworkElementGroupIds, IFollowupCosts> | null;
  readonly followupCostsDataByObject: any;
  readonly totalApartments: number | null;
  readonly totalWorkplaces: number | null;
}

/**
 * The followup costs for an area
 */
const FollowupCosts = (props: IProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div>
          <h1>Infrastrukturfolgekosten (innere Erschließung)</h1>
        </div>
      </header>

      <div className={styles.tabContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_, newValue): void => {
            setSelectedTab(newValue);
          }}>
          <Tab disableRipple label={'Kostenphase'} />
          <Tab disableRipple label={'Kostenträger'} />
          <Tab disableRipple label={'Vergleichswert'} />
          <Tab disableRipple label={'Annahmen'} />
        </Tabs>
      </div>
      <div className={styles.contentContainer}>
        {selectedTab === 0 && (
          <ByCostPhase data={props.data} areaTypeId={props.areaTypeId} />
        )}
        {selectedTab === 1 && (
          <ByCostObject
            data={props.data}
            followupCostsDataByObject={props.followupCostsDataByObject}
            areaTypeId={props.areaTypeId}
          />
        )}
        {selectedTab === 2 && (
          <Comparison
            data={props.data}
            compareCosts={props.compareCosts}
            areaTypeId={props.areaTypeId}
            totalApartments={props.totalApartments}
            totalWorkplaces={props.totalWorkplaces}
          />
        )}
        {selectedTab === 3 && <Assumptions />}
      </div>
    </section>
  );
};

export default FollowupCosts;

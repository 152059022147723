import React, {createRef, forwardRef} from 'react';

interface IProps {
    hidePopup?: boolean,
    mapPopup: div,
}
 
const MapPopup = ({
}: IProps): JSX.Element => {
  
  return (
    <div id="popup">
        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
        <div id="popup-content"></div>
    </div>
  );
  
};

export default MapPopup;
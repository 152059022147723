import {AreaTypeIds} from './area-types';
import {Ecologies} from './ecologies';
import {ViewIds, ViewTitles} from './views';
import {IEcology} from '../interfaces/ecology';

/**
 * The menu item type
 */
export interface IMenuItem {
  title?: string;
  viewId?: ViewIds;
  hasInformation?: boolean;
  hideForTypes?: AreaTypeIds[];
  hasChart?: boolean;
}

const ecologySubitems: IMenuItem[] = [];

Ecologies.forEach((value: IEcology, key: ViewIds) =>
  ecologySubitems.push({
    title: value.shortName,
    viewId: key,
    hasInformation: true,
    hasChart: false
  })
);

/**
 * The available menu items
 */
export const menuItems: IMenuItem[] = [
  {
    title: ViewTitles.get(ViewIds.PopulationDevelopment),
    viewId: ViewIds.PopulationDevelopment,
    hideForTypes: [AreaTypeIds.Industrial, AreaTypeIds.Retail],
    hasInformation: true,
    hasChart: true
  },
  {
    title: ViewTitles.get(ViewIds.WorkplaceDevelopment),
    viewId: ViewIds.WorkplaceDevelopment,
    hideForTypes: [AreaTypeIds.Residential, AreaTypeIds.Retail],
    hasInformation: true,
    hasChart: true
  },
  {
    title: ViewTitles.get(ViewIds.TrafficReachability),
    viewId: ViewIds.TrafficReachability,
    hasInformation: true,
    hasChart: false
  },
  {
    title: ViewTitles.get(ViewIds.TrafficEnvironment),
    viewId: ViewIds.TrafficEnvironment,
    hasInformation: true,
    hasChart: false
  },
  ...ecologySubitems,
  {
    title: ViewTitles.get(ViewIds.FollowupCosts),
    viewId: ViewIds.FollowupCosts,
    hideForTypes: [AreaTypeIds.Retail],
    hasInformation: true,
    hasChart: true
  },
  {
    title: ViewTitles.get(ViewIds.MunicipalIncome),
    viewId: ViewIds.MunicipalIncome,
    hideForTypes: [AreaTypeIds.Retail],
    hasInformation: false,
    hasChart: false
  },
  {
    title: ViewTitles.get(ViewIds.LocationCompetition),
    viewId: ViewIds.LocationCompetition,
    hideForTypes: [AreaTypeIds.Residential, AreaTypeIds.Industrial],
    hasInformation: true,
    hasChart: false
  }
];

import React from 'react';
import clsx from 'clsx';
import Icon from '../../shared/icon';
import {useNavigate, useMatch} from 'react-router-dom';
import {ViewIds} from '../../../constants/views';

import styles from './menu-item-button.styl';

interface IMenuItemButtonProps {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onInfoClick?: () => void;
  title?: string | JSX.Element;
  isActive: boolean;
  hasInformation?: boolean;
  viewId?: ViewIds;
}

const MenuItemButton = ({
  className,
  onClick,
  onInfoClick,
  title,
  isActive,
  hasInformation,
  viewId
}: IMenuItemButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const infoIsOpen = Boolean(useMatch(`/views/${viewId}/info`));
  const containerClasses = clsx(styles.container, className);

  return (
    <div className={containerClasses}>
      <button className={clsx(styles.menuItemButton)} onClick={onClick}>
        {title}
      </button>
      {isActive && hasInformation && (
        <button
          className={clsx(styles.menuItemButtonInfo)}
          onClick={(): void => {
            if (onInfoClick) {
              onInfoClick();
            }
            if (infoIsOpen) {
              navigate(-1);
            } else {
              navigate('./'+viewId+'/info');
            }
          }}>
          <Icon type="info" />
        </button>
      )}
    </div>
  );
};

export default MenuItemButton;

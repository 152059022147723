import {AreaTypeIds} from './area-types';
import {ViewIds} from './views';

export const informationVideos: Map<string, string> = new Map();

/* eslint-disable max-len */

informationVideos.set(
  `${ViewIds.PopulationDevelopment}#${AreaTypeIds.Residential}`,
  'h3adrXuiSbY'
);

informationVideos.set(
  `${ViewIds.WorkplaceDevelopment}#${AreaTypeIds.Retail}`,
  'h3adrXuiSbY'
);

informationVideos.set(
  `${ViewIds.WorkplaceDevelopment}#${AreaTypeIds.Industrial}`,
  'h3adrXuiSbY'
);

informationVideos.set(
  `${ViewIds.TrafficReachability}#${AreaTypeIds.Residential}`,
  'ad-DiJBYzdE'
);

informationVideos.set(
  `${ViewIds.TrafficReachability}#${AreaTypeIds.Retail}`,
  'ad-DiJBYzdE'
);

informationVideos.set(
  `${ViewIds.TrafficReachability}#${AreaTypeIds.Industrial}`,
  'ad-DiJBYzdE'
);

informationVideos.set(
  `${ViewIds.TrafficEnvironment}#${AreaTypeIds.Residential}`,
  'NMw3NhIBAvc'
);

informationVideos.set(
  `${ViewIds.TrafficEnvironment}#${AreaTypeIds.Retail}`,
  'NMw3NhIBAvc'
);

informationVideos.set(
  `${ViewIds.TrafficEnvironment}#${AreaTypeIds.Industrial}`,
  'NMw3NhIBAvc'
);

informationVideos.set(
  `${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyForest}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Residential}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyForest}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Industrial}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyForest}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Retail}`,
  'OKVfJyfg84U'
);

informationVideos.set(
  `${ViewIds.FollowupCosts}#${AreaTypeIds.Residential}`,
  'lGrxCojqjUY'
);

informationVideos.set(
  `${ViewIds.FollowupCosts}#${AreaTypeIds.Retail}`,
  'lGrxCojqjUY'
);

informationVideos.set(
  `${ViewIds.FollowupCosts}#${AreaTypeIds.Industrial}`,
  'lGrxCojqjUY'
);

informationVideos.set(
  `${ViewIds.MunicipalIncome}#${AreaTypeIds.Residential}`,
  'ETIoCX3YHZY'
);

informationVideos.set(
  `${ViewIds.MunicipalIncome}#${AreaTypeIds.Retail}`,
  'ETIoCX3YHZY'
);

informationVideos.set(
  `${ViewIds.MunicipalIncome}#${AreaTypeIds.Industrial}`,
  'ETIoCX3YHZY'
);

informationVideos.set(
  `${ViewIds.LocationCompetition}#${AreaTypeIds.Retail}`,
  '_qquOl5eouc'
);

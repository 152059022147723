import {IAction} from '../interfaces/action';
import {OPEN_LEGEND} from '../constants/actions';

/**
 * Open the legend
 */
export default function(): IAction {
  return {
    type: OPEN_LEGEND
  };
}

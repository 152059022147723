import React, {useState, useRef, useEffect, forwardRef, createRef} from 'react';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';

import Canvas from './canvas';
import MapPopup from './map-popup';
import AreaShape from './area-shape';
import EcologyOverlay from './ecology-overlay';
import Isochrones from './isochrones';
import Pois from './pois';
import TrafficRoutes from './traffic-routes';
import PopulationDensityOverlay from './population-density-overlay';
import MunicipalitiesOverlay from './municipalities-overlay';
import MapTypeControls from './map-type-controls/map-type-controls';

import {ROADMAP} from '../../constants/baselayer'; //ol-save

import selectAreaShape from '../../actions/area/select-area-shape';
import getShapeBounds from '../../libs/get-shape-bounds';
import updateMapBounds from '../../actions/map/update-map-bounds';

import mapDataSelector from '../../selectors/map-data';

import {ViewIds} from '../../constants/views';
import {zoom, center} from '../../config/map';

import {AreaTypeIds} from '../../constants/area-types';
import { IRoute } from '../../interfaces/routes';

import styles from './map.styl';

import * as ol from "ol";
import 'ol/ol.css';


/**
 * The map container
 */
const InteractiveMap = ({hide = false}: {hide?: boolean}): JSX.Element => {
  /**
   * The map state
   */
  const [mapCanvas, setMapCanvas] = useState<ol.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number | null>(null);
  const [layerset, setLayerset] = useState(ROADMAP);
  const [newTrafficRoutes, setNewTrafficRoutes] = useState<IRoute[] | null>(null);

  const mapData = useSelector(mapDataSelector);
  const dispatch = useDispatch();
  
  /**
   * Handle the init of the OpenLayers Map (acts like a g*ogle maps canvas)
   */
  const handleMapCanvasInit = (canvas: ol.Map): void => {
    setMapCanvas(canvas);
  };

  const {view, areaTypeId} = mapData;
  const isLocationCompetition = view === ViewIds.LocationCompetition;
  const isRetailReachability =
    view === ViewIds.TrafficReachability && areaTypeId === AreaTypeIds.Retail;
  const showPopulationDensityOverlay =
    isLocationCompetition || isRetailReachability;
  const mapPopupRef = useRef();
  /*const MapPopup = forwardRef((props, ref) => (
    <div id="popup" ref={ref} hidden={props.hidePopup}>
        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
        <div id="popup-content"></div>
    </div>
  ));*/

  /**
   * Trigger creation of a new route
   */
  // const addNewTrafficRoute = (destination: ol.coordinate): void => {
  //   if (!mapData.areaCenter) {
  //     return;
  //   }
  //   // dispatch(addTrafficRoute(mapData.trafficRoutes, mapData.areaCenter, destination));
  //   dispatch(addTrafficRoute(newTrafficRoutes, mapData.areaCenter, destination));
  // };
  // const removeThisTrafficRoute = (route) => {
  //   dispatch(removeTrafficRoute(newTrafficRoutes, route))
  // }
  //
  
  /**
   * wofür ist dieser hack? 
   */
  useEffect(
    (): (() => void) => {
      return
    }, 
    [mapCanvas, newTrafficRoutes]
  );

  /**
   * Render the Component

   */
  return (
    <div className={clsx(styles.container, 'map', hide && styles.noDisplay)}>
        <MapPopup
          hidePopup={false}
        />
        <Canvas
          bounds={mapData.bounds}
          center={center}
          zoom={zoom}
          onInit={handleMapCanvasInit}
          onZoomChange={setZoomLevel}
          hide={hide}
          layerset={layerset}
          setLayerset={setLayerset}
        />
        <MapTypeControls mapCanvas={mapCanvas} layerset={layerset} setLayerset={setLayerset} />
        {/* USE useCallback!!! https://stackoverflow.com/questions/29100774/reactjs-setstate-on-parent-inside-child-component */}
        <EcologyOverlay
          mapCanvas={mapCanvas}
          view={view}
          ecologyLayers={mapData.ecologyLayers}
          isEcologyView={mapData.isEcologyView}
        />
      
        <TrafficRoutes
          mapCanvas={mapCanvas}
          view={view}
          show={view === ViewIds.TrafficEnvironment}
          // trafficRoutes={newTrafficRoutes}
          // setTrafficRoutes={setNewTrafficRoutes}
          // addNewTrafficRoute={addNewTrafficRoute}
          // removeRoute={removeThisTrafficRoute}
        />
        
        <AreaShape
          mapCanvas={mapCanvas}
          shape={mapData.areaShape}
          onChange={(shape): void => {
            dispatch(selectAreaShape(shape));
            const mapBounds = getShapeBounds(shape, 2);
            dispatch(updateMapBounds(mapBounds));
          }}
          hide={hide}
        />
        
        <Isochrones
          mapCanvas={mapCanvas}
          isochrones={mapData.isochrones}
          show={view === ViewIds.TrafficReachability}
        />
        
        {[ViewIds.TrafficReachability, ViewIds.LocationCompetition].includes(
          view
        ) && <Pois mapCanvas={mapCanvas} pois={mapData.pois} />}
        
                
        <PopulationDensityOverlay
          mapCanvas={mapCanvas}
          show={showPopulationDensityOverlay}
        />
        
        <MunicipalitiesOverlay
          mapCanvas={mapCanvas}
          zoom={zoomLevel}
          view={view}
        />
    </div>
  );
};

export default InteractiveMap;

import {IFollowupCostsData} from '../interfaces/followup-costs-data';
import {IAction} from '../interfaces/action';
import {FETCH_FOLLOWUP_COSTS_DATA_SUCCESS} from '../constants/actions';

/**
 * Initial state
 */
const initialState: IFollowupCostsData = {
  byCostsObject: null,
  compareValues: null,
  referenceValues: null
};

/**
 * Handle the followup costs data
 */
export default function(
  state: IFollowupCostsData = initialState,
  action: IAction
): IFollowupCostsData {
  switch (action.type) {
    case FETCH_FOLLOWUP_COSTS_DATA_SUCCESS:
      return action.followupCostsData || state;
    default:
      return state;
  }
}

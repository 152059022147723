import React, {useEffect, useState, useRef} from 'react';
//import {useMatch} from 'react-router-dom';

import {poiCategories, IPoiCategory} from '../../constants/pois';
import ReachabilityPOIsInfowindow from './map-reachability-pois-infowindow';
import {Point} from 'ol/geom';
import {fromLonLat} from "ol/proj";
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import {Icon, Fill, Stroke, Style} from 'ol/style';
import Composite from 'ol-ext/filter/Composite';

interface IProps {
  pois: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
  mapCanvas: ol.Map | null;
}

// type IPoiMarker = {
//   marker: Point | null;
//   poi: GeoJSON.Feature<GeoJSON.Point>;
//   poiCategory?: IPoiCategory;
// };

const createPoi = (
  poi: GeoJSON.Feature<GeoJSON.Point>
): GeoJSON.Feature<GeoJSON.Point> => {
  const poiCategory = poiCategories.find(
    // eslint-disable-next-line camelcase
    category => category.key === poi?.properties?.projektcheck_category
  );
  poi.properties.poiCategory = poiCategory;
  
  const iconName = poiCategory?.iconName;
  poi.properties.iconName=iconName;

  const iconSize = poiCategory?.iconSize;
  poi.properties.iconSize=iconSize;
  
  const isMultiPoint = Array.isArray(poi.geometry.coordinates[0]);
 
  poi.geometry.coordinates = isMultiPoint ? poi.geometry.coordinates[0] : poi.geometry.coordinates;

  return poi;
};

/**
 * Some POIs
 */
export const Pois = ({
  pois, 
  mapCanvas
}: IProps): null => {
  const [markers, setMarkers] = useState<VectorLayer>([]);
  //const [infowindow, setInfowindow] = useState<Overlay | null>(null);
  const styleCache = {};

  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    if (markers instanceof VectorLayer) {
        mapCanvas.removeLayer(markers);
    }

    if (!pois) {
      return;
    }

    //if (pois && mapCanvas && markers.length === 0) {
      pois.features = pois.features.map(poi => createPoi(poi));
//       
//       poiMarkers.forEach(({marker, poi, poiCategory}) => {
//         if (marker) {
// //           marker.addListener('mouseout', () => closeInfowindow());
// //           marker.addListener('mouseover', () =>
// //             openInfowindow({marker, poi, poiCategory})
// //           );
//         }
//       });
      const poiLayer = new VectorLayer({
         source: new VectorSource({
           features: new GeoJSON().readFeatures(
              pois, 
              {
                featureProjection: 'EPSG:3857',
                dataProjection: 'EPSG:4326'
              }
           )
         }),
        zIndex: 99,
         style: (feature, resolution) => {
           if (!styleCache[feature.getProperties()['iconName']]) {
             const size = feature.getProperties()['iconSize']==='small'?12:32;
             styleCache[feature.getProperties()['iconName']] = new Style({
               image: new Icon({
                   src: './images/poi-' + feature.getProperties()['iconName'] + '.png',
                   //size: [size, size],
                   crossOrigin: 'anonymous',
                   scale: 0.5,
               })
             });
          }
          return styleCache[feature.getProperties()['iconName']]
        },
        title: 'POIs',
        type: 'data',
      });

      const comp = new Composite({operation: 'multiply'});

      poiLayer.addFilter(comp);
      //for (var featkey in poiLayer.getSource().getFeatures()) {
      //    poiLayer.getSource().getFeatures()[featkey].setGeometry(
      //       poiLayer.getSource().getFeatures()[featkey].clone().getGeometry().transform('EPSG:4326','EPSG:3857')
      //    )
      //}
      mapCanvas.addLayer(poiLayer);
      setMarkers(poiLayer);

    //}

    return (): void => {
      if (poiLayer instanceof VectorLayer) {
        mapCanvas.removeLayer(poiLayer);
      }
      if (markers instanceof VectorLayer) {
        mapCanvas.removeLayer(markers);
      }
    };
  }, [pois, mapCanvas]);



  if ( mapCanvas && pois && markers instanceof VectorLayer) {
    return (
      <ReachabilityPOIsInfowindow
        mapCanvas={mapCanvas}
/*        infowindow={infowindow},
        setInfowindow={setInfowindow},*/
        markers={markers}
      />
    );
  }

  return  null
};

export default Pois;

import {IAction} from '../../interfaces/action';
import {ViewIds} from '../../constants/views'; // eslint-disable-line no-unused-vars, max-len
import {
  FETCH_ECOLOGY_LAYER,
  FETCH_ECOLOGY_LAYER_SUCCESS,
  FETCH_ECOLOGY_LAYER_ERROR
} from '../../constants/actions';

const initialState: Map<ViewIds, boolean> = new Map();

/**
 * Handle the loading state for the ecology layers
 */
export default function(
  state: Map<ViewIds, boolean> = initialState,
  action: IAction
): Map<ViewIds, boolean> {
  switch (action.type) {
    case FETCH_ECOLOGY_LAYER: {
      if (!action.viewId) {
        return state;
      }

      const clonedState = new Map(state);
      clonedState.set(action.viewId, true);
      return clonedState;
    }

    case FETCH_ECOLOGY_LAYER_SUCCESS:
    case FETCH_ECOLOGY_LAYER_ERROR: {
      if (!action.viewId) {
        return state;
      }

      const clonedState = new Map(state);
      clonedState.set(action.viewId, false);
      return clonedState;
    }

    default:
      return state;
  }
}

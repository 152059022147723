import React from 'react';
import {useParams} from 'react-router-dom';

import styles from './instruction.styl';

import {steps} from '../../config/steps';

/*
 * An instruction overlay
 */
const Instruction = ({
  isDesktop = false
}: {
  isDesktop: boolean;
}): JSX.Element | null => {
  const {configurationStep} = useParams();
  if (configurationStep) {
    const isStepMunicipality =
      steps[configurationStep].number === steps.municipality.number;

    const isStepArea = steps[configurationStep].number === steps.area.number;

    const isStepDetails =
      steps[configurationStep].number === steps.details.number;

    const isStepUsage = steps[configurationStep].number === steps.usage.number;

    return (
      <>
        {isStepMunicipality && (
          <section className={styles.instruction}>
            Bitte geben Sie oben die Kommune an, in der die Flächenausweisung
            liegt und klicken Sie anschließend unten auf{' '}
            <span className={styles.highlight}>WEITER</span>.
          </section>
        )}

        {isStepArea && (
          <section className={styles.instruction}>
            Zeichnen Sie bitte grob die geplante Flächenausweisung in die Karte
            ein. Klicken Sie anschließend auf{' '}
            <span className={styles.highlight}>WEITER</span>.
            <br />
            {isDesktop && <br />}
            <span className={styles.additionalTextDesktop}>
              Sie können mit den Symbolen + und - auf der Karte zoomen und mit
              gedrückter Maustaste die Karte verschieben.
            </span>
          </section>
        )}

        {isStepDetails && (
          <section className={styles.instruction}>
            Geben Sie bitte oben den Namen des Plangebiets ein und passen Sie bei
            Bedarf dessen Größe an. Klicken Sie anschließend auf{' '}
            <span className={styles.highlight}>WEITER</span>.
          </section>
        )}

        {isStepUsage && (
          <section className={styles.instruction}>
            Wählen Sie bitte oben jeweils eine Gebietsart, einen Gebietstyp sowie
            gegebenenfalls die Bezugsdauer aus.
          </section>
        )}
      </>
    );
  }
  return null;
};

export default Instruction;

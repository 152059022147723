import {IAction} from '../../interfaces/action';
import {
  FETCH_FOLLOWUP_COSTS_DATA,
  FETCH_FOLLOWUP_COSTS_DATA_SUCCESS,
  FETCH_FOLLOWUP_COSTS_DATA_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for the followup costs data
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_FOLLOWUP_COSTS_DATA:
      return true;

    case FETCH_FOLLOWUP_COSTS_DATA_SUCCESS:
      return false;

    case FETCH_FOLLOWUP_COSTS_DATA_ERROR:
      return false;

    default:
      return state;
  }
}

import {IAction} from '../../interfaces/action';
import {IMunicipality} from '../../interfaces/municipality';
import {IMunicipalityData} from '../../interfaces/municipality-data';

import {
  SELECT_MUNICIPALITY,
  FETCH_MUNICIPALITY_DATA,
  FETCH_MUNICIPALITY_DATA_SUCCESS,
  FETCH_MUNICIPALITY_DATA_ERROR
} from '../../constants/actions';
import fetchApi from '../../apis/fetch-municipality-data';

/**
 * Error while fetching some municipality
 */
export function fetchMunicipalityDataError(id: string, error: Error): IAction {
  return {
    type: FETCH_MUNICIPALITY_DATA_ERROR,
    municipalityId: id,
    error
  };
}

/**
 * Success while fetching some municipality
 */
export function fetchMunicipalityDataSuccess(
  municipalityId: string,
  municipalityData: IMunicipalityData
): IAction {
  return {
    type: FETCH_MUNICIPALITY_DATA_SUCCESS,
    municipalityId,
    municipalityData
  };
}

/**
 * Fetching some municipality data
 */
export function fetchMunicipalityData(id: string): IAction {
  return {
    type: FETCH_MUNICIPALITY_DATA,
    municipalityId: id
  };
}

/**
 * Select a municipality
 */
export function selectMunicipality(municipality: IMunicipality): IAction {
  return {
    type: SELECT_MUNICIPALITY,
    municipality
  };
}

/**
 * Fetch some municipality data
 */
export default function(
  municipality: IMunicipality,
  fetchMunicipalityDataRemotely: (
    id: string
  ) => Promise<IMunicipalityData> = fetchApi
) {
  return dispatch => {
    const id = municipality.id;

    dispatch(selectMunicipality(municipality));
    dispatch(fetchMunicipalityData(id));

    return fetchMunicipalityDataRemotely(id)
      .then((municipalityData: IMunicipalityData) =>
        dispatch(fetchMunicipalityDataSuccess(id, municipalityData))
      )
      .catch(error => dispatch(fetchMunicipalityDataError(id, error)));
  };
}

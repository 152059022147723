import {Ecologies} from '../../constants/ecologies';
import fetchEcologyLayer from './fetch-ecology-layer';

/**
 * Fetch all ecology layers data
 */
export default function() {
  return dispatch => {
    Ecologies.forEach((ecology, view) => {
      dispatch(fetchEcologyLayer(view));
    });
  };
}

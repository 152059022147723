import {IGraph} from '../../../interfaces/graph';
import {IYearValue} from '../../../interfaces/year-value';
import React, {useEffect, useState} from 'react';
import {withResizeDetector} from 'react-resize-detector';

// import {
//   XYPlot,
//   XAxis,
//   YAxis,
//   HorizontalGridLines,
//   VerticalBarSeries,
//   Hint
// } from 'react-vis';
import { 
  BarChart, Bar, Cell, XAxis as XAxisRe, YAxis as YAxisRe, CartesianGrid, Tooltip, Legend as LegendRe, ResponsiveContainer 
} from 'recharts';
import getMaxValue from '../../../libs/get-max-stacked-graphs-value';

import clsx from 'clsx';

// import Legend from '../../shared/legend';
import Loading from '../../shared/loading/loading';
import {labelMap} from '../../../constants/population-development';
import styles from './chart.styl';

type IData = Map<any, IYearValue[]>;
interface IProps {
  data: IData | null;
  width: number;
  height: number;
  yAxisTitle: string;
  yAxisSubtitle: string;
  colorMap: Map<any, string>;
  totalApartments: number | null;
  print?: boolean;
}
interface IGraphs {
  graphs: IGraph<any, number, number>[] | null;
  graphsRe: any[] | null;
  domain: [number, number] | null;
  yearTicks: number[] | null;
}

const calculateGraphs = (data: IData): IGraph<any, number, number>[] => {
  const graphs: IGraph<any, number, number>[] = [];

  data.forEach((bucket: IYearValue[], key: string): void => {
    graphs.push({
      key,
      data: bucket.map((entry: IYearValue): {x: number; y: number} => ({
        x: entry.year,
        y: entry.value
      }))
    });
  });

  return graphs;
};

const calculateGraphsRe = (data: IData): IGraph<any, number, number>[] => {
  const graphsRe: any[] = [];
  const yearsMap = {}
  const years = data.get(1).map(item=> item['year']);
  years.forEach(
    year => {
      data.forEach(
        (cohorte, key) => {
          if (!yearsMap[year]) {
            yearsMap[year] = {};
            yearsMap[year]['year'] = year;
          }
          yearsMap[year][key] = cohorte.filter(entry => entry['year']==year)[0]['value'];
        }
      )
    }
  )
  for (const year in yearsMap) {
    graphsRe.push(yearsMap[year]);
  }

  return graphsRe;
};

const useData = (data: IData | null): IGraphs => {
  const [graphs, setGraphs] = useState<IGraph<any, number, number>[] | null>(
    null
  );
  const [graphsRe, setGraphsRe] = useState(
    null
  );
  const [domain, setDomain] = useState<[number, number] | null>(null);
  const [yearTicks, setYearTicks] = useState<number[] | null>(null);

  useEffect((): void => {
    if (graphs) {
      const firstDataRow = graphs[0].data;
      const minYear: number = firstDataRow[0].x;
      const maxYear: number = firstDataRow[firstDataRow.length - 1].x;
      const maxValue: number = getMaxValue(graphs);
      const roundTo: number = maxValue > 1000 ? 1000 : 50;
      const minDomain = 0;
      const maxDomain: number = Math.floor(maxValue / roundTo + 1) * roundTo;
      const newYearTicks: number[] = [];

      for (let year: number = minYear; year <= maxYear; year++) {
        if (year % 5 === 0) {
          newYearTicks.push(year);
        }
      }

      setDomain([minDomain, maxDomain]);
      setYearTicks(newYearTicks);
    }
  }, [graphs]);

  useEffect((): void => {
    if (data) {
      setGraphs(calculateGraphs(data));
      setGraphsRe(calculateGraphsRe(data));
    }
  }, [data]);

  return {
    graphs,
    graphsRe,
    domain,
    yearTicks
  };
};

const getPlural = (
  hoveredValue: {
    ageGroup: number;
    x: number;
    y: number;
    y0: number;
  } | null,
  data: IData | null
): string => {
  let text = '';

  if (hoveredValue && data) {
    let population = 0;
    data.forEach(ageGroup => {
      const popPerYear = ageGroup.find(({year}) => year === hoveredValue.x);
      population += popPerYear ? popPerYear.value : 0;
    });

    const value = hoveredValue.y - (hoveredValue.y0 || 0);
    const ageText = labelMap.get(hoveredValue.ageGroup);

    if (value < 1) {
      text = 'Unter 1 Bewohner/in';
    } else if (value > 1 && Math.round(value) < 2) {
      text = 'ca. 1 Bewohner/in';
    } else {
      text = `${Math.round(value)} Bewohner/innen`;
    }

    return `${text} im Alter von ${ageText} von ${Math.round(
      population
    ).toLocaleString('de')} insgesamt`;
  }

  return '';
};
/**
 * Bar Chart for the population development
 */
const Chart = ({
  data,
  yAxisTitle,
  yAxisSubtitle,
  colorMap,
  totalApartments,
  print,
  width
}: IProps): JSX.Element => {
  const {graphs, graphsRe, domain, yearTicks} = useData(data);
  const [hoveredValue, setHoveredValue] = useState(null);

  const graphContainerClasses = clsx(
    styles.graphContainer,
    !print && styles.graphContainerDisplayColumn
  );

  if (!graphs || !graphsRe || !domain || !yearTicks ) {
    return <Loading />;
  }

  return (
    <>
      <p className="intro">Anzahl und Altersstruktur der Bewohner/innen</p>
      <p>
        der {totalApartments && totalApartments.toLocaleString('de')} im
        Plangebiet neu gebauten Wohnungen.
      </p>
      <div className={graphContainerClasses}>
      <BarChart
          width={500}
          height={300}
          data={graphsRe}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxisRe dataKey="x" />
          <YAxisRe />
          <Tooltip />
          <LegendRe />
          {
            Array.from(data).map(
              (obj, i) => {
                return <Bar name={labelMap.get(obj[0])} dataKey={obj[0]} stackId="a" fill={colorMap.get(obj[0])}/>
              }
            )
          }
          {/* <Bar label={labelMap.get(1)} dataKey="1" stackId="a" fill={colorMap.get(1)}/>
          <Bar label={labelMap.get(2)} dataKey="2" stackId="a" fill={colorMap.get(2)}/>
          <Bar label={labelMap.get(3)} dataKey="3" stackId="a" fill={colorMap.get(3)}/>
          <Bar label={labelMap.get(4)} dataKey="4" stackId="a" fill={colorMap.get(4)}/>
          <Bar label={labelMap.get(5)} dataKey="5" stackId="a" fill={colorMap.get(5)}/>
          <Bar label={labelMap.get(6)} dataKey="6" stackId="a" fill={colorMap.get(6)}/> */}
        </BarChart>
        {/* <XYPlot
          width={print ? 600 : width}
          onMouseLeave={(): void => setHoveredValue(null)}
          height={250}
          margin={{left: 120, right: 20, top: 10, bottom: 90}}
          yDomain={domain}
          stackBy="y">
          <HorizontalGridLines />

          {graphs.map(
            (graph): JSX.Element => (
              <VerticalBarSeries
                onValueMouseOver={(value): void =>
                  setHoveredValue({...value, ageGroup: graph.key})
                }
                key={graph.key}
                data={graph.data}
                color={colorMap.get(graph.key)}
                size={10}
                width={10}
              />
            )
          )}

          <XAxis
            tickValues={yearTicks}
            tickFormat={(tick: number): number => Number(tick)}
          />

          <YAxis
            tickFormat={(tick: number): string => tick.toLocaleString('de')}
          />

          <span className="visualization--y-axis-label-rotated">
            <div>{yAxisTitle}</div>
            <div>{yAxisSubtitle}</div>
          </span>

          {hoveredValue && (
            <Hint
              value={hoveredValue}
              className="visualization__hint visualization__hint--bar-chart visualization__hint--population"
              align={{
                horizontal: Hint.ALIGN.RIGHT,
                vertical: Hint.ALIGN.TOP
              }}>
              <span className="visualization__hint__value ">
                {getPlural(hoveredValue, data)}
              </span>
            </Hint>
          )}
        </XYPlot> */}

        {/* <Legend
          className={styles.legend}
          entries={graphs.map((graph): {
            color: string;
            description: string;
          } => ({
            color: colorMap.get(graph.key) || '',
            description: labelMap.get(graph.key) || ''
          }))}
        /> */}
      </div>
    </>
  );
};

export default withResizeDetector(Chart);

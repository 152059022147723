import {combineReducers} from 'redux';

import center from './center';
import densityId from './density-id';
import influx from './influx';
import name from './name';
import shape from './shape';
import size from './size';
import typeId from './type-id';

export default combineReducers({
  center,
  densityId,
  influx,
  name,
  shape,
  size,
  typeId
});

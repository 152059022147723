import {
  IMunicipalityData,
  IAreaUsage,
  IAreaUsageData
} from '../../interfaces/municipality-data';
import {IGraph} from '../../interfaces/graph';
import {IGraphDatum} from '../../interfaces/graph-datum';
import React, {useState, useEffect} from 'react';
import sizeme from 'react-sizeme';
import {
  XYPlot,
  XAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint
} from 'react-vis';
import AreaUsageTable from './area-usage-table';
import {getTotal} from '../../libs/math-functions';
import Loading from '../shared/loading/loading';
import styles from './recent-development.styl';
import clsx from 'clsx';

interface IProps {
  data: IMunicipalityData | null;
  size: {width: number; height: number};
  print?: boolean;
}

const COLOR = new Map<string, string>();
COLOR.set('gewerbe', '#686E72');
COLOR.set('verkehr', '#BACCD3');
COLOR.set('wald', '#5BC16D');
COLOR.set('landwirtschaft', '#C2E372');
COLOR.set('siedlung', '#F5BB18');
COLOR.set('erholung', '#FF8B60');
COLOR.set('wohnen', '#CD6781');

const LABEL = new Map<string, string>();
LABEL.set('gewerbe', 'Gewerbe');
LABEL.set('verkehr', 'Verkehr');
LABEL.set('wald', 'Wald');
LABEL.set('landwirtschaft', 'Landwirtschaft');
LABEL.set('siedlung', 'Sonst. Siedlungsfläche');
LABEL.set('erholung', 'Erholung');
LABEL.set('wohnen', 'Wohnen');

const calculateGraphData = (
  municipalityData: IAreaUsageData,
  compareData: IAreaUsageData
): IGraph<string, number, number>[] => {
  const graphs: IGraph<string, number, number>[] = [];
  const municipalityDataTotal: number = getTotal(municipalityData);
  const compareDataTotal: number = getTotal(compareData);

  let offsetMunicipality = 0;
  let offsetCompare = 0;

  COLOR.forEach((color: string, key: string): void => {
    const xMunicipality: number =
      (municipalityData[key] / municipalityDataTotal) * 100;

    const xCompare: number = (compareData[key] / compareDataTotal) * 100;

    graphs.push({
      key,
      data: [
        {
          y: 0,
          x: xCompare,
          offset: offsetCompare,
          absolute: compareData[key]
        },
        {
          y: 1,
          x: xMunicipality,
          offset: offsetMunicipality,
          absolute: municipalityData[key]
        }
      ]
    });

    offsetMunicipality += xMunicipality;
    offsetCompare += xCompare;
  });

  return graphs;
};

/**
 * Update the state
 */
const useData = (
  data: IMunicipalityData | null
): {
  areaUsage: IAreaUsage | null;
  graphs: IGraph<string, number, number>[] | null;
} => {
  const [graphs, setGraphs] = useState<IGraph<string, number, number>[] | null>(
    null
  );

  useEffect((): void => {
    if (data) {
      const areaUsage = data.areaUsage;
      const municipalityData: IAreaUsageData = areaUsage.data[0];
      const compareData: IAreaUsageData = areaUsage.data[1];

      setGraphs(calculateGraphData(municipalityData, compareData));
    }
  }, [data]);

  return {
    areaUsage: data && data.areaUsage,
    graphs
  };
};

/**
 * Calculate the data for the graphs
 */

/**
 * Render the hint
 */
const renderHint = (hoveredValue): JSX.Element | null => {
  if (!hoveredValue || (!hoveredValue.offset && hoveredValue.offset !== 0)) {
    return null;
  }

  const value: IGraphDatum<number, number> = {
    y: hoveredValue.y,
    x: (hoveredValue.x - hoveredValue.offset) / 2 + hoveredValue.offset
  };

  const percentage = Math.trunc(hoveredValue.x - hoveredValue.offset);

  return (
    <Hint
      value={value}
      align={{
        horizontal: Hint.ALIGN.RIGHT,
        vertical: Hint.ALIGN.TOP
      }}>
      <div className="visualization__hint visualization__hint--bar-chart">
        <span className="visualization__hint__value">
          {Number(hoveredValue.absolute).toLocaleString('de')} ha
        </span>
        <span className="visualization__hint__percentage">
          {percentage >= 1 ? percentage : '<1'}%
        </span>
      </div>
    </Hint>
  );
};

/**
 * Bar Chart for the area usage in comparison
 */
const AreaUsage = ({data, size, print}: IProps): JSX.Element => {
  const [hoveredValue, setHoveredValue] = useState(null);
  const {graphs, areaUsage} = useData(data);

  if (!graphs || !areaUsage) {
    return <Loading />;
  }

  return (
    <div>
      <p>
        Flächennutzung {areaUsage.year} im Vergleich zu Kommunen mit ähnlicher
        Einwohnerzahl.
      </p>

      <XYPlot
        width={print ? 750 : size.width}
        height={print ? 170 : 286}
        margin={{left: 180, right: print ? 50 : 20, top: 10, bottom: 60}}
        className="visualization--area-usage"
        xDomain={[0, 100]}
        stackBy="x">
        <VerticalGridLines tickTotal={6} />

        {graphs.map(
          (graph): JSX.Element => (
            <HorizontalBarSeries
              key={graph.key}
              data={graph.data}
              color={COLOR.get(graph.key)}
              size={10}
              height={10}
              onValueMouseOver={(hoveredData): void =>
                setHoveredValue(hoveredData)
              }
              onValueMouseOut={(): void => setHoveredValue(null)}
            />
          )
        )}
        <XAxis title="Anteil der Flächennutzung an der Gemeindefläche in %" />

        {areaUsage.data[0] && (
          <div
            className={clsx(
              print
                ? `visualization__area-usage-tick
              visualization__area-usage-tick--municipality-print`
                : `visualization__area-usage-tick
              visualization__area-usage-tick--municipality`
            )}>
            {areaUsage.data[0].name}
          </div>
        )}

        {areaUsage.data[1] && (
          <div
            className={clsx(
              print
                ? `visualization__area-usage-tick
            visualization__area-usage-tick--compare-print`
                : `visualization__area-usage-tick
            visualization__area-usage-tick--compare`
            )}>
            {areaUsage.data[1].name}
          </div>
        )}

        {hoveredValue && renderHint(hoveredValue)}
      </XYPlot>

      {!print && (
        <p className={clsx(styles.note, styles.hideOnMobile)}>
          Wenn Sie mit der Maus über das Diagramm fahren, werden Ihnen die
          Absolut- und Prozentwerte angezeigt.
        </p>
      )}

      <AreaUsageTable
        print={print}
        municipalityName={areaUsage.data[0].name}
        municipalityData={data}
        allToCompareName={areaUsage.data[1].name}
      />
    </div>
  );
};

export default sizeme()(AreaUsage);

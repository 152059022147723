import React, {useEffect, useState} from 'react';

import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector'
import {Tile as TileLayer, Layer} from 'ol/layer';

// import WmsMapType from '../../libs/wms-map-type';

interface IProps {
  mapCanvas: ol.Map | null;
  show: boolean;
}

const DensityOverlay = ({
  mapCanvas,
  show
}: IProps): JSX.Element | null => {
  const [censusOverlay, setCensusOverlay] = useState<VectorLayer>(null); //todo: do I need a state here?

  useEffect(
    () => {
      if (!mapCanvas || (show && censusOverlay)) {
        return;
      }

      if (censusOverlay) {
        mapCanvas.removeLayer(censusOverlay)
        setCensusOverlay(null);
      }

      if (!show) {
        return;
      }


      const ew_zensus_source = new TileWMS({
          url: 'https://projektcheck.gs.ils-geomonitoring.de/projektcheck/wms?', 
          params: {'LAYERS': 'projektcheck:ew_zensus',  'TILED': true}
      });
      const ew_zensus_layer = new TileLayer({
          source: ew_zensus_source,
          maxZoom: 20,
          crossOrigin: 'anonymous',
          title: 'Einwohner Zensus 2011 (WMS)',
          type: 'data'
      })
      ew_zensus_layer.setOpacity(0.8);

      mapCanvas.addLayer(ew_zensus_layer);
      setCensusOverlay(ew_zensus_layer);
    },
    [mapCanvas, show]
  )

  return (null);
} 

export default DensityOverlay;

import React from 'react';
import {IAreaConfig, areaConfigsById} from '../../../constants/area-config';
import {minMax} from '../../../libs/math-functions';
import {connect} from 'react-redux';
import {areaConfigSelector} from '../../../selectors/area';
import {IStore} from '../../../interfaces/store';

interface IProps {
  efhSize: number;
  onEfhSizeChange: (startYear: number) => void;
  mfhSize: number;
  onMfhSizeChange: (startYear: number) => void;
  efhUnderAgeProportion: number;
  onEfhUnderAgeChange: (startYear: number) => void;
  mfhUnderAgeProportion: number;
  onMfhUnderAgeChange: (startYear: number) => void;
}

interface IStoreProps {
  areaConfig: IAreaConfig | null;
}

const AssumptionPopulation = ({
  efhSize,
  onEfhSizeChange,
  mfhSize,
  onMfhSizeChange,
  efhUnderAgeProportion,
  onEfhUnderAgeChange,
  mfhUnderAgeProportion,
  onMfhUnderAgeChange,
  areaConfig
}: IProps & IStoreProps): JSX.Element | null => {
  if (!areaConfig) {
    return null;
  }
  const areaDefaults = areaConfigsById[areaConfig.id];

  const sizeMinMax = minMax(0, 129);
  const underAgeMinMax = minMax(0, 60);

  const onEfhSizeInuputChange = (size: number): void => {
    const newEfhSize = sizeMinMax(size);
    onEfhSizeChange(newEfhSize);
  };
  const onMfhSizeInuputChange = (size: number): void => {
    const newMfhSize = sizeMinMax(size);
    onMfhSizeChange(newMfhSize);
  };

  const onEfhUnderAgeInuputChange = (underAge: number): void => {
    const newEfhUnderAge = underAgeMinMax(underAge) / 100;
    onEfhUnderAgeChange(newEfhUnderAge);
  };
  const onMfhUnderAgeInuputChange = (underAge: number): void => {
    const newMfhUnderAge = underAgeMinMax(underAge) / 100;
    onMfhUnderAgeChange(newMfhUnderAge);
  };

  return (
    <div>
      <p className="summary">
        Nachstehend sehen Sie die Annahmen von Projekt-Check zur mittleren
        Haushaltsgröße und zum Anteil an Personen unter 18 Jahren. Diese
        Annahmen können Sie in der rechten Spalte ändern. Alle Werte beziehen
        sich auf die durchschnittliche Bewohnerstruktur im dritten Jahr nach dem
        Erstbezug.
      </p>
      <p className="assumption-intro">Mittlere Haushaltsgröße</p>
      <table>
        <thead>
          <tr>
            <th />
            <th>Annahme Projekt-Check</th>
            <th>Ihre Annahme</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>in Ein- und Zweifamilien- bzw. Reihenhäusern</td>
            <td>{areaDefaults && areaDefaults.efhSize.toLocaleString('de')}</td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="1"
                max="6"
                step=".01"
                value={efhSize}
                onChange={({target}): void =>
                  onEfhSizeInuputChange(Number(target.value))
                }
              />
            </td>
          </tr>
          <tr>
            <td>in Mehrfamilienhäusern</td>
            <td>{areaDefaults && areaDefaults.mfhSize.toLocaleString('de')}</td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="1"
                max="6"
                step=".01"
                value={mfhSize}
                onChange={({target}): void =>
                  onMfhSizeInuputChange(Number(target.value))
                }
              />
            </td>
          </tr>
        </tbody>
      </table>

      <p className="assumption-intro">Anteil Kinder (Ew. &lt; 18 Jahre)</p>
      <table>
        <thead>
          <tr>
            <th />
            <th>Annahme Projekt-Check</th>
            <th>Ihre Annahme</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>in Ein- und Zweifamilien- bzw. Reihenhäusern (in Prozent)</td>
            <td>{areaDefaults && areaDefaults.efhUnderAgeProportion * 100} </td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="0"
                max="60"
                value={Math.round(efhUnderAgeProportion * 100)}
                onChange={({target}): void =>
                  onEfhUnderAgeInuputChange(Number(target.value))
                }
              />
            </td>
          </tr>
          <tr>
            <td>in Mehrfamilienhäusern (in Prozent)</td>
            <td>{areaDefaults && areaDefaults.mfhUnderAgeProportion * 100} </td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="0"
                max="60"
                value={Math.round(mfhUnderAgeProportion * 100)}
                onChange={({target}): void =>
                  onMfhUnderAgeInuputChange(Number(target.value))
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: IStore): IStoreProps => ({
  areaConfig: areaConfigSelector(state)
});

export default connect(mapStateToProps)(AssumptionPopulation);

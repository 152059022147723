import {combineReducers} from 'redux';

import ecologyLayers from './ecology-layers';
import followupCostsData from './followup-costs-data';
import isochrones from './isochrones';
import municipalities from './municipalities';
import municipalityData from './municipality-data';
import pois from './pois';
import populationDevelopment from './population-development';

export default combineReducers({
  ecologyLayers,
  followupCostsData,
  isochrones,
  municipalities,
  municipalityData,
  pois,
  populationDevelopment
});

import React from 'react';

import {IMunicipalityData} from '../../interfaces/municipality-data';

import {useSelector} from 'react-redux';
import {municipalitySelector} from '../../selectors/municipality';

import RecentDevelopmentScreen from './recent-development-screen';

export interface IRecentDevelopmentProps {
  title: string;
  intro: string;
  sectionTitles: {
    inhabitants: string;
    employed: string;
    areaUsage: string;
  };
  data: IMunicipalityData | null;
}

const RecentDevelopmentContainer = (): JSX.Element => {
  const municipality = useSelector(municipalitySelector);
  const data = municipality?.data || null;

  const displayProps = {
    title: `Bisherige Entwicklung in ${municipality?.name || ''}`,
    intro:
      'Daten gemäß Regionalstatistik der Statistischen Ämter des Bundes und der Länder.',
    sectionTitles: {
      inhabitants: 'Einwohner/innen',
      employed: 'Beschäftigte',
      areaUsage: 'Flächennutzung'
    },
    municipality,
    data
  };
  return <RecentDevelopmentScreen {...displayProps} />;
};

export default RecentDevelopmentContainer;

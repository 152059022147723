import {IAction} from '../interfaces/action';
import {IStore} from '../interfaces/store';

import {
  FETCH_ISOCHRONES,
  FETCH_ISOCHRONES_SUCCESS,
  FETCH_ISOCHRONES_ERROR
} from '../constants/actions';
import fetchApi from '../apis/fetch-isochrones';

/**
 * Fetching the isochrones
 */
export function fetchIsochrones(): IAction {
  return {
    type: FETCH_ISOCHRONES
  };
}

/**
 * Success while fetching the isochrones
 */
export function fetchIsochronesSuccess(
  isochrones: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>
): IAction {
  return {
    type: FETCH_ISOCHRONES_SUCCESS,
    isochrones
  };
}

/**
 * Error while fetching the isochrones
 */
export function fetchIsochronesError(error: Error): IAction {
  return {
    type: FETCH_ISOCHRONES_ERROR,
    error
  };
}

/**
 * Fetch the reachability isochrones
 */
export default function(
  fetchRemotely: (
    center: ol.coordinate
  ) => Promise<GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>> = fetchApi
) {
  return (dispatch, getState: () => IStore) => {
    const state: IStore = getState();

    if (state.isochrones) {
      return null;
    }

    dispatch(fetchIsochrones());

    const center = state.area.center;

    if (!center) {
      return null;
    }

    return fetchRemotely(center)
      .then(isochrones => dispatch(fetchIsochronesSuccess(isochrones)))
      .catch(error => dispatch(fetchIsochronesError(error)));
  };
}

import {IFollowupCostsData} from '../interfaces/followup-costs-data';

/**
 * Load the followup costs JSON and return those with a Promise
 */
export default function(): Promise<IFollowupCostsData> {
  return new Promise((resolve, reject) => {
    const url = 'data/followup-costs.json';

    fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(error => reject(error));
  });
}

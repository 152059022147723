import { IAction } from '../../interfaces/action';
import { SET_AREA_CONFIG, UPDATE_AREA_CONFIG } from '../../constants/actions';
import { IAreaConfig } from '../../constants/area-config';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle whether to show the print view or not
 */
export default function (
  state: IAreaConfig | null = initialState,
  action: IAction
): IAreaConfig | Partial<IAreaConfig> | null {
  switch (action.type) {
    case SET_AREA_CONFIG:
      return { ...action.areaConfig, startYear: new Date().getFullYear() };
    case UPDATE_AREA_CONFIG:
      return { ...state, ...action.areaConfig };
    default:
      return state;
  }
}

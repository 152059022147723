import lineSegment from '@turf/line-segment';
import GeoJSON from 'ol/format/GeoJSON';
/**
 * Load a route from origin to destination
 */
export default function(
  origin: ol.coordinate,
  destination: ol.coordinate
): Promise<GeoJSON> {
  
  
  const orsresult = new Promise((resolve, reject) => {
      const url = 'https://ors.germany.ils-geomonitoring.de/v2/directions/driving-car/geojson' //?api_key=5b3ce3597851110001cf6248e710847c5f024e018137c80f1ee4e831&start=' + 
            // origin[0] +','+ origin[1] + '&end=' + destination[0] +','+ destination[1];
      const data = { "coordinates": [origin, destination] };
      return fetch(
        url, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
            //'Authorization': "5b3ce3597851110001cf6248e710847c5f024e018137c80f1ee4e831", //only if using openrouteservice.org!!!
          },
          body: JSON.stringify(data),
          mode: 'cors',
        }
      )
      .then(response => response.json() )
      .then(json => {
          if (json.error) {
            throw json.error
          }
          const geojson = new GeoJSON();
          const resultLine = lineSegment(json);
          const polyline: any = geojson.readFeatures(
              resultLine,
              {
                  featureProjection: 'EPSG:3857',
                  dataProjection: 'EPSG:4326',
              }
          );

          // polyline.features[0].geometry.coordinates.map(point => [point[1],point[0]])
          resolve(polyline)
      })
      .catch((error) => {console.log(error)});
  });
  
  return orsresult;

/*

*/

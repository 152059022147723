import React from 'react';
import CloseButton from './information-close-button';
import styles from './information.styl';

const Information = ({
  title,
  content
}: {
  title?: string;
  content: string | JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Info: {title}</h1>
      </header>
      <div className={styles.contentContainer}>
        {content}
      </div>
      <CloseButton />
    </div>
  );
};

export default Information;

import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import fetchMunicipalities from '../actions/municipalities/fetch-municipalities';
import reducers from './index';

// import { useMatomo } from '@jonkoops/matomo-tracker-react';

import {
  SELECT_VIEW,
  DOWNLOAD,
  IMPORT_SETTINGS,
  START_ANALYSE
} from '../constants/actions';
import {ViewTitles} from '../constants/views';

/**
 * Intercepts actions and tracks them for g*ogle analytics
 */

const trackingMiddleware = () => next => (action): void => {
  
//   if ([DOWNLOAD, IMPORT_SETTINGS, START_ANALYSE].includes(action.type)) {
// //     const { trackEvent } = useMatomo();
// //     trackEvent({"action": action.type, "category": "ga_replacement", "name": "ga_replacement"});
// //     ga('send', 'event', 'interaction', action.type);
//   }

//   if ([SELECT_VIEW].includes(action.type)) {
// //     const { trackPageView } = useMatomo();
// //     trackPageView({"href":"webcheck.ils-geomonitoring.de"});
// //     ga('send', 'pageview', ViewTitles.get(action.viewId));
//   }

  return next(action);
};

const middlewares = [thunk, trackingMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
    timestamp: false
  });

  // @ts-ignore
  middlewares.push(logger);
}

const store = createStore(
  reducers,

  applyMiddleware(...middlewares)
);

// @ts-ignore
store.dispatch(fetchMunicipalities());

export default store;

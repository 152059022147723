import React, {MouseEvent, useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import Button from '../shared/button/button';
import Icon from '../shared/icon';
import Inhabitants from './inhabitants';
import Employed from './employed';
import AreaUsage from './area-usage';

import {IRecentDevelopmentProps} from './recent-development-container';

import styles from './recent-development.styl';

const RecentDevelopmentScreen = ({
  title,
  sectionTitles,
  data
}: IRecentDevelopmentProps): JSX.Element => {
  const navigate = useNavigate();

  const closeOverlay = (event: MouseEvent): void => {
    event.preventDefault();
    navigate('/config/municipality');
  };

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div>
          <h2>{title}</h2>
        </div>
        <Button
          title="Schließen"
          variant="icon"
          onClick={(event): void => closeOverlay(event)}>
          <Icon type="close" />
        </Button>
      </header>
      <div className={styles.tabContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_, newValue): void => {
            setSelectedTab(newValue);
          }}>
          <Tab disableRipple label={sectionTitles.inhabitants} />
          <Tab disableRipple label={sectionTitles.employed} />
          <Tab disableRipple label={sectionTitles.areaUsage} />
        </Tabs>
      </div>
      <div className={styles.contentContainer}>
        {selectedTab === 0 && <Inhabitants data={data} />}
        {selectedTab === 1 && <Employed data={data} />}
        {selectedTab === 2 && <AreaUsage data={data} />}
      </div>
    </section>
  );
};

export default RecentDevelopmentScreen;

import React, {useEffect} from 'react';
import {Route, Routes, useNavigate, useParams, useMatch, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

import SidebarHeader from '../sidebar-header/sidebar-header';
import SidebarFooter from '../sidebar-footer/sidebar-footer';
import Instruction from '../instruction/instruction';
import Stepper from '../sidebar-footer/stepper/stepper';
import ConfigurationTitle from './configuration-title/configuration-title';
import ConfigurationMunicipality from './configuration-municipality/configuration-municipality';
import ConfigurationDetails from './configuration-details/configuration-details';
import ConfigurationUsage from './configuration-usage/configuration-usage';

// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';

import Map from '../map/map';

import configurationStepNumber from '../../selectors/configuration-step-number';

import styles from './configuration.styl';

import {steps} from '../../config/steps';

import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Configuration = ({hide = false}: {hide?: boolean}): JSX.Element => {
  const {configurationStep} = useParams();
  const currentStepNumber = useSelector(configurationStepNumber);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });

  const { trackPageView, trackEvent } = useMatomo();
  
  useEffect(() => {
    if (
      !configurationStep ||
      steps[configurationStep].number > currentStepNumber
    ) {
      navigate('/welcome');
    } else if (steps[configurationStep].number === steps.done.number) {
      navigate('/views');
    }
  }, [configurationStep]);

  return (
    <aside className={clsx(styles.container, hide && styles.noDisplay)}>
      <div>
        <SidebarHeader title="Neue Planung" />
        <ConfigurationTitle />
      </div>
      {isDesktop===false && <Map hide={configurationStep !== steps.area.id} />}
      {useMatch('/config/municipality') && <ConfigurationMunicipality /> }
      {useMatch('/config/area') && <ConfigurationMunicipality /> }
      {useMatch('/config/details') && <ConfigurationDetails /> }
      {useMatch('/config/usage') && <ConfigurationUsage /> }
      <div>
        <Instruction isDesktop={isDesktop} />
        <SidebarFooter>
          <Stepper
            stepOnBlocked={
              !configurationStep ||
              currentStepNumber <= steps[configurationStep].number
            }
          />
        </SidebarFooter>
      </div>
    </aside>
  );
};

export default Configuration;

import {IAction} from '../../interfaces/action';
import {AreaConfigIds} from '../../constants/area-config'; // eslint-disable-line no-unused-vars, max-len
import {SELECT_AREA_DENSITY_ID} from '../../constants/actions';

/**
 * Select an area density id
 */
export default function(areaDensityId: AreaConfigIds): IAction {
  return {
    type: SELECT_AREA_DENSITY_ID,
    areaDensityId
  };
}

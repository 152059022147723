import React from 'react';
import clsx from 'clsx';
import styles from './loading.styl';

/**
 * Renders a loading animation component
 */
const Loading = (): JSX.Element => {
  return (
    <div className={styles.loading}>
      <div className={clsx(styles.bubble, styles.one)} />
      <div className={clsx(styles.bubble, styles.two)} />
      <div className={clsx(styles.bubble, styles.three)} />
    </div>
  );
};

export default Loading;

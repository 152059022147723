import {combineReducers} from 'redux';

import area from './area/area';
import areaConfig from './area/area-config';
import development from './development/development';
import ecologyLayers from './ecology/ecology-layers';
import ecologyLayersInformation from './ecology/ecology-layer-information';
import followupCostsData from './followup-costs-data';
import isLoading from './is-loading/is-loading';
import isochrones from './isochrones';
import map from './map/map';
import municipalities from './municipalities/municipalities';
import municipality from './municipalities/municipality';
import pois from './pois';
import showInformation from './show-information';
import traffic from './traffic/traffic';
import view from './view';
import showLegend from './show-legend';
import {IMPORT_SETTINGS} from '../constants/actions';

const appReducer = combineReducers({
  area,
  areaConfig,
  development,
  ecologyLayers,
  ecologyLayersInformation,
  followupCostsData,
  isLoading,
  isochrones,
  map,
  municipalities,
  municipality,
  pois,
  showInformation,
  traffic,
  view,
  showLegend
});

export default (state, action) => {
  if (action.type === IMPORT_SETTINGS) {
    state = {...state, ...action.settings};
  }

  return appReducer(state, action);
};

import {IAction} from '../../interfaces/action';
import {IRoute} from '../../interfaces/routes';
import {ADD_TRAFFIC_ROUTE, CLEAR_TRAFFIC_ROUTES, REMOVE_TRAFFIC_ROUTE} from '../../constants/actions';

/**
 * Initial state
 */
const initialState: IRoute[] = [];

/**
 * Handle the current traffic reachability routes
 */
export default function(
  state: IRoute[] = initialState,
  action: IAction
): IRoute[] {
  switch (action.type) {
    case ADD_TRAFFIC_ROUTE:
      if (!action.routes.route) {
        return state;
      }
      return [...state, action.routes.route];
    
    case REMOVE_TRAFFIC_ROUTE:
      if (!action.routes || !action.route) {
        return state
      }
      const newRoutes = JSON.parse(JSON.stringify(state));
      
      return newRoutes.filter(el => !(
        el.destination[0]==action.route.destination[0] &&
        el.destination[1]==action.route.destination[1]
      ))
      // return state; //odd...
      // return newRoutes; //odd...
      // return action.routes;
    
    case CLEAR_TRAFFIC_ROUTES:
      return initialState;

    default:
      return state;
  }
}

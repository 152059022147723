import React, {useEffect, useRef, useState} from 'react';

//OpenLayers
import * as ol from 'ol'; //todo: import only submodules (and types!)
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import {inView} from 'ol/layer/Layer.js';
import {OSM, TileWMS, Vector as VectorSource} from 'ol/source';
import {GeoJSON} from 'ol/format';
import {Attribution} from 'ol/control';
import {Stroke, Style} from 'ol/style';
import {intersects} from 'ol/extent';
import {toLonLat, transformExtent, get} from 'ol/proj';

//ol-extensions and related
import { applyStyle as applyMapboxStyleToLayer } from "ol-mapbox-style";
import Crop from 'ol-ext/filter/Crop';
// import 'ol-layerswitcher/dist/ol-layerswitcher.css';
// import './map.css';
// import LayerSwitcher from 'ol-layerswitcher';
// import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';

//other libraries
import union from '@turf/union';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import { useMediaQuery } from '@mui/material';

//WebCheck
import {
  BaselayerDOP,
  BaselayerBG,
  BaselayerVG,
  BGIds,
  DOPIds,
  VGIds,
  DOPTitles,
  SATELLITE,
  HYBRID,
  ROADMAP
} from '../../constants/baselayer';
// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';
import styles from './map.styl';

interface IProps {
  bounds: ol.extent;
  center: ol.coordinate;
  zoom: number;
  printVersion?: boolean;
  onInit: (canvas: ol.Map) => void;
  onCenterChange?: (center: ol.coordinate) => void;
  onZoomChange?: (zoom: number) => void;
  hide?: boolean;
  layerset: string | null;
  setLayerset: Function | null;
}

const Canvas = ({
  bounds,
  center,
  zoom,
  printVersion,
  onInit,
  onCenterChange,
  onZoomChange,
  hide,
  layerset,
  setLayerset
}: IProps): JSX.Element => {
  const {trackPageView, trackEvent} = useMatomo();
  /**
   * The container element to render the map canvas into
   */
  const canvas = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<ol.Map | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number | null>(null);
  const [currentBounds, setCurrentBounds] = useState<ol.extent | null>(null);
  const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });

  const [sentLayer, setSentLayer] = useState<ol.Layer | null>(null);
  const [bgLayer, setBgLayer] = useState<ol.Layer | null>(null);
  const [dopLayer, setDopLayer] = useState<ol.Layer | null>(null);
  //     const [lands, setLands] = useState<Array | null>(null);
  // const [landBoundarys, setLandBoundarys] = useState<ol.Layer | null>(null);
  // const [boundarySelection, setBoundarySelection] = useState<Array | null>(null);

  const isArray = (obj) => {
    return !!obj && obj.constructor === Array;
  };

  const fitForfit = (boundarys: Array) => {
    if (!isArray(boundarys)) {
      return transformExtent(
        [boundarys['west'], boundarys['south'], boundarys['east'], boundarys['north']],
        get('EPSG:4326'),
        get('EPSG:3857')
      );
    } else if (isArray(boundarys)) {
      return transformExtent(boundarys, get('EPSG:4326'), get('EPSG:3857'));
    }
  };
  const mapOptions = {
    view: new ol.View({zoom, center, projection: 'EPSG:3857'}),
    layers: [],
    controls: []
    //         overlays: []
  };

  const attributionsExtentFilter = (frameState) {

    const layersCroppedIntersecting = frameState.layerStatesArray.filter((lyrState) => {
      return (
        frameState.viewState.zoom>=12 &&
        lyrState.layer.getFilters().length!=0 &&
        inView(lyrState, frameState.viewState) && 
        intersects(lyrState.layer.getExtent(), frameState.extent)
      )
    }).map(
      (lyrState) => {return lyrState.layer}
    );

    let visibleAttributions = [];
    for (let lyr of layersCroppedIntersecting) {
      Array.from(BaselayerDOP).filter(
        (obj) => {
          if (obj[1].shortName==lyr.get('title')) { return true; }
        }
      ).map(
        (obj) => {return obj[0]}
      ).map(
        (dopId) => {
          return BaselayerDOP.get(
            dopId
          ).copyright ?? '';
        }
      ).forEach(
        (copyright) => {
          visibleAttributions.push(
            copyright
          );
        }
      );
    }
    return visibleAttributions;
  }
  /**
   * Initialize map and listeners.
   */
  useEffect(() => {
    // @ts-ignore The canvas is available after first render
    // before this effect is run

    const mapObject = new ol.Map(mapOptions);
    mapObject.setTarget(canvas.current);
    //  öö       mapObject.addControl(new LayerSwitcher());
    //         const bglayer = new OSM();

    const attribution = new Attribution({
      collapsible: false
    });

    mapObject.addControl(attribution);

    // const layerSwitcher = new LayerSwitcher({
    //   reverse: true,
    //   groupSelectStyle: 'group',
    //   startActive: true,
    //   activationMode: 'click'
    // });
    // mapObject.addControl(layerSwitcher);

    mapInstance.current = mapObject;

    /*
        TopPlusOpen or OSM
        */
    const base = BGIds.TopPlusOpenColor;
    if (BaselayerBG.get(base).serviceType === 'wms') {
      const bglayersource = new TileWMS({
        url: BaselayerBG.get(base).data.url,
        params: {'LAYERS': BaselayerBG.get(base).data.layers[0].id},
        attributions: BaselayerBG.get(base).copyright
        // attributions: attributionsExtentFilter,
      });
    } else if (BaselayerBG.get(base).serviceType === 'osm') {
      const bglayersource = new OSM({
        params: {'LAYERS': BaselayerBG.get(base).data.layers[0].id},
        attributions: BaselayerBG.get(base).copyright
      });
      bglayersource.setUrls(BaselayerBG.get(base).data.url);
    }
    const localBgLayer = new TileLayer({
      source: bglayersource,
      title: BaselayerBG.get(base).shortName,
      type: 'base',
      crossOrigin: 'anonymous',
      visible: true
    });
    localBgLayer.getSource().addEventListener('tileloadend', () => {
      onInit(mapInstance.current);
    });
    mapInstance.current.getLayers().insertAt(0, localBgLayer);
    // setBgLayer(localBgLayer);

    const sent2layer = new TileLayer({
      source: new TileWMS({
        url: BaselayerDOP.get(DOPIds.Sentinel2).data.url, //protecte
        params: {'LAYERS': BaselayerDOP.get(DOPIds.Sentinel2).data.layers[0].id},
        attributions: BaselayerDOP.get(DOPIds.Sentinel2).copyright
      }),
      title: BaselayerDOP.get(DOPIds.Sentinel2).shortName,
      type: 'base',
      crossOrigin: 'anonymous',
      visible: false
    });

    mapInstance.current.getLayers().insertAt(0, sent2layer);

    setSentLayer(sent2layer);

    const basemapVectorLayer = new VectorTileLayer({
      declutter: true
    })
    applyMapboxStyleToLayer(
      basemapVectorLayer,
      "https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_top.json"
    );
    mapInstance.current.getLayers().insertAt(0, basemapVectorLayer);
    setBgLayer(basemapVectorLayer);
    

    return (): void => {
      localBgLayer.getSource().removeEventListener('tileloadend');
      mapObject.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    if (mapInstance.current && bgLayer && sentLayer) {
      const geoJSONFormat = new GeoJSON();
      
      if (!dopLayer) {

        const _dissolve = (cropfeatures) => {
          for (var feat of cropfeatures) {
            const turfPoly = geoJSONFormat.writeFeatureObject(feat);
            let result;
            if (!result) {
              result = turfPoly;
            } else {
              result = union(result, turfPoly);
            }
          }
          return geoJSONFormat.readFeature(result);
        }

        if (layerset && (layerset === HYBRID || layerset === SATELLITE)) {
          bgLayer.setVisible(false);
          sentLayer.setVisible(true);
          trackEvent({category: 'nonessential', action: 'load DOPs'});
        }

        // BUT GET SOURCES FROM CONSTANTS!
        //Check this: https://github.com/Viglino/ol-ext/blob/master/examples/filter/map.filter.crop.html
        var landGeoJSONRequest = new XMLHttpRequest();
        landGeoJSONRequest.overrideMimeType('application/json');
        const wfsUrl =
          // eslint-disable-next-line prefer-template
          BaselayerVG.get(VGIds.vg250_land).data.url +
          '?service=WFS&version=1.1.0&request=GetFeature&typename=' +
          BaselayerVG.get(VGIds.vg250_land).data.layers[0].name +
          '&outputFormat=application/json&srsName=EPSG:3857&MAXFEATURES=10000';
        landGeoJSONRequest.open('GET', wfsUrl, true);
        landGeoJSONRequest.onreadystatechange = function () {
          if (landGeoJSONRequest.readyState === 4 && landGeoJSONRequest.status == '200') {

            const feats = geoJSONFormat.readFeatures(landGeoJSONRequest.responseText);

            const doplayers = [];
            const sentinellands = [];
            const directlylands = [];
            for (let _id in DOPIds) {
              let landname = DOPTitles.get(parseInt(_id));
              const cropfeatures = feats.filter(feat => feat.get('gen').match(new RegExp(landname + '.*')));
              if (cropfeatures.length > 0) {
                const currentDOPObject = BaselayerDOP.get(parseInt(_id));
                if (currentDOPObject.data.url != '') {
                  var result = undefined;

                  const cropfeature = _dissolve(cropfeatures);
                  directlylands.push(cropfeature);
                  const extent = cropfeature.getGeometry().getExtent();

                  const cropper = new Crop({
                    feature: cropfeature,
                    inner: false
                  });

                  if (currentDOPObject.data.special) {
                    const key = currentDOPObject.data.special.split('=')[0];
                    const value = currentDOPObject.data.special.split('=')[1];
                  } else {
                    const key = undefined;
                    const value = undefined;
                  }
                  const dopwms = new TileLayer({
                    source: new TileWMS({
                      url: currentDOPObject.data.url, //protected (no proxy)
                      params: {
                        LAYERS: currentDOPObject.data.layers[0].id,
                        VERSION: currentDOPObject.data.version ? currentDOPObject.data.version : '1.3.0',
                        [key]: value
                      },
                      attributions: attributionsExtentFilter
                    }),
                    title: currentDOPObject.shortName,
                    type: 'base',
                    extent: extent, //why is this not filtering on attributions???
                    crossOrigin: 'anonymous'
                  });
                  dopwms.addFilter(cropper);

                  doplayers.push(dopwms);
                } else if (Array.isArray(cropfeatures)) {
                    cropfeatures.forEach(feat => sentinellands.push(feat));
                  }
              }
            }

            const landLayerSentinel = new VectorLayer({
              source: new VectorSource({
                features: sentinellands
              }),
              crossOrigin: 'anonymous',
              style: new Style({
                stroke: new Stroke({
                  color: 'rgba(255, 0, 0, 0.0)',
                  width: 2
                })
              }),
              visible: true,
              title: 'Länder ohne freie DOP-Quelle - fallback: Sentinel2',
            });
            mapInstance.current.addLayer(landLayerSentinel);

            const landLayer = new VectorLayer({
              source: new VectorSource({
                features: directlylands
              }),
              crossOrigin: 'anonymous',
              style: new Style({
                stroke: new Stroke({
                  color: 'rgba(0, 0, 255, 0.0)',
                  width: 1
                })
              }),
              visible: true,
              title: 'Länder mit freier DOP-Quelle',
            });
            mapInstance.current.addLayer(landLayer);

            //                         sentLayer.addFilter(
            //                             new Crop({
            //                                 feature: dissolve(sentinellands),
            //                                 inner: false
            //                             })
            //                         ); // todo: sadly crops all Layers. Why?

            const localDopLayer = new LayerGroup({
              layers: doplayers,
              visible: false,
              minZoom: 12
            });

            if (mapInstance.current.getLayers().insertAt(1, localDopLayer));
            setDopLayer(localDopLayer);
          }
        };
        landGeoJSONRequest.send(null);
      }
      if (dopLayer) {
        if (layerset && dopLayer) {
          const layers = mapInstance.current.getLayers();
          if (layerset === ROADMAP) {
            layers
              .getArray()
              .filter(lay => lay.ol_uid == dopLayer.ol_uid)[0]
              .setVisible(false);
            layers
              .getArray()
              .filter(lay => lay.ol_uid == sentLayer.ol_uid)[0]
              .setVisible(false);
            layers
              .getArray()
              .filter(lay => lay.ol_uid == bgLayer.ol_uid)[0]
              .setVisible(true);
          } else if (layerset == SATELLITE || layerset == HYBRID) {
            layers
              .getArray()
              .filter(lay => lay.ol_uid == dopLayer.ol_uid)[0]
              .setVisible(true);
            layers
              .getArray()
              .filter(lay => lay.ol_uid == sentLayer.ol_uid)[0]
              .setVisible(true);
            layers
              .getArray()
              .filter(lay => lay.ol_uid == bgLayer.ol_uid)[0]
              .setVisible(false);
          }
        }
      }
    }
  }, [layerset, dopLayer]); //triggered on Backgound-Map-Change-Button (and once againt after dopLayer has been created)

  useEffect(() => {
    if (mapInstance.current && isDesktop) {
      // On desktop, set bounds immediately
      mapInstance.current.getView().fit(fitForfit(bounds), mapInstance.current.getSize());
      setCurrentBounds(bounds);
    }
  }, [bounds, mapInstance.current]);

  useEffect(() => {
    if (mapInstance.current && !isDesktop) {
      // If zoom has changed from default, apply it
      if (currentZoom && bounds === currentBounds) {
        mapInstance.current.setZoom(currentZoom);
      } else if (!hide) {
        mapInstance.current.getView().fit(fitForfit(bounds), mapInstance.current.getSize());
        setCurrentBounds(bounds);
      }
    }
  }, [hide, bounds, mapInstance.current]);

  return <div className={styles.canvas} ref={canvas}></div>;
};

export default Canvas;
/*
popup-helper
*/
//         var container = document.getElementById('popup');
//         var overlay = new Overlay({
//             element: container,
//             autoPan: true,
//             autoPanAnimation: {
//               duration: 250,
//             },
//         });
//         var closer = document.getElementById('popup-closer');
//         closer.onclick = function () {
//             overlay.setPosition(undefined);
//             closer.blur();
//             return false;
//         };
//         var content = document.getElementById('popup-content');
//         mapObject.on('singleclick', function (evt) {
//             var coordinate = evt.coordinate;
//             var hdms = toStringXY(toLonLat(coordinate),3);
//
//             content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
//             overlay.setPosition(coordinate);
//         });
//         mapObject.addOverlay(overlay)
        

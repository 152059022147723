import React from 'react';

import styles from './legend.styl';
import clsx from 'clsx';

interface IProps {
  readonly entries: {
    color: string;
    description: string;
  }[];
  className?: string;
}

/**
 * A legend
 */
const Legend = ({entries, className}: IProps): JSX.Element => {
  const containerClass = clsx(styles.legend, className);
  return (
    <dl className={containerClass}>
      {entries.map(
        (entry): JSX.Element => (
          <span key={entry.description} className={styles.legendEntry}>
            <dt className={styles.legendColor}>
              <svg
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <rect fill={entry.color} x="0" y="0" width="14" height="14" />
              </svg>
            </dt>
            <dd className={styles.legendDescription}>{entry.description}</dd>
          </span>
        )
      )}
    </dl>
  );
};

export default Legend;

import {AreaTypeIds} from '../constants/area-types';
import {
  ReferenceValues,
  FollowupCostsPhases
} from '../constants/followup-costs';
import {
  NetworkElementGroupIds,
  networkElementGroups
} from '../constants/network-elements';

import {IFollowupCosts} from '../interfaces/followup-costs';
import {IStore} from '../interfaces/store';

/**
 * Get the followup costs based on the store
 */
export const selectFollowUpCosts = function(
  state: IStore
): Map<NetworkElementGroupIds, IFollowupCosts> {
  const {area, municipality, followupCostsData} = state;

  if (
    area.typeId === AreaTypeIds.Retail ||
    !area.densityId ||
    !area.size ||
    !municipality ||
    !municipality.data ||
    !followupCostsData.referenceValues
  ) {
    return new Map([
      [
        1,
        new Map([
          [1, 0],
          [2, 0]
        ])
      ],
      [
        2,
        new Map([
          [1, 0],
          [2, 0]
        ])
      ],
      [
        3,
        new Map([
          [1, 0],
          [2, 0]
        ])
      ],
      [
        4,
        new Map([
          [1, 0],
          [2, 0]
        ])
      ]
    ]);
  }

  const densityReferences = ReferenceValues[area.densityId];
  const areaSize: number = area.size;
  const regionalFactor: number = municipality.data.followupCosts.regionalFactor;
  const data = new Map<NetworkElementGroupIds, IFollowupCosts>();

  networkElementGroups.forEach(
    (networkElementIds, networkElementGroupId): void => {
      const costs = new Map<FollowupCostsPhases, number>();
      let totalInitialCosts = 0,
        totalMaintenanceCosts = 0;

      networkElementIds.forEach((networkElementId): void => {
        const densityReference: number = densityReferences[networkElementId];
        const networkMeters: number = areaSize * densityReference;
        const referenceValues =
          followupCostsData.referenceValues[networkElementId];
        const initialCostsPerMeter: number =
          referenceValues[FollowupCostsPhases.Initial] * regionalFactor;
        const maintenanceCostsPerMeter: number =
          referenceValues[FollowupCostsPhases.Maintenance] * regionalFactor;

        totalInitialCosts += networkMeters * initialCostsPerMeter;
        totalMaintenanceCosts += networkMeters * maintenanceCostsPerMeter;
      });

      costs.set(FollowupCostsPhases.Initial, totalInitialCosts);
      costs.set(FollowupCostsPhases.Maintenance, totalMaintenanceCosts);

      data.set(networkElementGroupId, costs);
    }
  );

  return data;
};

export const selectNetworkLengths = (state: IStore): {[_: number]: number} => {
  const {area} = state;

  if (area.typeId === AreaTypeIds.Retail || !area.densityId || !area.size) {
    return {};
  }

  const lengthByMeterByGroup = {};
  const densityReferences = ReferenceValues[area.densityId];

  networkElementGroups.forEach(
    (networkElementIds, networkElementGroupId): void => {
      networkElementIds.forEach((networkElementId): void => {
        const densityReference: number = densityReferences[networkElementId];
        if (!lengthByMeterByGroup[networkElementGroupId]) {
          lengthByMeterByGroup[networkElementGroupId] = 0;
        }
        lengthByMeterByGroup[networkElementGroupId] =
          lengthByMeterByGroup[networkElementGroupId] +
          Math.round(
            // @ts-ignore
            area.size * densityReference
          );
      });
    }
  );

  return lengthByMeterByGroup;
};

export const selectInitialCostByMeterByGroup = (
  state: IStore
): {[_: number]: number} => {
  const costByMeterByGroup = {};
  const followupCosts = selectFollowUpCosts(state);
  const networkLengths = selectNetworkLengths(state);

  networkElementGroups.forEach((networkElementIds, networkElementGroupId):
    | number
    | null => {
    // @ts-ignore
    const initialCost = followupCosts.get(networkElementGroupId).get(1) || 0;
    const networkLength = networkLengths[networkElementGroupId];

    return (costByMeterByGroup[networkElementGroupId] = Math.round(
      initialCost / networkLength
    ));
  });

  return costByMeterByGroup;
};

export const selectMaintenanceCostByMeterByGroup = (
  state: IStore
): {[_: number]: number} => {
  const costByMeterByGroup = {};
  const followupCosts = selectFollowUpCosts(state);
  const networkLengths = selectNetworkLengths(state);

  networkElementGroups.forEach((networkElementIds, networkElementGroupId):
    | number
    | null => {
    const maintenanceCost =
      // @ts-ignore `followupCosts.get(networkElementGroupId)` is not undefined at this point.
      followupCosts.get(networkElementGroupId).get(2) || 0;
    const networkLength = networkLengths[networkElementGroupId];

    return (costByMeterByGroup[networkElementGroupId] = Math.round(
      maintenanceCost / networkLength
    ));
  });

  return costByMeterByGroup;
};

/**
 * The compare value for an area regarding the total costs
 */
export const compareValueSelector = (store: IStore): number | null => {
  const {area, followupCostsData} = store;

  if (
    !area.densityId ||
    !followupCostsData ||
    !followupCostsData.compareValues
  ) {
    return null;
  }

  return followupCostsData.compareValues[area.densityId];
};

export const costsDataByObject = (store: IStore): number | null =>
  store.followupCostsData.byCostsObject;

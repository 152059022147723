import React from 'react';
import {useSelector} from 'react-redux';

import PoisLegend from '../../shared/pois-legend';
import MapLocationCompetition from '../../map/map-location-competition';

import {AreaTypeIds} from '../../../constants/area-types';
import {poiCategories} from '../../../constants/pois';
import {poiCategoryIds} from '../../../constants/location-competition';
import {ViewTitles, ViewIds} from '../../../constants/views';

import {areaConfigSelector} from '../../../selectors/area';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

/**
 * The location competition print view
 */
const LocationCompetition = (): JSX.Element | null => {
  const areaConfig = useSelector(areaConfigSelector);

  if (areaConfig?.areaTypeId !== AreaTypeIds.Retail) {
    return null;
  }

  const selectedPoiCategories = poiCategories.filter(poiCategory =>
    poiCategoryIds.includes(poiCategory.id)
  );

  return (
    <PrintPage title={ViewTitles.get(ViewIds.LocationCompetition)}>
      <PrintParagraph
        aside={
          <>
            <p>
              Standorte bestehender Supermärkte und weiterer Läden im Umfeld des
              Plangebiets
            </p>
            <p>
              Quelle für Standorte der Supermärkte und Läden: Open Street Map
              und Mitwirkende
            </p>
            <p>
              Quelle für die Einwohnerdichte: Statistisches Bundesamt
              (100m-Raster auf Basis des Zensus 2011)
            </p>
          </>
        }
        main={
          <>
            <MapLocationCompetition />

            <PoisLegend
              areaTypeId={areaConfig.areaTypeId}
              className="location-competition__pois-legend"
              poiCategories={selectedPoiCategories}
            />
          </>
        }
      />
    </PrintPage>
  );
};

export default LocationCompetition;

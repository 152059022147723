/**
 * The available area type IDs
 */
export const enum AreaTypeIds {
  Residential = 1,
  Industrial = 2,
  Retail = 3
}

/**
 * An AreaType
 */
export interface IAreaType {
  id: AreaTypeIds;
  name: string;
}

/**
 * The available area types
 */
export const areaTypes: IAreaType[] = [
  {id: AreaTypeIds.Residential, name: 'Wohngebiet'},
  {id: AreaTypeIds.Industrial, name: 'Gewerbegebiet'},
  {id: AreaTypeIds.Retail, name: 'Einzelhandel'}
];

import React from 'react';
import clsx from 'clsx';
import styles from './button.styl';

type VariantTypes = 'regular' | 'link' | 'icon';

type ButtonProps = {
  fullWidth?: boolean;
  marginLeft?: boolean;
  grow?: boolean;
  variant?: VariantTypes;
  linkButton?: boolean;
  white?: boolean;
  withIcon?: boolean;
};

const Button = (
  {
    children,
    className,
    fullWidth,
    marginLeft,
    grow,
    white,
    withIcon,
    variant = 'regular',
    ...props
  }: ButtonProps & React.ComponentProps<'button'>,
  ref
): JSX.Element => {
  return (
    <button
      ref={ref}
      {...props}
      className={clsx(
        styles.container,
        styles[variant],
        className,
        fullWidth && styles.fullWidth,
        marginLeft && styles.marginLeft,
        grow && styles.grow,
        withIcon && styles.withIcon,
        white && styles.white
      )}>
      {children}
    </button>
  );
};

export default React.forwardRef(Button);

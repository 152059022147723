import React from 'react';
import {useSelector} from 'react-redux';

import IsochronesLegend from '../../traffic/isochrones-legend';
import PoisLegend from '../../shared/pois-legend';
import MapReachability from '../map/map-reachability';

import {areaConfigSelector} from '../../../selectors/area';
import styles from './styles.styl';
import appStyles from '../app/styles.styl';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

type IProps = {onInit: () => void};

/**
 * The traffic reachability view
 */
const Reachability = ({onInit}: IProps): JSX.Element | null => {
  const areaConfig = useSelector(areaConfigSelector);

  if (!areaConfig) {
    return null;
  }
  return (
    <PrintPage title="Erreichbarkeit">
      <PrintParagraph
        aside={
          <>
            <p>Bereiche, die innerhalb von 10 Minuten</p>
            <ul>
              <li>zu Fuß</li>
              <li>mit dem Fahrrad</li>
              <li>mit dem Auto</li>
            </ul>
            <p>
              vom Mittelpunkt des Plangebiets aus erreichbar sind sowie
              Einrichtungen und Infrastrukturen im Umfeld des Plangebiets
            </p>
            <p className={appStyles.source}>
              Quelle der Standorte von Infrastrukturen und Einrichtungen: Open
              Street Map und Mitwirkende
              <br />
              <br />
              Isochronen berechnet mit <s>OpenTripPlanner (OTP)</s> OpenRouteService (ORS)
              auf Basis von Straßen und Wegenetzen aus OpenStreetMap
            </p>
          </>
        }
        main={
          <>
            <MapReachability onInit={() => onInit();}/>
            <div className={styles.legend}>
              <IsochronesLegend />
              <PoisLegend
                areaTypeId={areaConfig.areaTypeId}
                className={styles.trafficReachabilityLegendPois}
              />
            </div>
          </>
        }
      />
    </PrintPage>
  );
};

export default Reachability;

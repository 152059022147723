import React from 'react';
import {useNavigate} from 'react-router-dom';
import MenuItemButton from '../menu-item-button/menu-item-button';

import styles from './menu-actions.styl';
const MenuActions = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <MenuItemButton
        className={styles.hideOnMobile}
        onClick={(): void => {
          navigate('/views/export/print');
        }}
        title="PDF drucken"
        isActive={false}
        hasInformation={false}
      />
      <MenuItemButton
        onClick={(): void => {
          navigate('/views/export/download');
        }}
        title="Speichern / Exportieren"
        isActive={false}
        hasInformation={false}
      />
      <MenuItemButton
        onClick={(): void => {
          window.open('https://www.projekt-check.de/impressum', '_blank');
        }}
        title="Impressum"
        isActive={false}
        hasInformation={false}
      />
    </>
  );
};

export default MenuActions;

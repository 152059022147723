import React, {ReactNode} from 'react';

import styles from './styles.styl';

type IProps = {aside?: ReactNode; main: ReactNode};

const PrintParagraph = ({aside, main}: IProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <aside>{aside}</aside> <main>{main}</main>
    </div>
  );
};

export default PrintParagraph;

/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';

interface IProps {
  type: string;
  className?: string;
}

const icons = {
  arrow: (
    <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" />
  ),
  close: (
    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
  ),
  info: (
    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
  ),
  printer: (
    <path d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
  ),
  trash: (
    <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
  ),
  download: <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />,
  upload: <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
};

/**
 * An icon wrapper
 */
export default class Icon extends React.Component<IProps> {
  /**
   * Render the Component
   */
  render(): JSX.Element {
    const icon = icons[this.props.type] || '';

    return (
      <svg
        className={clsx("icon", this.props.className)}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        {icon}
      </svg>
    );
  }
}

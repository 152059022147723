import {IStore} from '../interfaces/store';
import {AreaTypeIds} from '../constants/area-types';

import fetchEcologyLayers from './ecology/fetch-ecology-layers';
import fetchFollowupCostsData from './fetch-followup-costs-data';
import fetchIsochrones from './fetch-isochrones';
import fetchPois from './fetch-pois';
import fetchPopulationStructure from './fetch-population-structure';

/**
 *
 * Export as PDF
 */
export default function() {
  return (dispatch, getState: () => IStore) => {
    const {area} = getState();

    dispatch(fetchEcologyLayers());
    dispatch(fetchFollowupCostsData());
    dispatch(fetchIsochrones());
    dispatch(fetchPois());

    if (area.typeId === AreaTypeIds.Residential) {
      dispatch(fetchPopulationStructure());
    }
  };
}

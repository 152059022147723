import {IAction} from '../../interfaces/action';
import {AreaTypeIds} from '../../constants/area-types'; // eslint-disable-line no-unused-vars, max-len
import {
  SELECT_AREA_TYPE_ID,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE
} from '../../constants/actions';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected area type id
 */
export default function(
  state: AreaTypeIds | null = initialState,
  action: IAction
): AreaTypeIds | null {
  switch (action.type) {
    case SELECT_AREA_TYPE_ID:
      return action.areaTypeId || state;

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}

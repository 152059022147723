/**
 * Available network elements
 */
export const enum NetworkElementIds {
  Street1 = 1,
  Street2,
  Street3,
  Sewerage1,
  Sewerage2,
  Sewerage3,
  DrinkingWater1,
  DrinkingWater2,
  DrinkingWater3,
  Electricity1,
  Electricity2,
  Electricity3
}

/**
 * Available network element groups
 */
export const enum NetworkElementGroupIds {
  Street = 1,
  Sewerage,
  DrinkingWater,
  Electricity
}

/**
 * Assign ids to groups
 */
export const networkElementGroupLabels = new Map<
NetworkElementGroupIds,
string
>();

networkElementGroupLabels.set(NetworkElementGroupIds.Street, 'Straße');
networkElementGroupLabels.set(NetworkElementGroupIds.Sewerage, 'Kanalisation');
networkElementGroupLabels.set(
  NetworkElementGroupIds.DrinkingWater,
  'Trinkwasser'
);
networkElementGroupLabels.set(
  NetworkElementGroupIds.Electricity,
  'Elektrizität'
);

/**
 * Assign ids to groups
 */
export const networkElementGroups = new Map<
NetworkElementGroupIds,
NetworkElementIds[]
>();

networkElementGroups.set(NetworkElementGroupIds.Street, [
  NetworkElementIds.Street1,
  NetworkElementIds.Street2,
  NetworkElementIds.Street3
]);
networkElementGroups.set(NetworkElementGroupIds.Sewerage, [
  NetworkElementIds.Sewerage1,
  NetworkElementIds.Sewerage2,
  NetworkElementIds.Sewerage3
]);
networkElementGroups.set(NetworkElementGroupIds.DrinkingWater, [
  NetworkElementIds.DrinkingWater1,
  NetworkElementIds.DrinkingWater2,
  NetworkElementIds.DrinkingWater3
]);
networkElementGroups.set(NetworkElementGroupIds.Electricity, [
  NetworkElementIds.Electricity1,
  NetworkElementIds.Electricity2,
  NetworkElementIds.Electricity3
]);

import {IAction} from '../../interfaces/action';
import {
  SELECT_AREA_INFLUX,
  SELECT_AREA_TYPE_ID,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE,
  SET_AREA_INFLUX_SELLINGSPACE
} from '../../constants/actions';

/**
 * Initial state
 */
const initialState: number | null = null;

/**
 * Handle the current selected area influx
 */
export default function(
  state: number | null = initialState,
  action: IAction
): number | null {
  switch (action.type) {
    case SELECT_AREA_INFLUX:
      return action.areaInflux || state;

    case SET_AREA_INFLUX_SELLINGSPACE:
      return action.areaInflux || state;

    case SELECT_AREA_TYPE_ID:
      return initialState;

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}

import {AreaTypeIds} from './area-types';
import {ViewIds} from './views';
import React from 'react';

/* eslint-disable max-len */

const ecologyResidentialContent = (
  <div>
    <h4>Eingriffsminimierung</h4>
    <p>
      Neue Wohngebiete sollen gemäß Baugesetz einen möglichst geringen negativen
      Effekt auf unsere natürlichen Lebensgrundlagen haben. Zudem soll ein
      möglichst geringer Flächenanteil neu für Siedlungszwecke in Anspruch
      genommen werden. Dies gilt in besonderer Weise für ökologisch hochwertige
      Flächen, die ggf. auch einen entsprechenden Schutzstatus genießen.
    </p>

    <h4>Schutzstatus bestimmen per Karte</h4>
    <p>
      Mit der nachfolgenden Analysekarte haben Sie daher die Möglichkeit, das
      von Ihnen skizzierte Plangebiet mit den in Ihrer Region ausgewiesenen
      Schutzgebieten (Landschaftsschutzgebiete, Natur- und Artenschutzgebiete)
      sowie mit Wald-, Gehölz- und Freiraumflächen zu überlagern. Potenzielle
      Konflikte lassen sich dadurch leicht erkennen.
    </p>
    <p>
      Je nach Schutzstatus kann eine Überlappung Ihres Plangebiets
      unterschiedliche Folgen haben, die von einer Nutzungseinschränkung über
      umfangreiche Ausgleichs- und Ersatzmaßnahmen bis hin zu einer
      Nicht-Genehmigungsfähigkeit reichen können.
    </p>

    <h4>Profi-Check</h4>
    <p>
      Wenn Sie Ihre Analyse noch weiter vertiefen wollen, finden Sie im
      Profi-Check zusätzliche Funktionen, mit denen sich u.a. potenzielle
      Konflikte mit unzerschnittenen verkehrsarmen Räumen ermitteln lassen.
    </p>
  </div>
);

const ecologyIndustrialContent = (
  <div>
    <h4>Eingriffsminimierung</h4>
    <p>
      Neue Gewerbegebiete sollen gemäß Baugesetz einen möglichst geringen
      negativen Effekt auf unsere natürlichen Lebensgrundlagen haben. Zudem soll
      ein möglichst geringer Flächenanteil neu für Siedlungszwecke in Anspruch
      genommen werden. Dies gilt in besonderer Weise für ökologisch hochwertige
      Flächen, die ggf. auch einen entsprechenden Schutzstatus genießen.
    </p>

    <h4>Schutzstatus bestimmen per Karte</h4>
    <p>
      Mit der nachfolgenden Analysekarte haben Sie daher die Möglichkeit, das
      von Ihnen skizzierte Plangebiet mit den in Ihrer Region ausgewiesenen
      Schutzgebieten (Landschaftsschutzgebiete, Biosphärenreservate und
      Naturparke) zu überlagern. Potenzielle Konflikte lassen sich dadurch
      leicht erkennen.
    </p>
    <p>
      Je nach Schutzstatus kann eine Überlappung Ihres Plangebiets
      unterschiedliche Folgen haben, die von einer Nutzungseinschränkung über
      umfangreiche Ausgleichs- und Ersatzmaßnahmen bis hin zu einer
      Nicht-Genehmigungsfähigkeit reichen können.
    </p>

    <h4>Profi-Check</h4>
    <p>
      Wenn Sie Ihre Analyse noch weiter vertiefen wollen, finden Sie im
      Profi-Check zusätzliche Funktionen, mit denen sich u.a. potenzielle
      Konflikte mit unzerschnittenen verkehrsarmen Räumen ermitteln lassen.
    </p>
  </div>
);

const ecologyRetailContent = (
  <div>
    <h4>Eingriffsminimierung</h4>
    <p>
      Neue Einzelhandelsflächen sollen gemäß Baugesetz einen möglichst geringen
      negativen Effekt auf unsere natürlichen Lebensgrundlagen haben. Zudem soll
      ein möglichst geringer Flächenanteil neu für Siedlungszwecke in Anspruch
      genommen werden. Dies gilt in besonderer Weise für ökologisch hochwertige
      Flächen, die ggf. auch einen entsprechenden Schutzstatus genießen.
    </p>

    <h4>Schutzstatus bestimmen per Karte</h4>
    <p>
      Mit der nachfolgenden Analysekarte haben Sie daher die Möglichkeit, das
      von Ihnen skizzierte Plangebiet mit den in Ihrer Region ausgewiesenen
      Schutzgebieten (Landschaftsschutzgebiete, Biosphärenreservate und
      Naturparke) zu überlagern. Potenzielle Konflikte lassen sich dadurch
      leicht erkennen.
    </p>
    <p>
      Je nach Schutzstatus kann eine Überlappung Ihres Plangebiets
      unterschiedliche Folgen haben, die von einer Nutzungseinschränkung über
      umfangreiche Ausgleichs- und Ersatzmaßnahmen bis hin zu einer
      Nicht-Genehmigungsfähigkeit reichen können.
    </p>

    <h4>Profi-Check</h4>
    <p>
      Wenn Sie Ihre Analyse noch weiter vertiefen wollen, finden Sie im
      Profi-Check zusätzliche Funktionen, mit denen sich u.a. potenzielle
      Konflikte mit unzerschnittenen verkehrsarmen Räumen ermitteln lassen.
    </p>
  </div>
);

const highVoltageSupplyLinesContent = (
  <div>
    <h4>Schutzgut Mensch</h4>
    <p>
      Mit der nachfolgenden Analysekarte haben Sie die Möglichkeit, sich den
      Verlauf von Hochspannungsfreileitungen rund um Ihr Plangebiet anzeigen zu
      lassen.
    </p>
    <p>
      Aus einer unmittelbaren Nähe von Freileitungen zu Ihrem Plangebiet können
      sich genehmigungsrelevante Aspekte („Schutzgut Mensch“) ergeben. Zudem
      haben Hochspannungsfreileitungen u.U. Auswirkungen auf die Vermarktbarkeit
      Ihres Plangebiets.
    </p>
  </div>
);

export const informationContents = {
  [`${ViewIds.PopulationDevelopment}#${AreaTypeIds.Residential}`]: (
    <div>
      <h4>Wie viele Bewohner/innen?</h4>
      <p>
        Wie viele Bewohner/innen werden später in dem Wohngebiet wohnen? Und in
        welchem Alter werden sie sein? Wie viele Kinder werden dort mit
        einziehen – oder in den Folgejahren dort geboren werden?
      </p>
      <p>
        Für viele kommunale Planungsüberlegungen sind dies wichtige Fragen, z.B.
        zur Abschätzung der voraussichtlichen Mehrbedarfe im Bereich der
        sozialen Infrastrukturen.
      </p>

      <h4>Schätzwerte</h4>
      <p>
        Anhand von Auswertungen der Bewohnerentwicklung in Referenzprojekten
        sowie deutschlandweiter Datenquellen wie dem Mikrozensus schätzt
        Projekt-Check mit der nachfolgenden Darstellung die voraussichtliche
        Zahl und Altersstruktur der zukünftigen Bewohner/innen Ihres
        Plangebiets.
      </p>
      <p>
        Berücksichtigt werden dabei u.a. die von Ihnen gewählten
        Bebauungsdichte, die von Ihnen geschätzte Bezugsdauer sowie die von
        Ihnen angegebene Größe des Plangebiets.
      </p>

      <h4>Annahmen verändern</h4>
      <p>
        Über die beiden Reiter „Annahme Bebauung“ und „Annahme Bewohner“ der
        Analyseansicht haben Sie die Möglichkeit, die Annahmen für die
        Abschätzung der Bewohnerentwicklung zu verändern und sie so ggf. noch
        besser auf ihre lokale Situation anzupassen.
      </p>
    </div>
  ),

  [`${ViewIds.WorkplaceDevelopment}#${AreaTypeIds.Industrial}`]: (
    <div>
      <h4>Wie viele Arbeitsplätze?</h4>
      <p>
        Sofern ein Gewerbegebiet nicht für eine bereits feststehende
        Unternehmensansiedlung oder -erweiterung ausgewiesen wird, ist es Teil
        einer so genannten „Angebotsplanung“. Dabei ist häufig zunächst unklar,
        wie viele Arbeitsplätze sich auf der Fläche ansiedeln werden.
      </p>

      <h4>Orientierungswerte</h4>
      <p>
        Im Sinne eines ersten Orientierungswertes schätzt daher der
        Projekt-Check die Anzahl der Arbeitsplätze anhand der Flächengröße und
        der Lage des Plangebiets.
      </p>
      <p>
        Die Genauigkeit des Schätzwertes ist dabei deutlich geringer als bei der
        Schätzung der zukünftigen Einwohnerzahl von Wohnbaugebieten. Die Zahl
        der Unternehmen, die sich auf einer neuen Gewerbefläche ansiedeln, ist
        in aller Regel sehr überschaubar. Damit haben die spezifischen
        Flächenbedarfe dieser wenigen Firmen großen Einfluss auf die Zahl der
        Arbeitsplätze im Gebiet insgesamt. Entsprechend unsicher ist eine solche
        Prognose.
      </p>
      <p>
        Gleichwohl kann in einer frühen Planungsphase ein Orientierungswert zur
        voraussichtlichen Anzahl der Arbeitsplätze eine wichtige Hilfestellung
        für die weitergehenden Planungsüberlegungen sein.
      </p>

      <h4>Branchenstruktur</h4>
      <p>
        Bei der Herleitung des Orientierungswertes nimmt Projekt-Check zunächst
        eine Schätzung der voraussichtlichen Branchenstruktur vor, die sich aus
        dem von Ihnen gewählten Flächentyp ergibt. Anschließend wird anhand des
        mittleren Flächenbedarfs pro Arbeitsplatz in den einzelnen Branchen die
        voraussichtliche Gesamtzahl an Arbeitsplätzen im Plangebiet geschätzt.
      </p>
      <p>
        Berücksichtigt werden dabei auch regionale Dichtekennwerte, die das
        Bodenpreisniveau und die regionale Flächenverfügbarkeit berücksichtigen.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficReachability}#${AreaTypeIds.Residential}`]: (
    <div>
      <h4>Erreichbarkeit macht attraktiv</h4>
      <p>
        Attraktive Wohngebiete zeichnen sich u.a. dadurch aus, dass man für
        möglichst viele Ziele des täglichen Lebens nicht ins Auto steigen muss.
        Vielmehr sollten Schulen, Kitas, Einkaufsläden und Freizeiteinrichtungen
        nach Möglichkeit auch zu Fuß oder mit dem Rad gut erreichbar sein. Zudem
        sollten neue Flächenplanungen günstig zu den ÖPNV-Haltestellen liegen.
      </p>

      <h4>Analyse per Karte</h4>
      <p>
        Mit der nachfolgenden Kartendarstellung können Sie mit einem Blick
        prüfen, wie sich die Erreichbarkeitsverhältnisse für Ihr Plangebiet
        darstellen. Dazu werden rund um das Plangebiet die Gebiete eingefärbt,
        die in 10 Minuten zu Fuß, mit dem Rad oder mit dem Auto erreichbar sind.
      </p>
      <p>
        Zusätzlich werden die Standorte von Kitas, Schulen, Supermärkten und
        Haltestellen des ÖPNV eingeblendet. Auf diese Weise wird schnell
        erkennbar, welche Einrichtungen des täglichen Bedarfs mit welchem
        Verkehrsmittel wie gut erreichbar sind – und welche nicht.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Wenn Sie andere Wegzeiten als 10 Minuten analysieren oder die
        Darstellung mit einigen Daten- und Kartengrundlagen verschneiden
        möchten, finden Sie entsprechende Zusatzfunktionen im Profi-Check.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficReachability}#${AreaTypeIds.Industrial}`]: (
    <div>
      <h4>Gute Erreichbarkeit wichtig</h4>
      <p>Gewerbegebiete sollten in zweierlei Hinsicht gut erreichbar sein.</p>
      <p>
        Zum einen ist für viele Unternehmen eine gute Anbindung an das
        übergeordnete Straßennetz (Bundes- und Landesstraßen sowie Autobahnen)
        wichtig.
      </p>
      <p>
        Zum anderen hilft eine gute Erreichbarkeit des Standortes für die
        Mitarbeiter den Unternehmen, einen reibungslosen und flexiblen Betrieb
        zu organisieren. Gerade bei der Erreichbarkeit für die Mitarbeiter
        spielt neben der Autoerreichbarkeit auch die Erreichbarkeit mit dem Rad
        und dem ÖPNV eine zentrale Rolle.
      </p>

      <h4>Analyse per Karte</h4>
      <p>
        Mit der nachfolgenden Kartendarstellung können Sie mit einem Blick
        prüfen, wie sich die Erreichbarkeitsverhältnisse für Ihr Plangebiet
        darstellen. Dazu werden rund um das Plangebiet die Gebiete eingefärbt,
        von denen aus man das neue Gewerbegebiet in 10 Minuten zu Fuß, mit dem
        Rad oder mit dem Auto erreichen kann. Zusätzlich wird die Lage der
        ÖPNV-Haltestellen eingeblendet.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Wenn Sie andere Wegzeiten als 10 Minuten analysieren oder die
        Darstellung mit einigen Daten- und Kartengrundlagen verschneiden
        möchten, finden Sie entsprechende Zusatzfunktionen im Profi-Check.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficReachability}#${AreaTypeIds.Retail}`]: (
    <div>
      <h4>Erreichbarkeit elementar</h4>
      <p>
        Für erfolgreiche Einzelhandelsprojekte ist eine gute Erreichbarkeit eine
        Grundvoraussetzung. Erreichbarkeit meint dabei nicht nur
        Autoerreichbarkeit, sondern auch Erreichbarkeit zu Fuß, mit dem Rad und
        mit dem ÖPNV.
      </p>

      <h4>Analyse per Karte</h4>
      <p>
        Mit der nachfolgenden Kartendarstellung können Sie mit einem Blick
        prüfen, wie sich die Erreichbarkeitsverhältnisse für Ihr Plangebiet
        darstellen. Dazu werden rund um Ihren Einzelhandelsstandort die Gebiete
        eingefärbt, von denen aus Ihr Einzelhandelsprojekt in 10 Minuten zu Fuß,
        mit dem Rad oder mit dem Auto erreicht werden kann. Zusätzlich wird die
        Lage der ÖPNV-Haltestellen im Umfeld eingeblendet.
      </p>
      <p>
        Darüber hinaus finden Sie in der Karte die Standorte bereits vorhandener
        Supermärkte.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Wenn Sie andere Wegzeiten als 10 Minuten analysieren oder die
        Darstellung mit einigen Daten- und Kartengrundlagen verschneiden
        möchten, finden Sie entsprechende Funktionen im Profi-Check.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficEnvironment}#${AreaTypeIds.Residential}`]: (
    <div>
      <h4>Zusatzbelastungen?</h4>
      <p>
        Ein neues Wohngebiet verändert die lokalen Verkehrsflüsse. Insbesondere
        der Autoverkehr kann dabei im Umfeld des Plangebiets zu
        Zusatzbelastungen für die Anwohner/innen und zur Überlastung von
        Knotenpunkten führen.
      </p>

      <h4>Schwachstellensuche per Karte</h4>
      <p>
        Die Analysekarte hilft Ihnen, entsprechende
        Belastungsabschnitte und potenzielle Überlastungspunkte frühzeitig zu
        erkennen und in Ihrer Planung zu berücksichtigen.
      </p>
      <p>
        Klicken Sie hierzu nacheinander auf eine beliebige Anzahl von
        Einrichtungen außerhalb Ihres Plangebiets, von denen Sie annehmen, dass
        sie für die späteren Wohngebietsbewohner eine wichtige Rolle in der
        Alltagsmobilität mit dem Auto spielen werden. Dies können z.B. Schulen,
        Einkaufsläden oder Arbeitsplatzschwerpunkte sein. Alle angeklickten Orte 
        werden für die Berechnung des zusätzlichen Verkehrsvolumens gleich behandelt.
        Gibt es Orte die eine herausragende Bedeutung für den Verkehr haben, so 
        können Sie mehrere Punkte in unmittelbarer Nähe zueinander setzen und so 
        die Gewichtung dieser Orte vervielfachen. 
      </p>
      <p>
        Projekt-Check routet daraufhin den voraussichtlichen Fahrtweg zwischen
        dem Plangebiet und Ihren Zielpunkten. Überlagern sich die Fahrtwege zu
        den Zielpunkten, werden diese dicker dargestellt um ein erhöhtes Verkehrsaufkommen 
        zu symbolisieren. Diese dicker dargestellten Bereiche sind ein erster Hinweis
        auf besondere Zusatzbelastungen für die Anwohner/innen und Knotenpunkte.
      </p>
      <p>
        Das Verkehrsvolumen wird anhand von Bebauungsdichte und Nutzung des 
        Plangebiets (und der daraus abgeleiteten Anwohnerzahl) berechnet. 
        Hinzugezogen werden außerdem empirische Kennzahlen zum Anteil der Fahrten
        im motorisierten Individualverkehr (MIV). Die Anzahl dieser MIV-Fahrten
        pro Tag wird in der Karte entlang der Fahrt-Äste angezeigt, wenn Sie nah 
        heranzoomen.
      </p>

      <h4>Zielpunkte zusammenfassen</h4>
      <p>
        Überlappungen treten meist im unmittelbaren Umfeld des Plangebiets auf.
        Fassen Sie daher weiter entfernt liegende Zielpunkte gedanklich
        zusammen, indem Sie statt einer konkreten Einrichtung eine Kreuzung oder
        eine Schnellstraßenauffahrt als Zielpunkt wählen. Wenn Sie z.B. wissen,
        dass alle Verkehre, die Richtung „XY“ wollen, an einer bestimmten Stelle
        auf die Hauptstraße oder Autobahn auffahren werden, dann klicken Sie
        diese Kreuzung oder Auffahrt als zusammenfassenden Zielpunkt an.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Wenn Sie Ihre Analyse noch weiter vertiefen wollen, können Sie mit
        Profi-Check das Verkehrsvolumen noch kleinteiliger nachvollziehen.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficEnvironment}#${AreaTypeIds.Industrial}`]: (
    <div>
      <h4>Zusatzbelastungen?</h4>
      <p>
        Ein neues Gewerbegebiet verändert die lokalen Verkehrsflüsse.
        Insbesondere der Auto- und Lkw-Verkehr kann dabei im Umfeld des
        Plangebiets zu Zusatzbelastungen für die Anwohner/innen und zur
        Überlastung von Knotenpunkten führen.
      </p>

      <h4>Schwachstellensuche per Karte</h4>
      <p>
        Die nachfolgende Analysekarte hilft Ihnen, entsprechende
        Belastungsabschnitte und potenzielle Überlastungspunkte frühzeitig zu
        erkennen und in Ihrer Planung zu berücksichtigen.
      </p>
      <p>
        Klicken Sie hierzu nacheinander auf eine beliebige Anzahl von
        Herkunftspunkten außerhalb Ihres Plangebiets, von denen Sie annehmen,
        dass von hier die meisten Lieferverkehre und Fahrten der Mitarbeiter und
        Kunden kommen werden. Dies können z.B. bereits ansässige Großunternehmen
        oder bestimmte Wohngebiete sein.
      </p>
      <p>
        Projekt-Check routet daraufhin den voraussichtlichen Fahrtweg zwischen
        Ihren Herkunftspunkten und dem Plangebiet. Überlagern sich die Fahrtwege
        von den Herkunftspunkten zum neuen Gewerbegebiet, so sind die
        Überlappungsabschnitte ein erster Hinweis auf besondere
        Zusatzbelastungen für die Anwohner/innen und Knotenpunkte.
      </p>
      <p>
        Das Verkehrsvolumen wird anhand von Bebauungsdichte und Nutzung des 
        Plangebiets berechnet. Hinzugezogen werden außerdem empirische Kennzahlen 
        zum Anteil der Fahrten im motorisierten Individualverkehr (MIV). Die Anzahl 
        dieser MIV-Fahrten pro Tag wird in der Karte entlang der Fahrt-Äste angezeigt 
        (ggf. etwas rein- und rauszoomen).
      </p>

      <h4>Herkunftspunkte zusammenfassen</h4>
      <p>
        Überlappungen treten meist im unmittelbaren Umfeld des Plangebiets auf.
        Fassen Sie daher weiter entfernt liegende Herkunftspunkte gedanklich
        zusammen, indem Sie statt eines konkreten Standortes eine Kreuzung oder
        eine Schnellstraßenauffahrt als Herkunftspunkt wählen. Wenn Sie z.B.
        wissen, dass alle Verkehre, die aus Richtung „XY“ kommen, an einer
        bestimmten Stelle von der Hauptstraße oder Autobahn abfahren werden,
        dann klicken Sie diese Kreuzung oder Auffahrt als zusammenfassenden
        Herkunftspunkt an.
      </p>

      <h4>Projekt-Check</h4>
      <p>
        Wenn Sie Ihre Analyse noch weiter vertiefen wollen, können Sie mit
        Profi-Check auch noch die Verkehrsvolumen der einzelnen Ströme schätzen.
      </p>
    </div>
  ),

  [`${ViewIds.TrafficEnvironment}#${AreaTypeIds.Retail}`]: (
    <div>
      <h4>Zusatzbelastungen?</h4>
      <p>
        Eine neue Einzelhandelsfläche verändert die lokalen Verkehrsflüsse.
        Insbesondere der Auto- und Lkw-Verkehr kann dabei im Umfeld des
        Plangebiets zu Zusatzbelastungen für die Anwohner/innen und zur
        Überlastung von Knotenpunkten führen.
      </p>

      <h4>Schwachstellensuche per Karte</h4>
      <p>
        Die nachfolgende Analysekarte hilft Ihnen, entsprechende
        Belastungsabschnitte und potenzielle Überlastungspunkte frühzeitig zu
        erkennen und in Ihrer Planung zu berücksichtigen.
      </p>
      <p>
        Klicken Sie hierzu nacheinander auf eine beliebige Anzahl von
        Herkunftspunkten außerhalb Ihres Plangebiets, von denen Sie annehmen,
        dass von hier die meisten Lieferverkehre und Kundenfahrten kommen
        werden. Dies können z.B. Logistikzentren oder bestimmte Wohngebiete im
        Einzugsbereich sein.
      </p>
      <p>
        Projekt-Check routet daraufhin den voraussichtlichen Fahrtweg zwischen
        Ihren Herkunftspunkten und dem Plangebiet. Überlagern sich die Fahrtwege
        von den Herkunftspunkten zu Ihrer neuen Einzelhandelsfläche, so sind die
        Überlappungsabschnitte ein erster Hinweis auf besondere
        Zusatzbelastungen für die Anwohner/innen und Knotenpunkte.
      </p>
      <p>
        Das Verkehrsvolumen wird anhand von der angestrebten Verkaufsfläche des 
        Plangebiets berechnet. Hinzugezogen werden außerdem empirische Kennzahlen 
        zum Anteil der Fahrten im motorisierten Individualverkehr (MIV) der Kunden. 
        Die Anzahl dieser MIV-Fahrten pro Tag wird in der Karte entlang der 
        Fahrt-Äste angezeigt (ggf. etwas rein- und rauszoomen).
      </p>

      <h4>Herkunftspunkte zusammenfassen</h4>
      <p>
        Überlappungen treten meist im unmittelbaren Umfeld des Plangebiets auf.
        Fassen Sie daher weiter entfernt liegende Herkunftspunkte gedanklich
        zusammen, indem Sie statt eines konkreten Herkunftsortes eine Kreuzung
        oder eine Schnellstraßenauffahrt als Herkunftspunkt wählen. Wenn Sie
        z.B. wissen, dass alle Verkehre, die aus Richtung „XY“ kommen, an einer
        bestimmten Stelle von der Hauptstraße oder Autobahn abfahren werden,
        dann klicken Sie diese Kreuzung oder Auffahrt als zusammenfassenden
        Herkunftspunkt an.
      </p>

      <h4>Projekt-Check</h4>
      <p>
        Wenn Sie Ihre Analyse noch weiter vertiefen wollen, können Sie mit
        Profi-Check auch noch die Verkehrsvolumen der einzelnen Ströme schätzen.
      </p>
    </div>
  ),

  [`${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Residential}`]: [
    ecologyResidentialContent
  ],

  [`${ViewIds.EcologyForest}#${AreaTypeIds.Residential}`]: [
    ecologyResidentialContent
  ],

  [`${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Residential}`]: [
    highVoltageSupplyLinesContent
  ],

  [`${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Residential}`]: [
    ecologyResidentialContent
  ],

  [`${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Residential}`]: [
    ecologyResidentialContent
  ],

  [`${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Residential}`]: [
    ecologyResidentialContent
  ],

  [`${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Industrial}`]: [
    ecologyIndustrialContent
  ],

  [`${ViewIds.EcologyForest}#${AreaTypeIds.Industrial}`]: [
    ecologyIndustrialContent
  ],

  [`${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Industrial}`]: [
    highVoltageSupplyLinesContent
  ],

  [`${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Industrial}`]: [
    ecologyIndustrialContent
  ],

  [`${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Industrial}`]: [
    ecologyIndustrialContent
  ],

  [`${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Industrial}`]: [
    ecologyIndustrialContent
  ],

  [`${ViewIds.EcologyFloorSealing}#${AreaTypeIds.Retail}`]: [
    ecologyRetailContent
  ],

  [`${ViewIds.EcologyForest}#${AreaTypeIds.Retail}`]: [ecologyRetailContent],

  [`${ViewIds.EcologyHighVoltageSupplyLines}#${AreaTypeIds.Retail}`]: [
    highVoltageSupplyLinesContent
  ],

  [`${ViewIds.EcologyLandscapeProtection}#${AreaTypeIds.Retail}`]: [
    ecologyRetailContent
  ],

  [`${ViewIds.EcologyOpenSpace}#${AreaTypeIds.Retail}`]: [ecologyRetailContent],

  [`${ViewIds.EcologySpeciesProtection}#${AreaTypeIds.Retail}`]: [
    ecologyRetailContent
  ],

  [`${ViewIds.FollowupCosts}#${AreaTypeIds.Residential}`]: (
    <div>
      <h4>Zusätzliche Infrastruktur</h4>
      <p>
        Neue Wohngebiete benötigen fast immer zusätzliche
        Erschließungsinfrastruktur. Diese Erweiterung der Straßen-, Rohr- und
        Leitungsnetze führt nicht nur zu erstmaligen Herstellungskosten. Die
        neuen Netzabschnitte sind auch langfristig zu unterhalten, zu betreiben
        und in regelmäßigen Abständen zu erneuern.
      </p>

      <h4>Wer zahlt was?</h4>
      <p>
        Während die Investoren und zukünftigen Besitzer der neu erschlossenen
        Flächen i.d.R. den Großteil der erstmaligen Herstellungskosten zahlen,
        bleiben die Kosten für Betrieb, Unterhaltung und Erneuerung fast
        ausschließlich an der Gemeinde und der Allgemeinheit der Netzkunden,
        d.h. an allen Bürgern hängen.
      </p>

      <h4>Erste Grobabschätzung</h4>
      <p>
        In den nachfolgenden Analysegrafiken schätzt Projekt-Check die
        Infrastrukturfolgekosten pro Wohneinheit in Ihrem Plangebiet für
      </p>
      <ul>
        <li>Straßen</li>
        <li>Kanalisation</li>
        <li>Trinkwasser- und</li>
        <li>Stromversorgung</li>
      </ul>
      <p>
        Dazu wird im Hintergrund zunächst eine Schätzung der benötigten
        Infrastrukturmengen (Längen und Querschnitte) vorgenommen, in die u.a.
        die Größe und Nutzungsdichte Ihres Plangebiets einfließt. Anschließend
        werden die geschätzten Mengen mit regionalen Durchschnittskosten
        multipliziert.
      </p>
      <p>
        Die so ermittelten Folgekosten werden in drei Ergebnisgrafiken
        veranschaulicht.
      </p>
      <ul>
        <li>
          Die erste Grafik differenziert die Kosten nach der Kostenphase, d.h.
          nach den Kosten für die erstmalige Herstellung und den Kosten für den
          späteren Betrieb, die Unterhaltung und turnusmäßige Erneuerung.
        </li>
        <li>
          Die zweite Grafik differenziert die gleichen Gesamtkosten pro
          Wohneinheit nach den Kostenträgern. Dabei wird berücksichtigt, dass
          sich die Kosten der erstmaligen Herstellung anders verteilen als die
          Kosten für Betrieb, Unterhaltung und Erneuerung.
        </li>
        <li>
          Die dritte Grafik gibt Ihnen die Möglichkeit die Gesamtkosten pro
          Wohneinheit mit einem bundesdeutschen Durchschnittswert zu
          vergleichen. Dieser Kostendurchschnitt bezieht sich auf den
          Wohnungsneubau in Deutschland im Zeitraum 2011 bis 2015.
        </li>
      </ul>

      <h4>Dichte spart Kosten</h4>
      <p>
        Bei dem Vergleich in der dritten Grafik macht sich – neben dem
        regionalen Baukostenniveau – vor allem die Nutzungsdichte bemerkbar.
        Wohngebiete mit Einfamilienhäusern erzeugen deutlich mehr
        Infrastrukturkosten pro Wohneinheit als Gebiete mit Reihen- und
        Mehrfamilienhäusern.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Die Kostenschätzung berücksichtigt nur die innere Erschließung und
        unterstellt die Erhebung von Beiträgen nach BauGB. Über einen
        städtebaulichen Vertrag kann die Gemeinden ihren Anteil an den Kosten
        der erstmaligen Herstellung ggf. noch etwas verringern. Entsprechende
        Analysemöglichkeiten finden Sie im Profi-Check.
      </p>
    </div>
  ),

  [`${ViewIds.FollowupCosts}#${AreaTypeIds.Industrial}`]: (
    <div>
      <h4>Zusätzliche Infrastruktur</h4>
      <p>
        Neue Gewerbegebiete benötigen fast immer zusätzliche
        Erschließungsinfrastruktur. Diese Erweiterung der Straßen-, Rohr- und
        Leitungsnetze führt nicht nur zu erstmaligen Herstellungskosten. Die
        neuen Netzabschnitte sind auch langfristig zu unterhalten, zu betreiben
        und in regelmäßigen Abständen zu erneuern.
      </p>

      <h4>Erste Grobabschätzung</h4>
      <p>
        In den nachfolgenden Analysegrafiken schätzt Projekt-Check die
        Infrastrukturfolgekosten in Ihrem Plangebiet für
      </p>
      <ul>
        <li>Straßen</li>
        <li>Kanalisation</li>
        <li>Trinkwasser- und</li>
        <li>Stromversorgung</li>
      </ul>
      <p>
        Dazu wird im Hintergrund zunächst eine Schätzung der benötigten
        Infrastrukturmengen (Längen und Querschnitte) vorgenommen, in die u.a.
        die Größe und der Gebietstyp Ihres Plangebiets einfließt. Anschließend
        werden die geschätzten Mengen mit regionalen Durchschnittskosten
        multipliziert.
      </p>
      <p>
        Die so ermittelten Folgekosten werden in drei Ergebnisgrafiken
        veranschaulicht.
      </p>
      <ul>
        <li>
          Die erste Grafik differenziert die Kosten nach der Kostenphase, d.h.
          nach den Kosten für die erstmalige Herstellung und den Kosten für den
          späteren Betrieb, die Unterhaltung und turnusmäßige Erneuerung.
        </li>
        <li>
          Die zweite Grafik differenziert die gleichen Gesamtkosten nach den
          Kostenträgern. Dabei wird berücksichtigt, dass sich die Kosten der
          erstmaligen Herstellung anders verteilen als die Kosten für Betrieb,
          Unterhaltung und Erneuerung.
        </li>
        <li>
          Die dritte Grafik vergleicht die Gesamtkosten pro Arbeitsplatz mit
          einem bundesdeutschen Durchschnittswert. Dieser bezieht sich auf die
          Kostenstruktur der gewerblichen Bestandsflächen in Deutschland. Die
          Zahl der für das Plangebiet angenommenen Arbeitsplätze entspricht dem
          Orientierungswert aus dem vorangegangenen Analyseschritt
          „Arbeitsplätze“.
        </li>
      </ul>

      <h4>Profi-Check</h4>
      <p>
        Die Kostenschätzung berücksichtigt nur die innere Erschließung und
        unterstellt eine kommunale Finanzierung der erstmaligen Herstellung der
        Straßen. Weitergehende Analysemöglichkeiten finden Sie im Profi-Check.
      </p>
    </div>
  ),

  [`${ViewIds.MunicipalIncome}#${AreaTypeIds.Residential}`]: (
    <div>
      <h4>Umzugsketten</h4>
      <p>
        Neue Wohngebiete führen zu Umzügen von Haushalten. So ziehen Haushalte
        aus Bestandswohnungen in die neu gebauten Wohnungen. Vielfach ziehen
        anschließend wiederum andere Haushalte in die so frei gezogenen
        Wohnungen. Diese Kette geht immer weiter und verliert sich irgendwann im
        “allgemeinen Rauschen” des Wohnungsmarktes.
      </p>

      <h4>Steuermehreinnahmen</h4>
      <p>
        Bei diesen Umzugsketten ziehen fast immer auch Haushalte über
        Gemeindegrenzen um. Dies hat Auswirkungen auf die kommunalen Einnahmen.
        So erhalten die Zuzugsgemeinden zusätzliche Steuereinnahmen aus der
        Einkommensteuer und dem Familienleistungsausgleich, bei Wohnungsneubau
        auch aus der Grundsteuer. Zudem steigen i.d.R. auch ihre Zuweisungen aus
        dem kommunalen Finanzausgleich. Die Fortzugsgemeinden wiederum verlieren
        Steuereinnahmen und Zuweisungen.
      </p>

      <h4>Mehrausgaben</h4>
      <p>
        Den zusätzlichen Steuereinnahmen stehen aber auch zusätzliche Ausgaben
        der Zuzugsgemeinden gegenüber. So steigen mit den Steuermehreinnahmen
        die zu zahlenden Umlagen an Kreis, Verwaltungsgemeinschaft oder
        Zweckverband. Mit den verbleibenden Mehreinnahmen müssen zudem die
        zusätzlich benötigten sozialen und technischen Infrastrukturen
        finanziert werden.
      </p>

      <h4>Bilanz</h4>
      <p>
        In der Gesamtsumme übersteigen die Mehrausgaben bei über einem Drittel
        aller Wohngebietsausweisungen in Deutschland die Mehreinnahmen der
        Gemeinden. Höhere Nutzungsdichten, d.h. kleinere Grundstücke und
        kompaktere Bauformen führen i.d.R. zu einem günstigeren Verhältnis von
        Mehreinnahmen und -ausgaben.
      </p>

      <h4>Einflussgrößen</h4>
      <p>
        Auch eine Beteiligung der Gemeinde an den planungsbedingten
        Wertsteigerungen der Grundstücke lässt viele Kommunen in der fiskalische
        Bilanz besser dastehen als bei reinen Investorenplanungen. Eine
        Beteiligung der Gemeinde an den planungsbedingten Wertsteigerungen kann
        z.B. durch eine eigene Flächenentwicklung der Gemeinde oder durch einen
        über die Gemein- und Erschließungsflächen hinausgehenden kommunalen
        Flächenanteil bei Umlegungsverfahren erzielt werden.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Die fiskalischen Zusammenhänge sind zu komplex, um Sie in eine
        Online-Kurzanalyse wie Web-Check zu integrieren. Profi-Check bietet
        Ihnen hingegen die Möglichkeit abzuschätzen, welche regionalen
        Verschiebungen sich bei den kommunalen Steuereinnahmen durch Ihre
        Wohngebietsausweisung ergeben würde.
      </p>
    </div>
  ),

  [`${ViewIds.MunicipalIncome}#${AreaTypeIds.Industrial}`]: (
    <div>
      <h4>Verlagerungen</h4>
      <p>
        Neue Gewerbegebiete führen zu Verlagerungen von Unternehmen. Vielfach
        stehen diese Verlagerungen im Zusammenhang mit Erweiterungen, in sehr
        wenigen Fällen auch mit Neugründungen.
      </p>

      <h4>Verlagerungsketten</h4>
      <p>
        Je nach Nutzbarkeit der dabei ggf. freigezogenen Gewerbeflächen werden
        diese von anderen Firmen nachgenutzt, die ihrerseits verlagern oder
        erweitern. In der Gesamtbilanz sinkt dabei häufig die Arbeitsplatzdichte
        (Arbeitsplätze pro Hektar) der in die Verlagerungskette einbezogenen
        Gewerbeflächen.
      </p>

      <h4>Steuermehreinnahmen</h4>
      <p>
        Bei diesen Verlagerungsketten ziehen fast immer auch Unternehmen (bzw.
        Unternehmensteile) über Gemeindegrenzen um. Dies hat Auswirkungen auf
        die kommunalen Einnahmen. So erhalten die Zuzugsgemeinden zusätzliche
        Steuereinnahmen aus der Gewerbe- und der Umsatzsteuer, beim Neubau von
        Gewerbeimmobilien auch aus der Grundsteuer. Die Fortzugsgemeinden
        wiederum verlieren Steuereinnahmen.
      </p>

      <h4>Mehrausgaben</h4>
      <p>
        Den zusätzlichen Steuereinnahmen stehen aber auch zusätzliche Ausgaben
        der Zuzugsgemeinden gegenüber. So steigen mit den Steuermehreinnahmen
        die zu zahlenden Umlagen an Kreis, Verwaltungsgemeinschaft oder
        Zweckverband sowie die Gewerbesteuerumlage. Durch die gestiegene
        Steuerkraft sinken i.d.R. zudem die Zuweisungen aus dem kommunalen
        Finanzausgleich. Mit den verbleibenden Mehreinnahmen müssen zudem die
        zusätzlich benötigten technischen Infrastrukturen finanziert und
        betrieben werden.
      </p>

      <h4>Bilanz</h4>
      <p>
        In der Gesamtsumme übersteigen die Mehrausgaben bei schätzungsweise etwa
        einem Drittel aller Gewerbegebietsausweisungen in Deutschland die
        Mehreinnahmen der Gemeinden. Höhere Nutzungsdichten führen dabei
        tendenziell zu einem günstigeren Verhältnis von Mehreinnahmen und
        -ausgaben. Gewerbeflächen mit einem eher büroorientierten Branchenmix
        (ohne öffentliche Verwaltung) haben im Mittel höhere
        Gewerbesteuererträge. Da nur etwa ein Drittel aller Unternehmen in
        Deutschland überhaupt Gewerbesteuer zahlt, sind die Einnahmeeffekte für
        konkrete Gewerbeflächen jedoch kaum vorhersagbar, sofern die
        ansiedelnden Unternehmen noch nicht bekannt sind („Angebotsplanung“).
      </p>

      <h4>Profi-Check</h4>
      <p>
        Die fiskalischen Zusammenhänge sind zu komplex, um Sie in eine
        Online-Kurzanalyse wie Web-Check zu integrieren. Profi-Check bietet
        Ihnen hingegen die Möglichkeit abzuschätzen, welche regionalen
        Verschiebungen sich bei den kommunalen Steuereinnahmen durch Ihre
        Gewerbegebietsausweisung ergeben würde.
      </p>
    </div>
  ),

  [`${ViewIds.LocationCompetition}#${AreaTypeIds.Retail}`]: (
    <div>
      <h4>Verdrängungswettbewerb</h4>
      <p>
        Der Einzelhandel in Deutschland ist von einem Verdrängungswettbewerb
        gekennzeichnet. Insbesondere im Lebensmitteleinzelhandel führen
        zusätzliche Verkehrsflächen nicht zu einer Steigerung der Konsumausgaben
        der Haushalte. Diese werden lediglich neu verteilt.
      </p>

      <h4>Kontroverse Diskussionen</h4>
      <p>
        Die Ansiedlung neuer Supermärkte und Discounter wird daher häufig
        kontrovers diskutiert, da sie die vorhandene Kaufkraft im Ort binden, d.
        h. „neue“ Umsätze nur zu Lasten der bestehenden Standorte generieren.
        Das bedeutet, dass die bestehenden Versorgungsstrukturen durch
        Neuansiedlungen Kunden verlieren können.
      </p>

      <h4>Planungsrecht und Gutachten</h4>
      <p>
        Planungsrechtlich relevant sind Neuansiedlungen, wenn sie zu
        städtebaulichen Missständen führen. Das wird in der Regel im Rahmen der
        B-Plan-Aufstellung gutachterlich geprüft. Bewertungsmaßstäbe sind vor
        allem Umsatzumverteilungen für den Bestand, die aus der vorhandenen
        Kaufkraft, der Sogkraft des neuen Marktes und der bestehenden
        Wettbewerbssituation abgeleitet werden und im ungünstigen Fall zur
        Schließung bestehender Versorgungsstandorte führen können.
      </p>
      <p>
        Häufig sind die gutachterlichen Annahmen für die kommunalen
        Entscheidungsträger allerdings nicht nachprüfbar. Zudem können keine
        Standort- und Planungsalternativen, zum Beispiel eine andere
        Verkaufsfläche, geprüft werden.
      </p>

      <h4>Erste Grobeinschätzung per Karte</h4>
      <p>
        Für eine allererste Grobeinschätzung möglicher Konkurrenzsituationen
        zeigt die nachfolgende Analysekarte den von Ihnen eingetragenen
        potenziellen Einzelhandelsstandort im räumlichen Kontext der vorhandenen
        Läden und Supermärkte sowie der Einwohnerverteilung in einem
        100x100-Meter-Raster. Datenquellen sind OpenStreetMap sowie der
        bundesweite Zensus 2011.
      </p>

      <h4>Profi-Check</h4>
      <p>
        Für eine deutlich genauere Analyse möglicher Standortkonkurrenzeffekte
        im Bereich des Lebensmitteleinzelhandels steht Ihnen darüber hinaus das
        kostenfreie Profi-Check Add-In für ArcGIS zur Verfügung. Mit diesem
        können Sie Verdrängungsquoten für den Bestand berechnen. Diese können
        als Abwägungsmaterial für die Standort- und Planungsentscheidungen
        verwendet werden. Zudem lassen sich mit Profi-Check
        Ausstattungskennziffern wie die Verkaufsflächendichte und die
        Einzelhandelszentralität (Umsatz-Kaufkraft-Relation) ermitteln.
      </p>
    </div>
  )
};

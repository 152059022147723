import React from 'react';

import {useSelector} from 'react-redux';
import {municipalitySelector} from '../../../selectors/configuration-municipality';

import Inhabitants from './inhabitants';
import Workforce from './workforce';
import Area from './area';

const RecentDevelopment = (): JSX.Element | null => {
  const municipality = useSelector(municipalitySelector);

  if (!municipality || !municipality.data) {
    return null;
  }

  return (
    <>
      <Inhabitants municipality={municipality} />
      <Workforce municipality={municipality} />
      <Area municipality={municipality} />
    </>
  );
};

export default RecentDevelopment;

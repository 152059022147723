import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import {IYearValue} from '../interfaces/year-value';
import {IAreaUsageData} from '../interfaces/municipality-data';

/**
 * Get the extreme (min or max) value for the passed key from the data
 */
export function getExtremeBy(
  data: IYearValue[][],
  type: string,
  key: string
): IYearValue {
  const extremeBy = type === 'min' ? minBy : maxBy;
  const graphsMin: IYearValue[] = data.map(
    (year: IYearValue[]): IYearValue => extremeBy(year, key) || year[0]
  );

  return extremeBy(graphsMin, key) || graphsMin[0];
}

/**
 * Get the total of all the values
 */
export function getTotal(data: IAreaUsageData): number {
  let total = 0;

  Object.values(data).forEach((value: string) => {
    if (typeof value === 'number') {
      total += value;
    }
  });

  return total;
}

/**
 * Returns a function that defines a range between a given number can be.
 */
export const minMax = (
  min: number,
  max: number
): ((value: number) => number) => (value: number): number =>
  Math.min(Math.max(value, min), max);

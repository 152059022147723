import React from 'react';
import {useSelector} from 'react-redux';

import {IMenuItem} from '../../../constants/menu-items';
import MenuItem from './menu-item';
import {areaTypeIdSelector} from '../../../selectors/area';

interface IMenuItemsProps {
  items: IMenuItem[];
  menuItemSelected: (itemName: string) => void;
}

/**
 * Render a menu item
 */
const MenuItems = ({items, menuItemSelected}: IMenuItemsProps): JSX.Element => {
  const areaTypeId = useSelector(areaTypeIdSelector);

  const itemShouldBeHidden = (item, areaType): boolean =>
    item.hideForTypes && areaType && item.hideForTypes.includes(areaType);

  return (
    <>
      {items.map((item: IMenuItem): JSX.Element | null => {
        if (itemShouldBeHidden(item, areaTypeId)) {
          return null;
        }

        return (
          <MenuItem
            key={item.title}
            item={item}
            menuItemSelected={menuItemSelected}
          />
        );
      })}
    </>
  );
};

export default MenuItems;

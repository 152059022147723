import {IAction} from '../../interfaces/action';
import {IStore} from '../../interfaces/store';
import {IEcology, IEcologyLayer} from '../../interfaces/ecology';
import {Ecologies} from '../../constants/ecologies';
import {ViewIds} from '../../constants/views'; // eslint-disable-line no-unused-vars, max-len

import {
  FETCH_ECOLOGY_LAYER,
  FETCH_ECOLOGY_LAYER_SUCCESS,
  FETCH_ECOLOGY_LAYER_ERROR
} from '../../constants/actions';
import fetchApi from '../../apis/fetch-ecology-layer';

/**
 * Fetching the ecology layer
 */
export function fetchEcologyLayer(viewId: ViewIds): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER,
    viewId
  };
}

/**
 * Success while fetching the ecology layer
 */
export function fetchEcologyLayerSuccess(
  ecologyLayer: IEcologyLayer,
  viewId: ViewIds
): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER_SUCCESS,
    ecologyLayer,
    viewId
  };
}

/**
 * Error while fetching the ecology layer
 */
export function fetchEcologyLayerError(error: Error, viewId: ViewIds): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER_ERROR,
    error,
    viewId
  };
}

/**
 * Fetch the layer data of a ecology view
 */
export default function(
  viewId: ViewIds,
  fetchEcologyLayerRemotely: (
    ecology: IEcology
  ) => Promise<IEcologyLayer> = fetchApi
) {
  return (dispatch, getState: () => IStore) => {
    const state = getState();
    const ecology = Ecologies.get(viewId);
    const alreadyLoaded = ecology && state.ecologyLayers.get(viewId);

    if (!ecology || alreadyLoaded) {
      return null;
    }

    dispatch(fetchEcologyLayer(viewId));

    return fetchEcologyLayerRemotely(ecology)
      .then((ecologyLayer: IEcologyLayer) =>
        dispatch(fetchEcologyLayerSuccess(ecologyLayer, viewId))
      )
      .catch(error => dispatch(fetchEcologyLayerError(error, viewId)));
  };
}

import React from 'react';

import InhabitantsChart from '../../recent-development/inhabitants';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {IMunicipality} from '../../../interfaces/municipality';

import LegendColor from '../legend-color/legend-color';
import colors from '../../../constants/colors';

type IProps = {
  municipality: IMunicipality;
};

import styles from '../app/styles.styl';

const Inhabitants = ({municipality}: IProps): JSX.Element | null => {
  if (!municipality || !municipality.data) {
    return null;
  }

  const inhabitantsTableData: {
    year?: number;
    beforeValue?: number;
    afterValue?: number;
  }[] = [];

  municipality.data.inhabitants[0].forEach(item => {
    inhabitantsTableData.push({year: item.year, beforeValue: item.value});
  });
  municipality.data.inhabitants[1].forEach(item => {
    inhabitantsTableData.push({year: item.year, afterValue: item.value});
  });

  return (
    <>
      <PrintPage title={`Bisherige Entwicklung in ${municipality.name}`}>
        <PrintParagraph
          aside={
            <>
              <h3>Einwohner</h3>
              <p className={styles.source}>
                Quelle: Regionalstatistik der Statistischen Ämter des Bundes und
                der Länder
              </p>
            </>
          }
          main={<InhabitantsChart data={municipality.data} print />}
        />
      </PrintPage>
      <PrintPage title={`Bisherige Entwicklung in ${municipality.name}`}>
        <PrintParagraph
          aside={
            <p className={styles.source}>
              Quelle: Regionalstatistik der Statistischen Ämter des Bundes und
              der Länder
            </p>
          }
          main={
            <>
              <p>
                Einwohnerentwicklung {inhabitantsTableData[0].year} bis{' '}
                {inhabitantsTableData[inhabitantsTableData.length - 1].year}
              </p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <LegendColor color={colors.primaryColor} />
                      Einwohner
                      <br />
                      (vor Zensus-Bereinigung)
                    </th>
                    <th>
                      <LegendColor color={colors.lightBlue} />
                      Einwohner
                      <br />
                      (nach Zensus-Bereinigung)
                    </th>
                    <th>
                      Index
                      <br />
                      (2008 = 100)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {inhabitantsTableData.map((item, index) => (
                    <tr key={item.year}>
                      <td>{item.year}</td>
                      <td>{item.beforeValue?.toLocaleString('de')}</td>
                      <td>{item.afterValue?.toLocaleString('de')}</td>
                      <td>{100 + index}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          }
        />
      </PrintPage>
    </>
  );
};
export default Inhabitants;

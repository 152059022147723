import {IArea, IStore} from '../interfaces/store';
import {IAreaConfig} from '../constants/area-config';

import {AreaTypeIds} from '../constants/area-types';

import {Coordinate} from 'ol/coordinate';

export function areaSelector(state: IStore): IArea {
  return state.area;
}

export function areaConfigSelector(state: IStore): IAreaConfig | null {
  if (state.area.densityId) {
    return state.areaConfig;
  }
  return null;
}

export function areaCenterSelector(state: IStore): Coordinate | null {
  if (state.area.densityId) {
    return state.area.center;
  }
  return null;
}

export function areaTypeIdSelector(state: IStore): AreaTypeIds | null {
  return state.area.typeId;
}

/**
 * The total apartments for an residential area
 */
export function totalUnitsSelector(store: IStore): number {
  const {area} = store;
  const areaConfig = areaConfigSelector(store);

  if (
    !areaConfig ||
    !area.typeId ||
    !area.densityId ||
    !area.size ||
    area.typeId !== AreaTypeIds.Residential
  ) {
    return 0;
  }

  return Math.round(area.size * areaConfig.density);
}

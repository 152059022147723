import {IAction} from '../../interfaces/action';
// import {IRoute} from '../../interfaces/route';

import {
  REMOVE_TRAFFIC_ROUTE
} from '../../constants/actions';


/**
 * Add a route with the path and destination
 */
export function removeTrafficRoute(
  routes,
  route
): IAction {
  //routes.splice(routes.indexOf(route), 1); //this is altering state without state realizing it is changed?
  return {
    type: REMOVE_TRAFFIC_ROUTE,
    routes: routes,
    route: route
  };
}

/**
 * Add the route from origin to destination
 */
export default function(
  routes: IRoute[],
  route: IRoute
) {
  return dispatch => {
    return dispatch(removeTrafficRoute(
      routes, 
      route
      ));
  };
}

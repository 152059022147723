import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Button from '../shared/button/button';
import {importSettings} from '../../actions/settings/import-settings';
import styles from './welcome.styl';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Welcome = (): JSX.Element => {
  const { trackPageView, trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  trackEvent({ category: 'nonessential', action: 'welcome page' });

  return (
    <aside className={styles.container}>
      <div className={styles.content}>
        <div className={styles.image} />
        <div className={styles.text}>
          <p>
            Stehen in Ihrer Gemeinde Entscheidungen zu Flächenausweisungen für
            Wohnen, Gewerbe oder Einzelhandel an?
          </p>
          <p>
            Das Online-Werkzeug Web-Check hilft Ihnen, einen ersten Eindruck von
            möglichen Folgewirkungen zu bekommen.
          </p>
          <p>
            Wenn Sie Ihre Analyse anschließend weiter vertiefen möchten, steht
            Ihnen außerdem das Werkzeug{' '}
            <a
              href="https://www.projekt-check.de/profi-check"
              target="_blank"
              rel="noopener noreferrer">
              Profi-Check
            </a>{' '}
            auf unserer Internetseite zur Verfügung.
          </p>
          <p>
            <b>
              Klicken Sie auf „Neue Planung“, um eine eigene Flächenplanung
              vorzuprüfen.
            </b>
          </p>
          <p>
            Wenn Sie bereits eine Planung als Datei gespeichert haben oder eine
            Planungsdatei von einer anderen Person erhalten haben, können Sie
            diese mit „Importieren“ einlesen, ansehen und verändern.
          </p>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.buttonCursor}
          grow
          onClick={(): void => {
            navigate('/config/municipality', {replace: false});
          }}>
          Neue Planung
        </Button>
        <Button
          className={styles.buttonCursor}
          grow
          marginLeft
          onClick={(): void => {
            // dispatch(trackEvent({"action": "IMPORT_SETTINGS", "category": "ga_replacement", "name": "ga_replacement"}));
            trackEvent({"action": "IMPORT_SETTINGS", "category": "ga_replacement", "name": "ga_replacement"});
            dispatch(importSettings());
          }}>
          Importieren
        </Button>
      </div>
    </aside>
  );
};

export default Welcome;

import React from 'react';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded';

import styles from './selected-item-button.styl';
import MenuItemButton from '../menu-item-button/menu-item-button';

interface ISelectedItemButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onInfoClick: () => void;
  title?: string;
  isOpened: boolean;
}

const SelectedItemButton = ({
  onClick,
  onInfoClick,
  title,
  isOpened
}: ISelectedItemButtonProps): JSX.Element => {
  const selectedTitle: JSX.Element = (
    <div className={styles.arrowIcon}>
      {title}
      {isOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </div>
  );

  return (
    <div className={styles.container}>
      <MenuItemButton
        onClick={onClick}
        onInfoClick={onInfoClick}
        title={selectedTitle}
        isActive={true}
        hasInformation={true}
      />
    </div>
  );
};

export default SelectedItemButton;

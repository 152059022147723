import {IAction} from '../../interfaces/action';
import {
  FETCH_POIS,
  FETCH_POIS_SUCCESS,
  FETCH_POIS_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for the pois
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_POIS:
      return true;

    case FETCH_POIS_SUCCESS:
      return false;

    case FETCH_POIS_ERROR:
      return false;

    default:
      return state;
  }
}

import React from 'react';
import styles from './sidebar-footer.styl';

interface IProps {
  children: JSX.Element[] | JSX.Element;
}

const SidebarFooter = ({children}: IProps): JSX.Element => {
  return <div className={styles.container}>{children}</div>;
};

export default SidebarFooter;

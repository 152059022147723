import React, {useState, useEffect} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
// @ts-ignore
import mediaQueries from '../../../../styles/media-queries.json';

import {IFollowupCosts} from '../../../interfaces/followup-costs';
import {IGraphDatum} from '../../../interfaces/graph-datum';
import {AreaTypeIds} from '../../../constants/area-types';
import {NetworkElementGroupIds} from '../../../constants/network-elements';

import sizeme from 'react-sizeme';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint
} from 'react-vis';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '../../shared/icon';
import Loading from '../../shared/loading/loading';
import colors from '../../../constants/colors';

interface IProps {
  areaTypeId: AreaTypeIds | null;
  data: Map<NetworkElementGroupIds, IFollowupCosts> | null;
  compareCosts: number | null;
  totalApartments: number | null;
  totalWorkplaces: number | null;
  size: {width: number; height: number};
  print?: boolean;
}

/**
 * Chart for the followup costs comparison
 */

const Comparison = (props: IProps): JSX.Element | null => {
  const [graphs, setGraphs] = useState<IGraphDatum<number, string>[] | null>(
    null
  );

  const [hoveredValue, setHoveredValue] = useState<IGraphDatum<
    number,
    number
  > | null>(null);

  const [domain, setDomain] = useState<[number, number] | null>(null);

  const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });

  /**
   * When the series get’s hovered
   */
  const handleValueMouseOver = (
    newHoveredValue: IGraphDatum<number, number>
  ): void => {
    setHoveredValue(newHoveredValue);
  };

  /**
   * When the series get’s un-hovered
   */
  const handleValueMouseOut = (): void => {
    setHoveredValue(null);
  };

  const renderHint = (): JSX.Element | null => {
    if (!hoveredValue) {
      return null;
    }

    const x0 = hoveredValue.x0 || 0;
    const absolute = hoveredValue.x - x0;
    const roundedAbsolute = Math.round(absolute / 1000) * 1000;
    const value: IGraphDatum<number, number> = {
      y: hoveredValue.y,
      x: absolute / 2 + x0
    };

    return (
      <Hint
        value={value}
        align={{
          horizontal: Hint.ALIGN.RIGHT,
          vertical: Hint.ALIGN.TOP
        }}>
        <div className="visualization__hint visualization__hint--bar-chart">
          <span className="visualization__hint__value">
            {`${Number(roundedAbsolute).toLocaleString('de')}€`}
          </span>
        </div>
      </Hint>
    );
  };

  const calculateGraphData = (params: {
    data: Map<NetworkElementGroupIds, IFollowupCosts>;
    areaTypeId: AreaTypeIds;
    totalApartments: number;
    totalWorkplaces: number;
    compareCosts: number;
  }): IGraphDatum<number, string>[] => {
    const graphData: IGraphDatum<number, string>[] = [];
    let totalCosts = 0;

    params.data.forEach((costs: IFollowupCosts) => {
      costs.forEach(cost => {
        totalCosts += cost;
      });
    });

    if (params.areaTypeId === AreaTypeIds.Residential) {
      totalCosts = totalCosts / params.totalApartments;
    }

    if (params.areaTypeId === AreaTypeIds.Industrial) {
      totalCosts = totalCosts / params.totalWorkplaces;
    }

    graphData.push({
      x: params.compareCosts,
      y: 'Bundesschnitt (alle vier Netze)'
    });

    graphData.push({
      x: totalCosts,
      y: 'Plangebiet (alle vier Netze)'
    });

    return graphData;
  };

  const updateState = (): void | number[] => {
    const {
      data,
      areaTypeId,
      compareCosts,
      totalApartments,
      totalWorkplaces
    } = props;

    if (
      !data ||
      !areaTypeId ||
      !compareCosts ||
      (areaTypeId === AreaTypeIds.Industrial && !totalWorkplaces)
    ) {
      return;
    }

    const graphData = calculateGraphData({
        data,
        areaTypeId,
        totalApartments: totalApartments || 0,
        totalWorkplaces: totalWorkplaces || 0,
        compareCosts
      }),
      maxValue: number = Math.max(graphData[0].x, graphData[1].x),
      roundTo: number = maxValue > 10000 ? 10000 : 1000,
      minDomain = 0,
      maxDomain: number = Math.floor(maxValue / roundTo + 1) * roundTo;

    //update state
    setGraphs(graphData);
    setDomain([minDomain, maxDomain]);
  };

  useEffect(() => {
    updateState();
  }, []);

  if (!graphs || !graphs.length) {
    return <Loading />;
  }

  const isResidentialArea = props.areaTypeId === AreaTypeIds.Residential;

  const title = isResidentialArea
    ? 'Kosten der inneren Erschließung pro Wohneinheit'
    : 'Kosten der inneren Erschließung pro Arbeitsplatz';
  const tooltipText: string = isResidentialArea
    ? `Der Kennwert für das Plangebiet entspricht den in den beiden anderen
      Diagrammen dargestellten Gesamtkosten über alle vier Netze, geteilt
      durch die Zahl der Wohneinheiten. Der Bundesschnitt ist ein Schätzwert
      unter vergleichbaren Grundannahmen und bezieht sich auf den
      Wohnungsneubau in Deutschland im Zeitraum zwischen 2011 und 2018 in
      aktuellen Preisen.`
    : `Der Kennwert für das Plangebiet entspricht den in den beiden anderen
      Diagrammen dargestellten Gesamtkosten über alle vier Netze, geteilt
      durch die geschätzte Zahl der Arbeitsplätze (vgl. Analyse
      „Arbeitsplätze“). Der Bundesschnitt ist ein Schätzwert unter
      vergleichbaren Grundannahmen und bezieht sich auf die
      Gewerbeflächenentwicklung in Deutschland im Zeitraum zwischen 2011
      und 2018 in aktuellen Preisen.`;

  const marginXYPlot = isDesktop
    ? {left: 190, right: 40, top: 10, bottom: 90}
    : {left: 110, right: 20, top: 10, bottom: 90};

  return (
    <>
      {!props.print && (
        <p>
          Erschließungskosten im Vergleich zum Bundesschnitt
          <Tooltip
            title={tooltipText}
            arrow
            disableFocusListener
            disableTouchListener>
            <IconButton
              style={{
                right: 'initial',
                padding: '2px',
                boxShadow: 'none',
                width: 'auto'
              }}
              disableRipple
              size="small">
              <Icon type="info" />
            </IconButton>
          </Tooltip>
        </p>
      )}

      <div>
        <XYPlot
          width={props.print ? 700 : props.size.width}
          height={275}
          margin={marginXYPlot}
          yType="ordinal"
          xDomain={domain}>
          <VerticalGridLines />

          <HorizontalBarSeries
            data={graphs}
            color={colors.primaryColor}
            onValueMouseOver={handleValueMouseOver}
            onMouseLeave={handleValueMouseOut}
          />

          <XAxis
            tickTotal={5}
            tickFormat={(tick): string =>
              `${Number(tick).toLocaleString('de')}€`
            }
          />

          <span className="visualization--x-axis-label">{title}</span>

          <YAxis className="visualization__axis" />

          {renderHint()}
        </XYPlot>
      </div>
    </>
  );
};

export default sizeme()(Comparison);

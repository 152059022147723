import {IAction} from '../../interfaces/action';
import {FETCH_POPULATION_STRUCTURE_SUCCESS} from '../../constants/actions';

/**
 * Initial state
 */
const initialState: any = null;

/**
 * Handle the population development data
 */
export default function(state: any = initialState, action: IAction): any {
  switch (action.type) {
    case FETCH_POPULATION_STRUCTURE_SUCCESS:
      return action.populationStructure || state;

    default:
      return state;
  }
}

import {AreaTypeIds} from '../../constants/area-types';
import React from 'react';

import styles from './legend.styl';
import clsx from 'clsx';

import {
  poiCategories as allPoiCategories,
  IPoiCategory
} from '../../constants/pois';

interface IProps {
  readonly areaTypeId: AreaTypeIds | null;
  readonly className: string;
  readonly poiCategories?: IPoiCategory[];
}

/**
 * The pois legend
 */
export default class PoisLegend extends React.Component<IProps> {
  /**
   * Render the POI categories for the passed categories
   */
  renderPoiCategories(categories: IPoiCategory[]): (JSX.Element | null)[] {
    const {areaTypeId} = this.props;

    return categories.map(poiCategory => {
      if (!areaTypeId || !poiCategory.areaTypes.includes(areaTypeId)) {
        return null;
      }

      const legendIconClass = clsx(
        styles.legendIcon,
        poiCategory.iconSize === 'small' && styles.legendIconSmall
      );

      return (
        <span className={styles.legendEntry} key={poiCategory.key}>
          <dt className={legendIconClass}>
            <img src={`./images/poi-${poiCategory.iconName}.png`} alt="" />
          </dt>
          <dd className={styles.legendDescription}>{poiCategory.name}</dd>
        </span>
      );
    });
  }

  /**
   * Render the Component
   */
  render(): JSX.Element {
    const {areaTypeId, className} = this.props;
    const poiCategories: IPoiCategory[] = this.props.poiCategories
      ? this.props.poiCategories
      : allPoiCategories;
    const isResidentialArea = areaTypeId === AreaTypeIds.Residential;
    const largePoiIconCategories = poiCategories.filter(
      poiCategory => poiCategory.iconSize === 'large'
    );
    const smallPoiIconCategories = poiCategories.filter(
      poiCategory => poiCategory.iconSize === 'small'
    );

    const containerClass = clsx(styles.legend, className);

    return (
      <>
        {isResidentialArea && (
          <dl className={containerClass}>
            {this.renderPoiCategories(largePoiIconCategories)}
          </dl>
        )}
        <dl>
          {isResidentialArea &&
            this.renderPoiCategories(smallPoiIconCategories)}

          {!isResidentialArea && this.renderPoiCategories(poiCategories)}

          {areaTypeId === AreaTypeIds.Retail && (
            <span className={styles.legendEntry}>
              <dt className={styles.legendColor}>
                <svg
                  viewBox="0 0 14 14"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect fill="#CB7E7E" x="0" y="0" width="14" height="14" />
                </svg>
              </dt>
              <dd className={styles.legendDescription}>
                Einwohnerdichte (Farbskala, dunkel: hohe Dichte)
              </dd>
            </span>
          )}
        </dl>
      </>
    );
  }
}

import React from 'react';
import styles from './styles.styl';

const LegendColor = ({color}: {color?: string}): JSX.Element => (
  <svg
    className={styles.container}
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <rect fill={color} x="0" y="0" width="14" height="14" />
  </svg>
);

export default LegendColor;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';;
// @ts-ignore
import mediaQueries from '../../../../styles/media-queries.json';

import {IStore} from '../../../interfaces/store';
import {IMenuItem} from '../../../constants/menu-items';
import selectView from '../../../actions/select-view';
import {ViewIds} from '../../../constants/views';
import MenuItemButton from '../menu-item-button/menu-item-button';

import styles from './menu-item.styl';

interface IMenuItemProps {
  item: IMenuItem;
  menuItemSelected: (itemName: string) => void;
}

/**
 * Render a menu item
 */
const MenuItem = ({item, menuItemSelected}: IMenuItemProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { trackEvent } = useMatomo();

  const view = useSelector((state: IStore) => state.view);

  const isActive = item.viewId === view;
  const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });
  const classes = clsx(styles.container, isActive && styles.active);

  const clickHandler = (): void => {
    if (item.title) {
      menuItemSelected(item.title);
    }
    dispatch(selectView(item.viewId || ViewIds.None, isDesktop));
    let target = `/views/${item.viewId}${item.hasChart ? '/charts' : '/'}`;
    if (item.viewId === ViewIds.MunicipalIncome) {
      target = `/views/${item.viewId}/info`;
    }
  
    // dispatch(trackEvent({"action": 'SELECT_VIEW', "name": item.title,  "category": "ga_replacement"}));
    trackEvent({"action": 'SELECT_VIEW', "name": item.title,  "category": "ga_replacement"});
    
    navigate(target);
  };

  return (
    <div className={classes} key={item.title}>
      <MenuItemButton
        isActive={isActive}
        title={item.title}
        onClick={clickHandler}
        hasInformation={item.hasInformation && isDesktop}
        viewId={item.viewId}
      />
    </div>
  );
};

export default MenuItem;

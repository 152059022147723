import {AreaTypeIds} from './area-types';

/**
 * The available poi categories
 */
export const enum PoiCategoryIds {
  Kindergarten = 1,
  School,
  Shop,
  Supermarket,
  Leisure,
  Service,
  PublicTransport,
  Highway,
  Health
}

/**
 * The poi category type
 */
export interface IPoiCategory {
  id: PoiCategoryIds;
  iconName: string;
  iconSize: 'small' | 'large';
  name: string;
  key: string;
  areaTypes: AreaTypeIds[];
}

/**
 * The available poi categories
 */
export const poiCategories: IPoiCategory[] = [
  {
    id: PoiCategoryIds.Kindergarten,
    iconName: 'kindergarten',
    iconSize: 'large',
    name: 'Kitas',
    key: 'Kita',
    areaTypes: [AreaTypeIds.Residential, AreaTypeIds.Industrial]
  },
  {
    id: PoiCategoryIds.School,
    iconName: 'school',
    iconSize: 'large',
    name: 'Schulen',
    key: 'Schule',
    areaTypes: [AreaTypeIds.Residential]
  },
  {
    id: PoiCategoryIds.Health,
    iconName: 'health',
    iconSize: 'large',
    name: 'Gesundheit',
    key: 'Ärzte',
    areaTypes: [AreaTypeIds.Residential, AreaTypeIds.Retail]
  },
  {
    id: PoiCategoryIds.Supermarket,
    iconName: 'supermarket',
    iconSize: 'large',
    name: 'Supermärkte',
    key: 'Supermarkt/Einkaufszentrum',
    areaTypes: [AreaTypeIds.Residential, AreaTypeIds.Retail]
  },
  {
    id: PoiCategoryIds.PublicTransport,
    iconName: 'public-transport',
    iconSize: 'large',
    name: 'Haltestellen Bus & Bahn',
    key: 'ÖPNV_Haltestelle',
    areaTypes: [
      AreaTypeIds.Residential,
      AreaTypeIds.Industrial,
      AreaTypeIds.Retail
    ]
  },
  {
    id: PoiCategoryIds.Shop,
    iconName: 'shop',
    iconSize: 'small',
    name: 'Weitere Läden',
    key: 'Läden',
    areaTypes: [AreaTypeIds.Residential, AreaTypeIds.Retail]
  },
  {
    id: PoiCategoryIds.Service,
    iconName: 'service',
    iconSize: 'small',
    name: 'Dienstleistungen',
    key: 'Dienstleistungen',
    areaTypes: [AreaTypeIds.Residential]
  },
  {
    id: PoiCategoryIds.Leisure,
    iconName: 'leisure',
    iconSize: 'small',
    name: 'Freizeit und Gastronomie',
    key: 'Freizeit',
    areaTypes: [AreaTypeIds.Residential]
  },
  {
    id: PoiCategoryIds.Highway,
    iconName: 'highway',
    iconSize: 'large',
    name: 'Autobahnanschlussstellen',
    key: 'Autobahnanschlussstelle',
    areaTypes: [AreaTypeIds.Industrial, AreaTypeIds.Retail]
  }
];

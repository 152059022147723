import {IAction} from '../../interfaces/action';
import {AreaTypeIds} from '../../constants/area-types'; // eslint-disable-line no-unused-vars, max-len
import {SELECT_AREA_TYPE_ID} from '../../constants/actions';

/**
 * Select an area type id
 */
export default function(areaTypeId: AreaTypeIds): IAction {
  return {
    type: SELECT_AREA_TYPE_ID,
    areaTypeId
  };
}

import React from 'react';
import styles from './header.styl';
import {IHeaderProps} from './header-container';
import {useNavigate} from 'react-router-dom';
import Button from '../shared/button/button';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const HeaderScreen = (props: IHeaderProps): JSX.Element => {
  const {name, size, influx, config, type} = props;
  const navigate = useNavigate();
  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{name}</div>

        <div className={styles.data}>
          <div className={styles.entry}>
            {size}
            {type && ' - '}
          </div>
          {type && (
            <div className={styles.entry}>
              {type.name}
              {config && ' - '}
            </div>
          )}
          {config && (
            <div className={styles.entry}>
              {config.shortName}
              {influx && ' - '}
            </div>
          )}
          {influx && <div className={styles.entry}>{influx}</div>}
        </div>
      </div>

      <Button
        variant="icon"
        title="Planung nochmal verändern"
        onClick={(): void => {
          navigate('/config/municipality');
        }}>
        <EditRoundedIcon className={styles.icon} />
      </Button>
    </header>
  );
};

export default HeaderScreen;

import React from 'react';

import colors from '../../constants/colors';
import Legend from '../shared/legend';

const isochroneLegendEntries = [
  {
    color: colors.lightRed,
    description: 'zu Fuß'
  },
  {
    color: colors.darkGreen,
    description: 'mit dem Fahrrad'
  },
  {
    color: colors.petrolBlue,
    description: 'mit dem Auto'
  }
];

/**
 * The isochrones legend
 */
const IsochronesLegend = (): JSX.Element | null => {
  return <Legend entries={isochroneLegendEntries} />;
};

export default IsochronesLegend;

import React from 'react';
import {useSelector} from 'react-redux';

import PopulationEstimation from '../../chart-views/population-development/population-estimation';

import {
  totalUnitsSelector,
  areaConfigSelector,
  areaSelector
} from '../../../selectors/area';
import {municipalitySelector} from '../../../selectors/configuration-municipality';

import {areaConfigsById} from '../../../constants/area-config';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';
import {populationStructureSelector} from '../../../selectors/population-structure';
import {colorMap} from '../../../constants/population-development';
import LegendColor from '../legend-color/legend-color';

import styles from './styles.styl';
import {IStore} from '../../../interfaces/store';

const Population = (): JSX.Element | null => {
  const areaConfig = useSelector(areaConfigSelector);
  const area = useSelector(areaSelector);
  const municipality = useSelector(municipalitySelector);
  const data = useSelector((store: IStore) =>
    populationStructureSelector(store, {
      startYear: areaConfig?.startYear,
      efhSize: areaConfig?.efhSize,
      mfhSize: areaConfig?.mfhSize,
      efhUnderAgeProportion: areaConfig?.efhUnderAgeProportion,
      mfhUnderAgeProportion: areaConfig?.mfhUnderAgeProportion,
      efhProportion: areaConfig?.efhProportion
    })
  );

  if (!areaConfig || !data) {
    return null;
  }

  const areaDefaults = areaConfigsById[area.densityId || 0];
  const {startYear = 0} = areaConfig;

  // @ts-ignore A partial store is ok at this point.
  const totalUnits = totalUnitsSelector({areaConfig, area, municipality});

  const tableData: {
    [k: number]: {[k: number]: number};
  } = {};

  Array.from(data.entries()).forEach(([key, values]) => {
    values.forEach(({year, value}) => {
      tableData[year] = {...tableData[year], ...{[key]: value}};
    });
  });

  return (
    <>
      <PrintPage title="Bewohner/innen">
        <PrintParagraph
          aside={<h3>Schätzung von Zahl und Altersstruktur</h3>}
          main={
            <PopulationEstimation
              startYear={areaConfig.startYear}
              efhSize={areaConfig.efhSize}
              mfhSize={areaConfig.mfhSize}
              efhUnderAgeProportion={areaConfig.efhUnderAgeProportion}
              mfhUnderAgeProportion={areaConfig.mfhUnderAgeProportion}
              efhProportion={areaConfig.efhProportion}
              print
            />
          }
        />
        <PrintParagraph
          aside={
            <>
              <h3>Annahmen</h3>
              <p>
                Werte in Klammern zeigen nachrichtlich die
                Web-Check-Vorschlagswerte für das Plangebiet. Die im
                vorstehenden Diagramm gezeigte Schätzung basiert auf den nicht
                eingeklammerten Werten.
              </p>
            </>
          }
          main={
            <>
              <p style={{fontWeight: 'normal', fontSize: '16px'}}>
                Die vorstehende Schätzung geht von den folgenden Annahmen aus:
              </p>
              <ul>
                <li>
                  {area.size?.toLocaleString('de')} ha Bruttowohnbauland x{' '}
                  {areaConfig.density} Wohneinheiten / ha (brutto) ={' '}
                  {totalUnits} Wohneinheiten im Plangebiet, davon
                  <ul>
                    <li>
                      {areaDefaults.efhProportion * totalUnits} (
                      {areaConfig.efhProportion * totalUnits}) in Ein- und
                      Zweifamilien- sowie Reihenhäusern
                    </li>
                    <li>
                      {Math.round(
                        (1 - areaDefaults.efhProportion) * totalUnits
                      )}{' '}
                      ({Math.round((1 - areaConfig.efhProportion) * totalUnits)}
                      ) in Mehrfamilienhäusern
                    </li>
                  </ul>
                </li>
                <li>
                  Dauer des Erstbezugs: {areaConfig.startYear} -{' '}
                  {startYear + Number(area.influx)}
                </li>
                <li>
                  Mittlere Haushaltsgröße
                  <ul>
                    <li>
                      {areaDefaults.efhSize.toLocaleString('de')} (
                      {areaConfig.efhSize.toLocaleString('de')}) Personen pro
                      Haushalt in Ein- und Zweifamilien- sowie Reihenhäusern
                    </li>
                    <li>
                      {areaDefaults.mfhSize.toLocaleString('de')} (
                      {areaConfig.mfhSize.toLocaleString('de')}) Personen pro
                      Haushalt in Mehrfamilienhäusern
                    </li>
                  </ul>
                </li>
                <li>
                  Anteil Kinder (Bewohner/innen unter 18 Jahre) an allen
                  Bewohner/innen
                  <ul>
                    <li>
                      {areaDefaults.efhUnderAgeProportion.toLocaleString('de')}{' '}
                      ({areaConfig.efhUnderAgeProportion.toLocaleString('de')})
                      in Ein- und Zweifamilien- sowie Reihenhäusern
                    </li>
                    <li>
                      {areaDefaults.mfhUnderAgeProportion.toLocaleString('de')}{' '}
                      ({areaConfig.mfhUnderAgeProportion.toLocaleString('de')})
                      in Mehrfamilienhäusern
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          }
        />
      </PrintPage>
      <PrintPage title="Bewohner/innen">
        <PrintParagraph
          main={
            <table className={styles.table}>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <LegendColor color={colorMap.get(1)} />
                    unter 18
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(2)} />
                    18 bis 29
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(3)} />
                    30 bis 44
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(4)} />
                    45 bis 64
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(5)} />
                    65 bis 80
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(6)} />
                    über 80
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(tableData)
                  .slice(0, 12)
                  .map(([year, values]) => (
                    <tr key={year}>
                      <td>{year}</td>
                      <td>{Math.round(values[1]).toLocaleString('de')}</td>
                      <td>{Math.round(values[2]).toLocaleString('de')}</td>
                      <td>{Math.round(values[3]).toLocaleString('de')}</td>
                      <td>{Math.round(values[4]).toLocaleString('de')}</td>
                      <td>{Math.round(values[5]).toLocaleString('de')}</td>
                      <td>{Math.round(values[6]).toLocaleString('de')}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          }
        />
      </PrintPage>
      <PrintPage title="Bewohner/innen">
        <PrintParagraph
          main={
            <table className={styles.table}>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <LegendColor color={colorMap.get(1)} />
                    unter 18
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(2)} />
                    18 bis 29
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(3)} />
                    30 bis 44
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(4)} />
                    45 bis 64
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(5)} />
                    65 bis 80
                  </th>
                  <th>
                    <LegendColor color={colorMap.get(6)} />
                    über 80
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(tableData)
                  .slice(12)
                  .map(([year, values]) => (
                    <tr key={year}>
                      <td>{year}</td>
                      <td>{Math.round(values[1]).toLocaleString('de')}</td>
                      <td>{Math.round(values[2]).toLocaleString('de')}</td>
                      <td>{Math.round(values[3]).toLocaleString('de')}</td>
                      <td>{Math.round(values[4]).toLocaleString('de')}</td>
                      <td>{Math.round(values[5]).toLocaleString('de')}</td>
                      <td>{Math.round(values[6]).toLocaleString('de')}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          }
        />
      </PrintPage>
    </>
  );
};

export default Population;

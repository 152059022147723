import {IAction} from '../interfaces/action';
import {IFollowupCostsData} from '../interfaces/followup-costs-data';

import {
  FETCH_FOLLOWUP_COSTS_DATA,
  FETCH_FOLLOWUP_COSTS_DATA_SUCCESS,
  FETCH_FOLLOWUP_COSTS_DATA_ERROR
} from '../constants/actions';
import fetchApi from '../apis/fetch-followup-costs-data';

/**
 * Fetching the followup costs data
 */
export function fetchFollowupCostsData(): IAction {
  return {
    type: FETCH_FOLLOWUP_COSTS_DATA
  };
}

/**
 * Success while fetching the followup costs data
 */
export function fetchFollowupCostsDataSuccess(
  followupCostsData: IFollowupCostsData
): IAction {
  return {
    type: FETCH_FOLLOWUP_COSTS_DATA_SUCCESS,
    followupCostsData
  };
}

/**
 * Error while fetching the followup costs data
 */
export function fetchFollowupCostsDataError(error: Error): IAction {
  return {
    type: FETCH_FOLLOWUP_COSTS_DATA_ERROR,
    error
  };
}

/**
 * Fetch the followup costs data
 */
export default function(
  fetchFollowupCostsDataRemotely: () => Promise<IFollowupCostsData> = fetchApi
) {
  return dispatch => {
    dispatch(fetchFollowupCostsData());

    return fetchFollowupCostsDataRemotely()
      .then(followupCostsData =>
        dispatch(fetchFollowupCostsDataSuccess(followupCostsData))
      )
      .catch(error => dispatch(fetchFollowupCostsDataError(error)));
  };
}

import {IStore} from '../interfaces/store';

/**
 * Whether there is still some data for the print view loading
 */
export default function(state: IStore): boolean {
  const {isLoading} = state;
  const ecologyLayerEntries = Array.from(isLoading.ecologyLayers.values());
  const entries = Object.keys(isLoading).map(key => isLoading[key]);

  return entries.concat(ecologyLayerEntries).some(entry => entry === true);
}

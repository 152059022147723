import {ViewIds} from './views';
import React from 'react';

const profiHintFooter = (
  <div>
    <br />
    Für die Nutzung von Profi-Check brauchen Sie das GIS-Programm ArcGIS der
    Firma ESRI oder die Open-Source-Software QGIS. Sie können Profi-Check{' '}
    <a
      href="https://www.projekt-check.de/profi-check"
      target="_blank"
      rel="noopener noreferrer">
      hier
    </a>{' '}
    kostenfrei herunterladen.
  </div>
);

/**
 * Mapping of views to the contents
 */
export const profiHintContent = {
  [`View${ViewIds.PopulationDevelopment}`]: {
    title: 'Annahmen ändern',
    content: (
      <div>
        Über die beiden Reiter „Annahme Bebauung“ und „Annahme Haushalte“ der
        Analyseansicht haben Sie die Möglichkeit, die Annahmen für die
        Abschätzung der Bewohnerentwicklung zu verändern und sie so ggf. noch
        besser auf ihre lokale Situation anzupassen.
      </div>
    )
  },
  [`View${ViewIds.WorkplaceDevelopment}`]: {
    title: 'Annahmen ändern',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Projekt-Check haben Sie die
        Möglichkeit, die Annahmen für die Abschätzung der Anzahl und der
        Zusammensetzung der Arbeitsplatz zu verändern und sie so ggf. noch
        besser auf ihre lokale Situation anzupassen.
        {profiHintFooter}
      </div>
    )
  },
  [`View${ViewIds.TrafficReachability}`]: {
    title: 'Andere Wegzeiten',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Projekt-Check können Sie die
        Erreichbarkeitsanalyse noch verfeinern. So haben Sie dort z.B. die
        Möglichkeit, andere Wegzeiten als 10 min zu wählen. Zudem können Sie in
        Projekt-Check Profi die erzeugten Isochronen als Shape-File abspeichern
        und so in eigene Darstellungen einbinden oder für weitergehende Analysen
        nutzen.
        {profiHintFooter}
      </div>
    )
  },
  [`View${ViewIds.TrafficEnvironment}`]: {
    title: 'Weitere Analysen',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Projekt-Check haben Sie die
        Möglichkeit, die ermittelten Fahrtwege als Shape-File abzuspeichern.
        Damit können sie diese in eigene GIS-Darstellungen einbinden oder sie
        für weitergehende Analysen nutzen.
        {profiHintFooter}
      </div>
    )
  },
  [`View${ViewIds.FollowupCosts}`]: {
    title: 'Annahmen ändern',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Profi-Check stehen Ihnen eine Reihe
        weiterer Auswertungsmöglichkeiten zur Verfügung. So können Sie dort z.B.
        die der Folgekostenermittlung zugrunde liegenden Schätzungen der
        benötigten Infrastrukturmengen kontrollieren. Zudem können Sie die
        verwendeten Kostensätze und Kostenaufteilungen verändern.
        {profiHintFooter}
      </div>
    )
  },
  [`View${ViewIds.MunicipalIncome}`]: {
    title: 'Nur in Profi-Check',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Profi-Check können Sie eine
        Abschätzung vornehmen, in welchem Umfang es aufgrund der Planung zu
        einer Verschiebung von Steuereinnahmen zwischen den Kommunen kommt.
        {profiHintFooter}
      </div>
    )
  },
  [`View${ViewIds.LocationCompetition}`]: {
    title: 'Nur in Profi-Check',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Tool Profi-Check haben Sie die
        Möglichkeit abzuschätzen, welche Auswirkungen der von Ihnen definierte
        neue Einzelhandelsstandort auf die Kaufkraftströme zu den bestehenden
        Märkten hat.
        {profiHintFooter}
      </div>
    )
  },
  Ecology: {
    title: 'Mehr Karten',
    content: (
      <div>
        Das kostenfrei verfügbare Tool Profi-Check gibt Ihnen die Möglichkeit,
        weitere Hintergrundkarten zur ökologischen Sensibilität des Umfeldes
        einzublenden. Zudem können Sie weitergehende Analysen in den Bereichen
        Ökologie und Flächenverbrauch durchführen.
        {profiHintFooter}
      </div>
    )
  },
  Mobile: {
    title: 'Nur in Profi-Check',
    content: (
      <div>
        Mit dem kostenfrei verfügbaren Desktop-Tool Profi-Check können Sie Ihre
        Planung noch detaillierter definieren und analysieren. Dazu bieten Ihnen
        Profi-Check u.a. die folgenden Zusatzoptionen:
        <ul style={{paddingLeft: '1.2em', marginBlockEnd: '0'}}>
          <li>
            Projektdefinition: Detailliertere Definition der Bebauungsstruktur
            bei Wohngebieten sowie Möglichkeit zur Anpassung der
            Branchenstruktur bei Gewerbegebieten Erreichbarkeit: Erzeugung von
            Erreichbarkeitsbereichen („Isochronen“) im Projektumfeld auch mit
            anderen Wegzeiten als 10 Minuten Fuß-, Rad- oder Kfz-Weg sowie
            Möglichkeit zum Exportieren der erzeugten Isochronen, z.B. als
            Shape-File. Zusätzliche Möglichkeit zur Analyse der
            ÖPNV-Erreichbarkeit anhand von Fahrplandaten.
          </li>
          <li>
            Verkehr im Umfeld: Möglichkeit zum Exportieren der erzeugten
            Fahrtwege, z.B. als Shape-File, sowie Schätzung der zusätzlichen
            Straßenverkehrsbelastung durch das Projekt Landschafts- und
            Naturschutz: Weitere Hintergrundkarten (z.B. zu unzerschnittenen
            Naturräumen) sowie Abschätzungen zur Flächenneuinanspruchnahme und
            zur Bodenbeeinträchtigung
          </li>
          <li>
            Folgekosten: Möglichkeit zur Kontrolle der den Kostenschätzungen
            zugrundeliegenden Mengenschätzungen (Bsp.: Länge der
            Erschließungsstraßen) sowie zur Veränderung der Kostensätze und
            Kostenaufteilungen
          </li>
          <li>
            Kommunale Einnahmen: Möglichkeit zur Abschätzung der regionalen
            Verschiebung kommunaler Steuereinnahmen durch das Projekt
          </li>
          <li>
            Standortkonkurrenz (Einzelhandel): Analyse der Auswirkungen eines
            neuen Einzelhandelsstandortes auf die Kaufkraftströme zu den
            bestehenden Märkten.
          </li>
        </ul>
        {profiHintFooter}
      </div>
    )
  }
};

import React from 'react';

import styles from './information-overlay.styl';

interface IProps {
  infoContent: JSX.Element | string;
}

const InfoOverlay = ({infoContent}: IProps): JSX.Element => {
  return <div className={styles.container}>{infoContent}</div>;
};

export default InfoOverlay;

import React, {useEffect, useState} from 'react'; // eslint-disable-line no-unused-vars, max-len

import {ViewIds} from '../../constants/views';
import {BaselayerVG, VGIds} from '../../constants/baselayer';

import {GeoJSON} from 'ol/format';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector'
import {Group as LayerGroup, Tile as TileLayer, Vector as VectorLayer, Layer} from 'ol/layer';
import {bbox as bboxStrategy, tile as tileStrategy} from 'ol/loadingstrategy';
import {Stroke, Style} from 'ol/style';

interface IProps {
  view: ViewIds;
  zoom: number | null;
  mapCanvas: ol.Map | null;
}

/**
 * A municipality WMS overlay over the map
 */
const MunicipalitiesOverlay = ({
  zoom,
  mapCanvas
}: IProps): JSX.Element | null => {
  /**
   * The actual overlay
   */
  const [overlay, setOverlay] = useState<Layer | null>(null);
  
  
  
  const createOverlay = (map: ol.Map): void => {
      if (!overlay) {
        const vg250wfssource = new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
                const url = (
                    BaselayerVG.get(VGIds.vg250wfs).data.url + 
                    '?service=WFS&version=1.1.0&request=GetFeature&typename=' + 
                    BaselayerVG.get(VGIds.vg250wfs).data.layers[0].name + 
                    '&outputFormat=application/json&srsName=EPSG:3857&MAXFEATURES=10000&' +
                    'bbox=' + 
                    extent.join(',') //+
//                     ',EPSG:3857'
                );
                return url;
            },
            strategy: bboxStrategy
        });
        const vg250wfslayer = new VectorLayer({
            source: vg250wfssource,
            minZoom: 12,
            crossOrigin: 'anonymous',
            style: new Style({
                stroke: new Stroke({
                  color: 'rgba(0, 255, 255, 1.0)',
                  width: 2,
                }),
            }),
            title: 'Gemeindegrenzen (WFS)',
            type: 'data'
        });

        
        const vg250source = new TileWMS({
            url: BaselayerVG.get(VGIds.vg250).data.url + '?', 
            params: {'LAYERS': BaselayerVG.get(VGIds.vg250).data.layers.id,  'TILED': true}
        });
        const vg250layer = new TileLayer({
            source: vg250source,
            maxZoom: 11,
            crossOrigin: 'anonymous',
            title: 'Gemeindegrenzen (WMS)',
            type: 'data'
        })
        
        
        const currentOverlay = new LayerGroup({
            layers: [
                vg250layer,
                vg250wfslayer
            ],
            title: 'Gemeindegrenzen',
            type: 'group'
        });
//         const currentOverlay = new TileLayer({
//             source: new TileWMS({
//                 url: 'https://sgx.geodatenzentrum.de/wms_vg250?', 
//                 params: {'LAYERS': 'vg250_li', 'TILED': true}
//             })
//         });
        map.addLayer(currentOverlay);
        setOverlay(currentOverlay);
    }
  };

  const destroyOverlay = (map: ol.Map): void => {
    if (overlay) {
      map.removeLayer(overlay)
      setOverlay(null);
    }
  };

  useEffect(() => {
    if (mapCanvas && mapCanvas.getView().getZoom() >= 6) {
      createOverlay(mapCanvas);
    }

    return (): void => {
      if (mapCanvas) {
        destroyOverlay(mapCanvas);
      }
    };
  }, [mapCanvas]);

  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    if (!zoom || zoom < 6) {
      destroyOverlay(mapCanvas);
      return;
    }

    createOverlay(mapCanvas);
  }, [zoom]);

  return null;
};

export default MunicipalitiesOverlay;
import {IStore} from '../interfaces/store';
import {Ecologies} from '../constants/ecologies';

interface IEcologyOverlayData {
  [_: string]: string[];
}
/**
 * Whether the active view is an ecology view or not
 */
export default function(state: IStore): IEcologyOverlayData | null {
  if (
    state.ecologyLayersInformation &&
    state.ecologyLayersInformation.features.length > 0
  ) {
    const ecologyOverlayData = state.ecologyLayersInformation.features.reduce(
      (data, feature): IEcologyOverlayData => {
        const ecology = Ecologies.get(state.view);
        const ecologyLayer =
          ecology &&
          ecology.data.layers.find(
            // @ts-ignore feature.id is always there and always a string. In this case at least
            ({id}): boolean => feature.id.startsWith(id)
          );

        if (ecologyLayer) {
          const layerName = ecologyLayer.name;

          if (!data[layerName]) {
            data[layerName] = [];
          }
          
          data[layerName].push(feature.properties.NAME);
        }

        return data;
      },
      {}
    );

    return ecologyOverlayData;
  }
  return null;
}

import {IStore} from '../../interfaces/store';
import {saveAs} from 'file-saver';
import {Dispatch} from 'redux';
import {DOWNLOAD} from '../../constants/actions';

/**
 * Save the settings
 */
export const downloadSettings = (): ((
  dispatch: Dispatch,
  getState: () => IStore
) => void) => {

  const slimTraffic = (routes) => {
    for (const route of routes) {
      for (const pathId in route.path) {
        // GeoJSON.writeFeatureObject(
        const lats = route.path[pathId].values_.geometry.flatCoordinates.filter((v, i, ar) => (i % 2 == 0));
        const lons = route.path[pathId].values_.geometry.flatCoordinates.filter((v, i, ar) => (i % 2 == 1));
        const newCoords = [];
        for (const i in lats) {
          newCoords.push([lats[i],lons[i]]);
        }
        route.path[pathId] = newCoords;
      }
    }
    return routes;
  }
  
  return (dispatch, getState): void => {
    dispatch({
      type: DOWNLOAD
    });
    const state: IStore = getState();
    const {municipality, area, map, areaConfig} = state;
    let {traffic} = state;
    traffic.routes = slimTraffic(traffic.routes);
    const file = new Blob(
      [JSON.stringify({municipality, area, map, areaConfig, traffic}, null, 2)],
      {type: 'text/plain;charset=utf-8'}
    );

    saveAs(file, `projekt-check-${area.name}.json`);
  };
};

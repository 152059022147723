import {IAction} from '../../interfaces/action';
import {
  FETCH_POPULATION_STRUCTURE,
  FETCH_POPULATION_STRUCTURE_SUCCESS,
  FETCH_POPULATION_STRUCTURE_ERROR
} from '../../constants/actions';

/**
 * Handle the loading state for the population development
 */
export default function(state = false, action: IAction): boolean {
  switch (action.type) {
    case FETCH_POPULATION_STRUCTURE:
      return true;

    case FETCH_POPULATION_STRUCTURE_SUCCESS:
      return false;

    case FETCH_POPULATION_STRUCTURE_ERROR:
      return false;

    default:
      return state;
  }
}

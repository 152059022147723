import React from 'react';

import Employed from '../../recent-development/employed';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {IMunicipality} from '../../../interfaces/municipality';

import LegendColor from '../legend-color/legend-color';
import colors from '../../../constants/colors';

type IProps = {
  municipality: IMunicipality;
};

import styles from '../app/styles.styl';

const Workforce = ({municipality}: IProps): JSX.Element | null => {
  if (!municipality || !municipality.data) {
    return null;
  }
  const employmentsTableData: {
    year?: number;
    inbound?: number;
    outbound?: number;
    inner?: number;
  }[] = [];

  municipality.data.employed.inbound.forEach(item => {
    employmentsTableData.push({year: item.year, inbound: item.value});
  });
  municipality.data.employed.outbound.forEach((item, index) => {
    employmentsTableData[index] = {
      ...employmentsTableData[index],
      year: item.year,
      outbound: item.value
    };
  });
  municipality.data.employed.inner.forEach((item, index) => {
    employmentsTableData[index] = {
      ...employmentsTableData[index],
      year: item.year,
      inner: item.value
    };
  });

  return (
    <>
      <PrintPage title={`Bisherige Entwicklung in ${municipality.name}`}>
        <PrintParagraph
          aside={
            <>
              <h3>Beschäftigte</h3>
              <p className={styles.source}>Quelle: Bundesagentur für Arbeit</p>
            </>
          }
          main={<Employed data={municipality.data} print />}
        />
      </PrintPage>
      <PrintPage title={`Bisherige Entwicklung in ${municipality.name}`}>
        <PrintParagraph
          aside={
            <>
              <p className={styles.source}>Quelle: Bundesagentur für Arbeit</p>
            </>
          }
          main={
            <>
              <p>Entwicklung der Zahl der Beschäftigten 2008 bis 2019</p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <LegendColor color={colors.lightBlue} />
                      Einpendler
                    </th>
                    <th>
                      <LegendColor color={colors.darkGreen} />
                      Auspendler
                    </th>
                    <th>
                      <LegendColor color={colors.primaryColor} />
                      Wohnsitz und Arbeitsstätte in der Gemeinde
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employmentsTableData.map(item => (
                    <tr key={item.year}>
                      <td>{item.year}</td>
                      <td>{item.inbound?.toLocaleString('de')}</td>
                      <td>{item.outbound?.toLocaleString('de')}</td>
                      <td>{item.inner?.toLocaleString('de')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          }
        />
      </PrintPage>
    </>
  );
};
export default Workforce;

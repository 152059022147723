import { IAction } from '../../interfaces/action';
import { IStore } from '../../interfaces/store';
import { SELECT_AREA_SHAPE } from '../../constants/actions';
import selectAreaSize from './select-area-size';
import { Polygon } from "ol/geom";

/**
 * Select an area shape
 */
export function selectAreaShape(
  areaShape: ol.coordinate[]
): IAction {
  return {
    type: SELECT_AREA_SHAPE,
    areaShape
  };
}

/**
 * Select an area shape and maybe calculate the size
 */
export default function (areaShape: ol.coordinate[]) {
  return (dispatch, getState: () => IStore) => {
    dispatch(selectAreaShape(areaShape));

    const size = getState().area.size;

    if ( size || areaShape === null ) {
      return;
    }
    const poly = new Polygon([areaShape]);
    const area: number = poly.getArea();
    const areaInHa: number = area/10000;
    const roundedAreaInHa: number = Math.round(areaInHa * 10) / 10;

    dispatch(selectAreaSize(roundedAreaInHa));
  };
}

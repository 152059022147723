import React, {useState} from 'react';

import getShapeBounds from '../../../libs/get-shape-bounds';

import Canvas from '../../map/canvas';
import AreaShape from '../../map/area-shape';
import Isochrones from '../../map/isochrones';
import Pois from '../../map/pois';
import {useSelector} from 'react-redux';
import {areaSelector} from '../../../selectors/area';
import {IStore} from '../../../interfaces/store';

type IProps = {onInit: () => void};

/**
 * The map container
 */
const MapReachability = ({onInit}: IProps): JSX.Element | null => {
  const [mapCanvas, setMapCanvas] = useState<ol.Map | null>(null);
  const area = useSelector(areaSelector);
  const isochrones = useSelector((state: IStore) => state.isochrones);
  const pois = useSelector((state: IStore) => state.pois);

  if (!area.shape || !area.center) {
    return null;
  }

  const bounds = getShapeBounds(area.shape, 2.5);

  return (
    <div className="map">
      <Canvas
        bounds={bounds}
        center={area.center}
        zoom={14}
        printVersion={true}
        onInit={canvas => {
          setMapCanvas(canvas);
          onInit();
        }}
      />

      {mapCanvas && <AreaShape mapCanvas={mapCanvas} shape={area.shape} edit={false}/>}

      <Isochrones mapCanvas={mapCanvas} isochrones={isochrones} show={true} />

      <Pois mapCanvas={mapCanvas} pois={pois} />
    </div>
  );
};

export default MapReachability;

import {IAction} from '../../interfaces/action';
import {CLEAR_TRAFFIC_ROUTES} from '../../constants/actions';

/**
 * Clear all the traffic routes
 */
export default function(): IAction {
  return {
    type: CLEAR_TRAFFIC_ROUTES
  };
}

import {IAction} from '../../interfaces/action';

import GeoJSON from 'ol/format/GeoJSON.js';
import LineString from 'ol/geom/LineString.js';
import Feature from 'ol/Feature';
import {transform} from 'ol/proj';

import {
  ADD_TRAFFIC_ROUTE,
  GET_TRAFFIC_ROUTE,
  GET_TRAFFIC_ROUTE_ERROR
} from '../../constants/actions';
import getRouteApi from '../../apis/get-route';

/**
 * Get a traffic route to the destination
 */
export function getTrafficRoute(): IAction {
  return {
    type: GET_TRAFFIC_ROUTE
  };
}


/**
 * Add a route with the path and destination
 */
export function addTrafficRoute(
  trafficRoutes: IRoute[] | null,
  destination: ol.coordinate,
  path: ol.coordinate[]
): IAction {
  //trafficRoutes.push( { destination, path } ); //this is done in reducers, not here.
  let coordinates = [transform(path[0].getGeometry().getCoordinates()[0], 'EPSG:3857','EPSG:4326')];
  for (const partpath of path) {
    coordinates.push(
      transform(
        partpath.getGeometry().getCoordinates()[1], 
        'EPSG:3857',
        'EPSG:4326'
      )
    )
  }
  const geojsonformat = new GeoJSON({dataProjection: 'EPSG:4326'});
  const geojsonpath = geojsonformat.writeFeatureObject(new Feature({geometry: new LineString(coordinates)}));
  
  return {
    type: ADD_TRAFFIC_ROUTE,
    routes: { "routes": trafficRoutes, "route": {destination, geojsonpath} }
  };
}

/**
 * Error while fetching the municipalities
 */
export function addTrafficRouteError(error: Error): IAction {
  return {
    type: GET_TRAFFIC_ROUTE_ERROR,
    error
  };
}

/**
 * Add the route from origin to destination
 */
export default function(
  routes: IRoute[] | null,
  origin: ol.coordinate,
  destination: ol.coordinate,
  getRouteRemotely: (
    origin: ol.coordinate,
    destination: ol.coordinate
  ) => Promise<ol.coordinate[]> = getRouteApi
) {
  return dispatch => {
    dispatch(getTrafficRoute());

    return getRouteRemotely(origin, destination)
      .then(routePath => dispatch(addTrafficRoute(routes, destination, routePath)))
      .catch(error => dispatch(addTrafficRouteError(error)));
  };
}

import {IStore} from '../interfaces/store';
import {totalUnitsSelector} from './area';
import {IYearValue} from '../interfaces/year-value';
import {IAreaConfig, areaConfigsById} from '../constants/area-config';
import {PopulationCategories} from '../constants/population-development';

interface ICorrections {
  // efh =  Einfamilienhaus
  efhSize: number;
  // mfh =  Mehrfamilienhaus
  mfhSize: number;
  efhUnderAge: number;
  efhOverAge: number;
  mfhUnderAge: number;
  mfhOverAge: number;
  mfh: number;
}
interface IAdjustments {
  startYear?: number;
  efhSize?: number;
  mfhSize?: number;
  efhUnderAgeProportion?: number;
  mfhUnderAgeProportion?: number;
  efhProportion?: number;
}

const referencePeriod = 25;

/**
 * Calculates the correction factors from the user input
 */
export const getCorrections = (
  adjustments: IAreaConfig,
  defaults: IAreaConfig
): ICorrections => {
  const efhSize = adjustments.efhSize / defaults.efhSize;
  const mfhSize = adjustments.mfhSize / defaults.mfhSize;

  const efhUnderAge =
    adjustments.efhUnderAgeProportion / defaults.efhUnderAgeProportion;

  const efhOverAge =
    (1 - adjustments.efhUnderAgeProportion) /
    (1 - defaults.efhUnderAgeProportion);

  const mfhUnderAge =
    adjustments.mfhUnderAgeProportion / defaults.mfhUnderAgeProportion;
  const mfhOverAge =
    (1 - adjustments.mfhUnderAgeProportion) /
    (1 - defaults.mfhUnderAgeProportion);

  const mfh =
    defaults.efhProportion > 0
      ? 1 - adjustments.efhProportion / defaults.efhProportion
      : 1;

  return {
    efhSize,
    mfhSize,
    efhUnderAge,
    efhOverAge,
    mfhUnderAge,
    mfhOverAge,
    mfh
  };
};

/**
 * Calculates the "Einfamilienhaus" population factor
 */
export const getEfhPopulation = (
  ageGroup: PopulationCategories,
  corrections: ICorrections,
  efhPopulation: {[_: number]: {[_: number]: number}},
  year: number,
  efhProportion: number
  // eslint-disable-next-line
): number => {
  const overAgeSum = [2, 3, 4, 5, 6].reduce(
    (sum, age) => (sum += Number(efhPopulation[age][year])),
    0
  );
  const efhAgeCorection =
    ageGroup === 1
      ? corrections.efhUnderAge
      : (efhPopulation[1][year] * (1 - corrections.efhUnderAge) + overAgeSum) /
        overAgeSum;

  const efhPop =
    efhPopulation[ageGroup][year] *
    corrections.efhSize *
    efhAgeCorection *
    efhProportion;

  return efhPop;
};

/**
 * Calculates the "Mehrfamilienhaus" population factor
 */
export const getMfhPopulation = (
  ageGroup: PopulationCategories,
  corrections: ICorrections,
  mfhPopulation: {[_: number]: {[_: number]: number}},
  year: number
): number => {
  const overAgeSum = [2, 3, 4, 5, 6].reduce(
    (sum, age) => (sum += Number(mfhPopulation[age][year])),
    0
  );
  const mfhAgeCorection =
    ageGroup === 1
      ? corrections.mfhUnderAge
      : (mfhPopulation[1][year] * (1 - corrections.mfhUnderAge) + overAgeSum) /
        overAgeSum;

  const mfhPop =
    mfhPopulation[ageGroup][year] *
    corrections.mfhSize *
    mfhAgeCorection *
    corrections.mfh;

  return mfhPop;
};

/**
 * Calculates the population for a year in the reference period
 */
export const getPopulation = (
  populationFactorsOverYears: number[],
  yearsOfUnitsInTime: number[],
  influx: number,
  totalUnits: number
): number => {
  const unitAgePercent = 1 / influx;

  return (
    populationFactorsOverYears.reduce((population, age, year): number => {
      const factor = yearsOfUnitsInTime.includes(year + 1) ? unitAgePercent : 0;

      return population + age * factor;
    }, 0) * totalUnits
  );
};

/**
 * Whether the active view is an ecology view or not
 */
export function populationStructureSelector(
  state: IStore,
  options: IAdjustments | null = {}
): Map<PopulationCategories, IYearValue[]> | null {
  const {area, development} = state;
  
  if ( development===undefined || !development.population || !development.population[area.densityId] || !area.influx || !area.densityId ) {
    return null;
  }

  const areaConfigDefaults = areaConfigsById[area.densityId];

  // Use the default when adjustments are not available
  const adjustments = {
    startYear: new Date().getFullYear(),
    ...areaConfigDefaults,
    ...options
  };

  const data = new Map([
    [1, [{year: 0, value: 0}]],
    [2, [{year: 0, value: 0}]],
    [3, [{year: 0, value: 0}]],
    [4, [{year: 0, value: 0}]],
    [5, [{year: 0, value: 0}]],
    [6, [{year: 0, value: 0}]]
  ]);

  const efhDataByAgeGroup = development.population[area.densityId][1];
  const mfhDataByAgeGroup = development.population[area.densityId][2];
  const corrections = getCorrections(adjustments, areaConfigDefaults);

  const yearsOfTimePassing = Array(referencePeriod)
    .fill(null)
    .map((_, i): number => i + 1);

  // For age group 1 to 6 build population data
  for (const ageGroup of data.keys()) {
    // For 1 to `referencePeriod` years of time passing build the
    // population factors with user corrections
    const populationFactorsOverYears: number[] = yearsOfTimePassing.map(
      (year): number => {
        const efhPopulation = getEfhPopulation(
          ageGroup,
          corrections,
          efhDataByAgeGroup,
          year,
          adjustments.efhProportion
        );
        const mfhPopulation = getMfhPopulation(
          ageGroup,
          corrections,
          mfhDataByAgeGroup,
          year
        );
        return efhPopulation + mfhPopulation;
      }
    );

    // For 1 to `referencePeriod` years of time passing accumulate
    // the population per unit age
    const populationPerYear: IYearValue[] = yearsOfTimePassing.map(
      (_, index): IYearValue => {
        // Find the index of passed years with unit ages
        const yearsOfUnitsInTime = yearsOfTimePassing.slice(
          // @ts-ignore area.influx is not null at this point
          Math.max(index - area.influx + 1, 0),
          index + 1
        );
        const value = getPopulation(
          populationFactorsOverYears,
          yearsOfUnitsInTime,
          // @ts-ignore area.influx is not null at this point
          area.influx,
          totalUnitsSelector(state)
        );

        return {
          year: adjustments.startYear + index,
          value
        };
      }
    );

    data.set(ageGroup, populationPerYear);
  }

  return data;
}

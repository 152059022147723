import {IAction} from '../../interfaces/action';
import {DELETE_AREA_SHAPE} from '../../constants/actions';

/**
 * Delete an area shape
 */
export default function(): IAction {
  return {
    type: DELETE_AREA_SHAPE
  };
}

import React from 'react';
import {useSelector} from 'react-redux';
import {areaConfigSelector} from '../../../selectors/area';
import {selectFollowUpCosts} from '../../../selectors/followup-costs';

import InitialCosts from './intial-costs';
import CostsByObject from './costs-by-object';
import CostComparison from './costs-comparison';

const FollowupCosts = (): JSX.Element | null => {
  const areaConfig = useSelector(areaConfigSelector);
  const data = useSelector(selectFollowUpCosts);

  if (!areaConfig) {
    return null;
  }

  return (
    <>
      <InitialCosts data={data} areaConfig={areaConfig} />
      <CostsByObject data={data} areaConfig={areaConfig} />
      <CostComparison data={data} areaConfig={areaConfig} />
    </>
  );
};

export default FollowupCosts;

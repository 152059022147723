import React from 'react';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import ByCostPhase from '../../chart-views/followup-costs/by-cost-phase';

import {
  networkElementGroupLabels,
  NetworkElementGroupIds
} from '../../../constants/network-elements';
import LegendColor from '../legend-color/legend-color';

import colors from '../../../constants/colors';
import {IAreaConfig} from '../../../constants/area-config';
import {IFollowupCosts} from '../../../interfaces/followup-costs';

type IProps = {
  areaConfig: IAreaConfig;
  data: Map<NetworkElementGroupIds, IFollowupCosts>;
};

const InitialCosts = ({areaConfig, data}: IProps): JSX.Element => (
  <PrintPage title="Folgekosten im Bereich der technischen Infrastrukturen">
    <PrintParagraph
      aside={
        <>
          <h3>
            Gesamtkosten,
            <br /> differenziert nach Kostenphase
          </h3>
        </>
      }
      main={
        <>
          <p>
            Schätzung der Kosten für die innere Erschließung im Falle einer
            Neuerschließung.
          </p>
          <p>
            Die Annahmen zur Abschätzung der Gesamtkosten finden sie im
            separaten Dokument &quot;Methodik&quot;.
          </p>
          <ByCostPhase data={data} areaTypeId={areaConfig.areaTypeId} print />
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  <LegendColor color={colors.primaryColor} />
                  Erstmalige Herstellung
                </th>
                <th>
                  <LegendColor color={colors.lightRed} />
                  Betrieb, Unterhaltung und Erneuerung
                  <br /> für die nächsten 25 Jahre
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.from(data.entries()).map(entry => (
                <tr key={entry[0]}>
                  <td>{networkElementGroupLabels.get(entry[0])}</td>
                  <td>
                    {(
                      Math.round((entry[1].get(1) || 0) / 1000) * 1000
                    ).toLocaleString('de')}{' '}
                    €
                  </td>
                  <td>
                    {(
                      Math.round((entry[1].get(2) || 0) / 1000) * 1000
                    ).toLocaleString('de')}{' '}
                    €
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    />
  </PrintPage>
);

export default InitialCosts;

/**
 * Load the isochrones from the API
 *
 * @see http://dev.opentripplanner.org/apidoc/1.0.0/resource_LIsochrone.html
 */
export default function(
  center: ol.coordinate
): Promise<GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>> {
  return new Promise((resolve, reject) => {
    //const travelModeCalls = ['CAR', 'BICYCLE', 'WALK'].map(travelMode => {
    const travelModeCalls = ['driving-car', 'cycling-regular', 'foot-walking'].map(
        travelMode => {
            const url = 'https://ors.germany.ils-geomonitoring.de/v2/isochrones/' + travelMode;
            
            return fetch(url, {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({"locations":[center],"range":[600]})
            })
            .then(response => response.json())
            .then(json => {
                const isochrone: any = json;
                
                isochrone.features[0].properties = Object.assign(
                  {},
                  isochrone.features[0].properties,
                  {travelMode}
                );
                return isochrone;
            });
        }
    );

    Promise.all(travelModeCalls)
      .then(allIsochrones => {
        const collection: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> = {
          type: 'FeatureCollection',
          features: allIsochrones.map(isochrone => isochrone.features[0])
        };

        resolve(collection);
      })
      .catch(error => reject(error));
  });
}

import React from 'react';

import {
  selectInitialCostByMeterByGroup,
  selectMaintenanceCostByMeterByGroup,
  selectNetworkLengths
} from '../../../selectors/followup-costs';

import {IStore, IArea} from '../../../interfaces/store';
import {connect} from 'react-redux';
import {ReferenceValues} from '../../../constants/followup-costs';
import {NetworkElementIds} from '../../../constants/network-elements';

interface IStateProps {
  area: IArea;
  initialCosts: {[_: number]: number};
  maintenanceCosts: {[_: number]: number};
  networklengths: {[_: number]: number} | null;
}

type IProps = IStateProps;

function roundMeters(meters) {
  return meters < 10000
    ? Math.round(meters * 0.1) / 0.1
    : Math.round(meters * 0.01) / 0.01;
}

const Assumptions = (props: IProps): JSX.Element => {
  const {area, initialCosts, maintenanceCosts, networklengths} = props;

  const e3 =
    area.densityId &&
    ReferenceValues[area.densityId][NetworkElementIds.Electricity3];

  return (
    <>
      <h1>Annahmen der Berechnung</h1>
      <p className="intro">
        Den vorstehenden Diagrammen liegen die folgenden Schätzungen und
        Annahmen zugrunde. Diese können nicht verändert werden.
      </p>
      <p className="intro">
        Wenn Sie die Annahmen verändern möchten, nutzen Sie bitte das Programm{' '}
        <a
          href="https://www.projekt-check.de/profi-check"
          target="_blank"
          rel="noopener noreferrer">
          Profi-Check (für ArcGIS oder QGIS)
        </a>{' '}
        oder den FolgekostenSchätzer unter{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.was-kostet-mein-baugebiet.de">
          www.was-kostet-mein-baugebiet.de
        </a>
        .
      </p>
      <p></p>
      <h1>Netzlängen im Plangebiet (Schätzung)</h1>
      <p>
        Zur Erschließung des {area.size && area.size.toLocaleString('de')} ha
        großen Plangebiets sind nach grober Schätzung von Projekt-Check etwa
        folgende Netzlängen notwendig. Einzelne Abschnitte können dabei jeweils
        unterschiedliche Breiten, Durchmesser bzw. Leitungskennwerte haben.
        Hausanschlüsse werden in der gesamten Abschätzung nicht berücksichtigt,
        da deren Kosten i.d.R. vollständig privat getragen werden.
      </p>
      <table>
        <tbody>
          <tr>
            <td>Straße (Erschließungs- und Sammelstraßen)</td>
            <td>
              {networklengths &&
                roundMeters(networklengths[1]).toLocaleString('de')}{' '}
              lfd. Meter
            </td>
          </tr>
          <tr>
            <td>Kanalisation (Kanäle)</td>
            <td>
              {networklengths &&
                roundMeters(networklengths[2]).toLocaleString('de')}{' '}
              lfd. Meter
            </td>
          </tr>
          <tr>
            <td>Trinkwasser (Wasserleitungen)</td>
            <td>
              {networklengths &&
                roundMeters(networklengths[3]).toLocaleString('de')}{' '}
              lfd. Meter
            </td>
          </tr>
          <tr>
            <td>Elektrizität (Stromleitungen)</td>
            <td>
              {networklengths &&
                roundMeters(networklengths[4]).toLocaleString('de')}{' '}
              lfd. Meter
            </td>
          </tr>
        </tbody>

        {e3 > 0 && (
          <tfoot className="table__hint">
            <tr><th>
            Wert für Elektrizität inkl. Trafostation.
            </th></tr>
          </tfoot>
        )}
      </table>

      <h1>Gesamtkosten pro laufendem Meter</h1>
      <p>
        Pro laufendem Meter Netzlänge (siehe vorstehende Tabelle) werden die
        folgenden Standardkosten angesetzt. Dabei wird zwischen der erstmaligen
        Herstellung und den jährlichen Kosten für Betrieb, Unterhaltung und
        Erneuerung (rechnerische Rücklagen) unterschieden. Zur besseren
        Vergleichbarkeit mit der erstmaligen Herstellung werden die Betriebs-,
        Unterhaltungs- und Erneuerungskosten als Summenwert für die ersten 25
        Jahre ausgewiesen. Nach dieser Zeit fallen weitere Betriebs-,
        Unterhaltungs- und Erneuerungskosten an. In alle Kostenkennwerte gehen
        neben dem regionalen Baukostenniveau auch die angenommenen Breiten,
        Durchmesser oder Leitungskennwerte der Netzabschnitte ein. In der
        gleichen Gemeinde werden bei größeren Flächen und höheren Dichten daher
        etwas höhere Kostenkennwerte angesetzt als bei kleineren Flächen und
        geringeren Dichten.
      </p>
      <p className="assumption-intro">Erstmalige Herstellung</p>
      <table>
        <tbody>
          <tr>
            <td>Straße</td>
            <td>{initialCosts[1]} EUR / lfd. Meter Straße</td>
          </tr>
          <tr>
            <td>Kanalisation</td>
            <td>{initialCosts[2]} EUR / lfd. Meter Kanal</td>
          </tr>
          <tr>
            <td>Trinkwasser</td>
            <td>{initialCosts[3]} EUR / lfd. Meter Trinkwasserleitung</td>
          </tr>
          <tr>
            <td>Elektrizität</td>
            <td>{initialCosts[4]} EUR / lfd. Meter Stromleitung</td>
          </tr>
        </tbody>
      </table>
      <p className="assumption-intro">
        Betrieb, Unterhaltung und Erneuerung für die nächsten 25 Jahre
      </p>
      <table>
        <tbody>
          <tr>
            <td>Straße</td>
            <td>{maintenanceCosts[1]} EUR / lfd. Meter Straße</td>
          </tr>
          <tr>
            <td>Kanalisation</td>
            <td>{maintenanceCosts[2]} EUR / lfd. Meter Kanal</td>
          </tr>
          <tr>
            <td>Trinkwasser</td>
            <td>{maintenanceCosts[3]} EUR / lfd. Meter Trinkwasserleitung</td>
          </tr>
          <tr>
            <td>Elektrizität</td>
            <td>{maintenanceCosts[4]} EUR / lfd. Meter Stromleitung</td>
          </tr>
        </tbody>
        {e3 > 0 && (
          <tfoot className="table__hint">
            <tr><th>
            Wert für Elektrizität inkl. Trafostation.
            </th></tr>
          </tfoot>
        )}
      </table>

      <h1> Anteile der Kostenträger</h1>
      <p>
        Die vorstehenden Gesamtkosten werden von den Grundstücksbesitzer/innen,
        der Gemeinde und der Allgemeinheit der Tarifkunden getragen. Welcher
        Kostenträger welchen Anteil trägt, ist je nach Netz und Kostenphase
        unterschiedlich geregelt. Die Grobabschätzung von Projekt-Check geht von
        den folgenden Annahmen zur Kostenaufteilung aus.
      </p>
      <p className="assumption-intro">Straße</p>
      <p>
        Für die erstmalige Herstellung wird von Erschließungsbeiträgen nach §127
        BauGB ausgegangen. Entsprechend zahlen die Grundstücksbesitzer/innen 90%
        der Kosten. Die restlichen 10% zahlt die Gemeinde. Für die nachfolgende
        Betriebs-, Unterhaltungs- und Erneuerungsphase wird unterstellt, dass
        die Gemeinde fast alle Kosten trägt. Die Grundstücksbesitzer/innen
        zahlen nur geringe Anteile (z.B. in Form der Straßenreinigungsgebühr).
        Bezüglich der Erneuerung geht die Grobabschätzung davon aus, dass die
        Gemeinde keine Straßenerneuerungsbeiträge nach KAG erhebt, sondern die
        Erneuerungskosten alleine trägt.
      </p>
      <table>
        <thead>
          <tr>
            <th />
            <th>Grundstücksbesitzer/innen</th>
            <th>Gemeinde</th>
            <th>Allgemeinheit der Tarifkunden</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Erstmalige Herstellung</td>
            <td>90%</td>
            <td>10%</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>
              Betrieb, Unterhaltung und Erneuerung für die nächsten 25 Jahre
            </td>
            <td>5%</td>
            <td>95%</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
      <p className="assumption-intro">Kanalisation</p>
      <p>
        In den meisten Gemeinden werden die Grundstücksbesitzer/innen neu
        erschlossener Flächen über Anschlussbeiträge an den Kosten der
        erstmaligen Herstellung der Kanalisation beteiligt. Häufig decken diese
        Beiträge aber nicht die gesamten Herstellungskosten. Die Abschätzung
        geht von einem Kostendeckungsgrad von 75% aus. Der Restbetrag sowie alle
        nachfolgenden Kosten für Betrieb, Unterhaltung und Erneuerung gehen in
        die allgemeine Tarifkalkulation der Abwassergebühr ein. Entsprechend
        werden sie von allen Netzkunden/innen im gesamten Versorgungsgebiet
        getragen.
      </p>
      <table>
        <thead>
          <tr>
            <th />
            <th>Grundstücksbesitzer/innen</th>
            <th>Gemeinde</th>
            <th>Allgemeinheit der Tarifkunden</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Erstmalige Herstellung</td>
            <td>75%</td>
            <td>0%</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>
              Betrieb, Unterhaltung und Erneuerung für die nächsten 25 Jahre
            </td>
            <td>0%</td>
            <td>0%</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
      <p className="assumption-intro">Trinkwasser und Elektrizität</p>
      <p>
        Bei der Trinkwasser- und der Elektrizitätsversorgung werden i.d.R. alle
        anfallenden Erschließungskosten aus den allgemeinen Verbrauchstarifen
        („Wasserpreis“ bzw. „Strompreis“) finanziert. Dies gilt sowohl für die
        erstmalige Herstellung wie auch für die nachfolgenden Kosten für
        Betrieb, Unterhaltung und Erneuerung. In der Abschätzung wird davon
        ausgegangen, dass die entsprechenden Versorger keine Baukostenzuschüsse
        nach AVBWasserV bzw. AVBEltV erheben. Da alle Tarifkunden die gleichen
        Preise pro Verbrauchseinheit (m3 Wasser, kWh Strom) zahlen, tragen die
        Bewohner/innen von Gebieten höherer Siedlungsdichte (z.B.
        Mehrfamilienhäusern) die pro-Kopf-Mehrkosten von Strukturen mit
        geringeren Siedlungsdichten (z.B. Einfamilienhausgebieten). Dies gilt
        z.T. auch für die Kanalisation (s.o.).
      </p>
      <table>
        <thead>
          <tr>
            <th />
            <th>Grundstücksbesitzer/innen</th>
            <th>Gemeinde</th>
            <th>Allgemeinheit der Tarifkunden</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Erstmalige Herstellung</td>
            <td>0%</td>
            <td>0%</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>
              Betrieb, Unterhaltung und Erneuerung für die nächsten 25 Jahre
            </td>
            <td>0%</td>
            <td>0%</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

/**
 * Map the received store to properties that are passed to the component.
 */
const mapStoreToProps = (store: IStore): IStateProps => {
  return {
    area: store.area,
    initialCosts: selectInitialCostByMeterByGroup(store),
    maintenanceCosts: selectMaintenanceCostByMeterByGroup(store),
    networklengths: selectNetworkLengths(store)
  };
};

export default connect(mapStoreToProps)(Assumptions);

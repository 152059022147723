import {IMunicipalityData} from '../interfaces/municipality-data';

/**
 * Load the municipality data JSON and return it with a Promise
 */
export default function(id: string): Promise<IMunicipalityData> {
  return new Promise((resolve, reject) => {
    const url = `data/municipality-${id}.json`;

    fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(municipalityData => resolve(municipalityData))
      .catch(error => reject(error));
  });
}

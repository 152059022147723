import {ViewIds} from './views';
import {IEcology} from '../interfaces/ecology';

/**
 * The various ecologies overlays
 */
export const Ecologies = new Map<ViewIds, IEcology>();
Ecologies.set(
    ViewIds.EcologyLandscapeProtection, 
    {
        shortName: 'Landschaftsschutz',
        description: 'Schutzgebiete mit Schwerpunkt "Landschaftsschutz" im Umfeld des Plangebiets',
        copyright: `Bundesamt für Naturschutz (BfN), Stand: 2017-2019`,
        serviceType: 'wms',
        data: {
          url: 'https://projektcheck.gs.ils-geomonitoring.de/projektcheck/wms?',
          layers: [
            {
              id: 'lsg',
              name: 'Landschaftsschutzgebiete'
            },
            {
              id: 'bio',
              name: 'Biosphärenreservate (UNESCO)'
            },
            {id: 'naturparke', name: 'Naturparke'}
          ]
        }
        });

Ecologies.set(
    ViewIds.EcologySpeciesProtection, 
    {
        shortName: 'Naturschutz',
        description: 'Schutzgebiete mit Schwerpunkt "Naturschutz" im Umfeld des Plangebiets',
        copyright: `Bundesamt für Naturschutz (BfN), Stand: 2017-2019`,
        serviceType: 'wms',
        data: {
          url: 'https://projektcheck.gs.ils-geomonitoring.de/projektcheck/wms?',
          layers: [
            {
              id: 'nnm',
              name: 'Nationale Naturmonumente'
            },
            {id: 'nsg', name: 'Naturschutzgebiete'},
            {id: 'nlp', name: 'Nationalparke'},
            {id: 'vsg', name: 'Vogelschutzgebiete'},
            {
              id: 'ffh',
              name: 'FFH-Gebiete'
            }
          ]
        }
        });

Ecologies.set(
    ViewIds.EcologyHighVoltageSupplyLines, 
    {
        shortName: 'Hochspannung',
        description: 'Hochspannungsfreileitungen im Umfeld des Plangebiets',
        copyright: `Bundesamt für Kartographie und Geodäsie (BKG)`,
        serviceType: 'wfs',
        data: {
          url: 'https://projektcheck.gs.ils-geomonitoring.de/projektcheck_wfs/wfs?',
          layers: [
            {
              id: 'projektcheck_wfs:51005_ax_leitung',
              name: 'Hochspannungsfreileitungen',
              color: '#000000'
            }
          ]
        }
    });

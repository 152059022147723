import React, {useEffect, useState} from 'react';
import sizeme from 'react-sizeme';

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Hint
} from 'react-vis';

import {
  LineChart as LineChartRe,
  Line as LineRe, 
  XAxis as XAxisRe, 
  YAxis as YAxisRe, 
  CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  ReferenceLine
} from 'recharts';

import Loading from '../shared/loading/loading';
// import Legend from '../shared/legend';

import {getExtremeBy} from '../../libs/math-functions';
import createInvisibleLineData from '../../libs/create-invisible-line-data';
import colors from '../../constants/colors';

import {IMunicipalityData} from '../../interfaces/municipality-data';
import {IGraphDatum} from '../../interfaces/graph-datum';
import {IYearValue} from '../../interfaces/year-value';


import clsx from 'clsx';
import styles from './recent-development.styl';

interface IProps {
  data: IMunicipalityData | null;
  size: {width: number; height: number};
  print?: boolean;
}

const COLORS = {
  y: colors.primaryColorHex,
  absolute: colors.lightBlue
};

const useData = (
  data: IMunicipalityData | null
): {
  minYear: any;
  maxYear: any;
  domain: [number, number] | null;
  graphs: {x: number; y: number; absolute: number}[][] | null;
  invisibleLineData: IGraphDatum<number, number>[] | null;
} => {

  /**
   * set State with same name as props. (Is this allowed?)
   */
  const [invisibleLineData, setInvisibleLineData] = useState<IGraphDatum<number, number>[] | null>(null);
  const [maxYear, setMaxYear] = useState<IYearValue>({value: 0, year: 0});
  const [minYear, setMinYear] = useState<IYearValue>({value: 0, year: 0});
  const [domain, setDomain] = useState<[number, number] | null>(null);
  const [graphs, setGraphs] = useState<{x: number; y: number; absolute: number}[][] | null>(null);

  useEffect(
    () => {
      if (data) {
        const inhabitantsData: IYearValue[][] = data.inhabitants;
        setMinYear(getExtremeBy(inhabitantsData, 'min', 'year'));
        setMaxYear(getExtremeBy(inhabitantsData, 'max', 'year'));
      }
    },
    [data]
  );

  useEffect(() => {
    if (minYear.value && maxYear.value && data) {
      const minByValue: IYearValue = getExtremeBy(
        data.inhabitants,
        'min',
        'value'
      );
      const maxByValue: IYearValue = getExtremeBy(
        data.inhabitants,
        'max',
        'value'
      );
      const minPercentage: number = (minByValue.value / minYear.value) * 100;
      const maxPercentage: number = (maxByValue.value / minYear.value) * 100;
      const minDomainRaw: number = Math.floor(minPercentage / 5) * 5;
      const minDomain: number = Math.min(minDomainRaw, 95);
      const maxDomain: number = Math.floor(maxPercentage / 5 + 1) * 5;

      setDomain([minDomain, maxDomain]);

      const inhabitants_joined = Array();
      data.inhabitants[0].forEach(
        (yearValue: IYearValue) => {
          inhabitants_joined.push({
            name: 'preZensus',
            x: yearValue.year,
            y: Math.round(((yearValue.value / minYear.value) * 100)*100)/100,
            absolute: yearValue.value
          })
        }
      )
      data.inhabitants[1].forEach(
        (yearValue: IYearValue) => {
          inhabitants_joined.push({
            name: 'postZensus',
            x: yearValue.year,
            y: Math.round(((yearValue.value / minYear.value) * 100)*100)/100,
            absolute: yearValue.value
          })
        }
      )
      setGraphs(inhabitants_joined);

      setInvisibleLineData(createInvisibleLineData(minYear.year, maxYear.year));
    }
  }, [minYear, maxYear, data]);

  return {
    minYear: minYear.year,
    maxYear: maxYear.year,
    domain,
    graphs,
    invisibleLineData
  };
};

const handleNearestX = (
  hoveredDatum: IGraphDatum<number, number>,
  graphs
): IGraphDatum<number, number> | null => {
  if (!hoveredDatum) {
    return null;
  }

  const year: number = hoveredDatum.x;
  const values: (
    | IGraphDatum<number, number>
    | undefined
  )[] = graphs.map(graph => graph.find(graphDatum => graphDatum.x === year));
  let hoveredValue: IGraphDatum<number, number> | null = values[0] || null;

  if (!hoveredValue) {
    hoveredValue = values[1] || null;
  }

  return hoveredValue;
};

const Inhabitants = (
  props: IProps
): JSX.Element => {
  const [hoveredValue, setHoveredValue] = useState<IGraphDatum<number, number> | null>(null);
  const {minYear, maxYear, domain, graphs, invisibleLineData} = useData(
    props.data
  );
  if (!minYear || !maxYear || !domain || !graphs || !invisibleLineData) {
    return <Loading />;
  }

  const marginXYPlot = props.print
    ? {left: 60, right: 50, top: 10, bottom: 40}
    : {left: 80, right: 30, top: 10, bottom: 40};

  return (
    <div>
      <p>
        Einwohnerentwicklung {minYear} bis {maxYear} (Indexwert).
      </p>
      <p>Einwohnerzahl von {minYear} entspricht dem Indexwert 100.</p>

      <LineChartRe
          width={props.size.width}
          height={300}
          data={graphs}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxisRe dataKey="x" />
          <YAxisRe yAxisId="left"  domain={domain}/>
          {/* <YAxisRe yAxisId="right"  domain={['dataMin', 'dataMax']} orientation="right" /> */}
          <ReferenceLine yAxisId="left" x={2011} stroke="green" label="Zensus 2011" text-align="right" />
          <Tooltip />
          <Legend />
          <LineRe
            yAxisId="left"
            name="Einwohner*Innen (indizierte Entwicklung)" 
            type="linear" 
            dataKey="y"
            stroke={COLORS.y}
            activeDot={{ r: 8 }}
          />
          {/* <LineRe
            yAxisId="right"
            name="Einwohner*Innen (absolut)" 
            type="linear" 
            dataKey="absolute"
            stroke={COLORS.absolute}
            activeDot={{ r: 8 }}
          /> */}
      </LineChartRe>
      {/* {!props.print && (
        <Legend
          entries={[
            {
              color: colors.primaryColor,
              description: 'vor Zensus-Bereinigung 2011'
            },
            {
              color: colors.lightBlue,
              description: 'nach Zensus-Bereinigung 2011'
            }
          ]}
        />
      )} */}

      {!props.print && (
        <p className={clsx(styles.note, styles.hideOnMobile)}>
          Wenn Sie mit der Maus über das Diagramm fahren, werden Ihnen die
          Absolutwerte angezeigt.
        </p>
      )}
    </div>
  );
};

export default sizeme()(Inhabitants);
// export default Inhabitants;

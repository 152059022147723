import {IStore} from '../interfaces/store';
import {IYearValue} from '../interfaces/year-value';
import {Industries} from '../constants/workplace-development'; // eslint-disable-line no-unused-vars, max-len

/**
 * The workplace development data
 */
export default function(
  // eslint-disable-line complexity
  state: IStore
): IYearValue[] | null {
  const {area, municipality} = state;
  const areaSize = area.size || 1;
  const areaInflux = area.influx || 1;

  if (!area.densityId || !municipality || !municipality.data) {
    return null;
  }

  const {workplaceDevelopment} = municipality.data;

  if (!workplaceDevelopment || !workplaceDevelopment[area.densityId]) {
    return null;
  }

  const data: IYearValue[] = [],
    currentYear: number = new Date().getFullYear(),
    rawData: {[key: number]: number} = workplaceDevelopment[area.densityId],
    industryIds: Industries[] = Object.keys(rawData).map(industry =>
      Number(industry)
    ),
    totalWorkplaces: number = industryIds.reduce((total, industry) => {
      if (!rawData[industry]) {
        return total;
      }

      return total + rawData[industry] * areaSize;
    }, 0);

  for (let year = 1; year <= areaInflux + 7; year++) {
    const weight: number = Math.min(year / areaInflux, 1);

    data.push({
      year: currentYear + 1 + year,
      value: totalWorkplaces * weight
    });
  }

  return data;
}

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ViewIds } from '../constants/views';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useMatch, useNavigate } from 'react-router-dom';
import { matchRoutes, useLocation } from "react-router-dom"
import useMediaQuery from '@mui/material/useMediaQuery';
import { ErrorBoundary } from "react-error-boundary";

// @ts-ignore
import mediaQueries from '../../styles/media-queries.json';

// import Map from './map/map';
import Map from './map/map';
import AnalyzeMenu from './analyze-menu/analyze-menu';
import Information from './information/information-container';
import Ecology from './ecologies/ecology';
import TrafficReachability from './traffic/reachability';
import TrafficInfoOverlay from './traffic/traffic-info-overlay';
import PopulationDevelopment from './chart-views/population-development/population';
import WorkplaceDevelopment from './chart-views/workplace-development/workplace';
import FollowupCosts from './chart-views/followup-costs/followup-costs-container';
import LocationCompetition from './location-competition/location-competition';
import configurationStepNumber from '../selectors/configuration-step-number';
import Welcome from '../components/welcome/welcome';
import RecentDevelopment from '../components/recent-development/recent-development-container';

import Configuration from './configuration/configuration';
import { steps } from '../config/steps';

import styles from './app.styl';

import { useMatomo } from '@jonkoops/matomo-tracker-react';
/**
 * The interactive app container
 */

const AppInteractive = (): JSX.Element => {

    const { trackPageView, trackEvent } = useMatomo();

    const configStep = useSelector(configurationStepNumber);

    const configurationIsDone = configStep === steps.done.number;
    const configurationIsInit = configStep === steps.municipality.number;
    const isDesktop = useMediaQuery(mediaQueries.big, { noSsr: true });
    const [hideMap, setHideMap] = useState(false);
    const [hideConfig, setHideConfig] = useState(false);

    const routes = [{ path: "/members/:id" }]

    const navigate = useNavigate();

    useEffect(() => {
        trackPageView({ "href": "webcheck.ils-geomonitoring.de" });
    }, [])

    return (
        <div className={styles.container}>
            {/* Always render map on desktop, just hide it when not needed (when
            Recent Development is shown) */}
            { isDesktop===true && <Map hide={hideMap} />}
            { useMatch('/welcome') && configurationIsDone && <Navigate to="/views" replace={true} /> }
            { useMatch('/views/*') && !configurationIsDone && <Navigate to={'/welcome'} replace /> }
            <> {/* for sidebar */}
                <Routes>
                    <Route path='/welcome' element={<Welcome/>}/>
                    <Route path='/config/*'>
                        <Route
                            path=':configurationStep'
                            loader={
                                ():void => {
                                    if (hideMap) {
                                        setHideMap(false);
                                    }
                                    if (hideConfig) {
                                        setHideConfig(false);
                                    }
                                }
                            }
                            element={ <Configuration hide={hideConfig} />}
                        />
                        <Route
                            path='municipality/recent'
                            loader={
                                ():void => {
                                    setHideMap(true);
                                    if (!isDesktop) {
                                        setHideConfig(true);
                                    }
                                }
                            }
                            element={ <RecentDevelopment />}
                        />
                        <Route
                            path='*'
                            loader={
                                ():void => {
                                    if (hideMap) {
                                        setHideMap(false);
                                    }
                                    if (hideConfig) {
                                        setHideConfig(false);
                                    }
                                }
                            }
                            element={<Configuration hide={hideConfig} />}
                        />
                    </Route>
                    <Route
                        path='/views/*'
                        loader={() => {
                            if (hideMap) {
                                setHideMap(false);
                            }
                        }}
                        element={
                            <AnalyzeMenu onSidebarTextShown={(): void => setHideMap(false)} />
                        }
                    />
                    { useMatch('/') && configurationIsInit && <Route path="/*" element={<Navigate to="/welcome" replace={true}/>}/> }
                </Routes>
            </>
            <> {/* for map/chart view */}
                <Routes>
                    <Route
                        path={`views/${ViewIds.WorkplaceDevelopment}/charts`}
                        loader={()=>{
                            setHideMap(true);
                        }}
                        element={<WorkplaceDevelopment />}
                    />
                    <Route
                        path={`views/${ViewIds.PopulationDevelopment}/charts`}
                        loader={()=>{
                            setHideMap(true);
                        }}
                        element={<PopulationDevelopment />}
                    />
                    <Route
                        path={`views/${ViewIds.FollowupCosts}/charts`}
                        loader={()=>{
                            setHideMap(true);
                        }}
                        element={<FollowupCosts />}
                    />
                    {/* Views without chart components  */}
                    <Route
                        path={`views/${ViewIds.EcologyHighVoltageSupplyLines}`}
                        element={<Ecology />}
                    />
                    <Route
                        path={`views/${ViewIds.EcologyLandscapeProtection}`}
                        element={<Ecology />}
                    />
                    <Route
                        path={`views/${ViewIds.EcologySpeciesProtection}`}
                        element={<Ecology />}
                    />
                    <Route path={`views/${ViewIds.TrafficReachability}`}
                        element={<TrafficReachability />}
                    />
                    <Route path={`views/${ViewIds.TrafficEnvironment}`}
                        element={<TrafficInfoOverlay />}
                    />
                    <Route path={`views/${ViewIds.LocationCompetition}`}
                        element={<LocationCompetition />}
                    />
                    <Route
                        path={'views/:viewId/info'}
                        loader={()=>{
                            if (!hideMap) {
                                setHideMap(true);
                            }
                        }}
                        element={<Information />}
                    />
                </Routes>
            </>
        </div>
    );
};

export default AppInteractive;

import {IRoute} from '../../../interfaces/routes';
import {IStore} from '../../../interfaces/store';
import React, {useState, useEffect} from 'react';
import {useSelector, connect, useDispatch} from 'react-redux';

import getShapeBounds from '../../../libs/get-shape-bounds';

import Canvas from '../../map/canvas';
import AreaShape from '../../map/area-shape';
import TrafficRoutes from '../../map/traffic-routes';
import {Coordinate} from 'ol/coordinate';
import Map from 'ol/Map';

import mapDataSelector from '../../../selectors/map-data';

import addTrafficRoute from '../../../actions/traffic/add-traffic-route';
import removeTrafficRoute from '../../../actions/traffic/remove-traffic-route';

interface IStoreProps {
  areaCenter: Coordinate | null;
  areaShape: Coordinate[] | null;
  trafficRoutes: IRoute[];
}
type IProps = IStoreProps;
interface IState {
  mapCanvas: Map | null; // eslint-disable-line no-use-before-define
}

const TrafficRoutesMap = ({areaCenter, areaShape, trafficRoutes, onInit}: IProps): JSX.Element | null => {
  const [mapCanvas, setMapCanvas] = useState<ol.Map | null>(null);
  
  const dispatch = useDispatch();
  
  const mapData = useSelector(mapDataSelector);
  const handleMapCanvasInit = (mapCanvas: ol.Map) => this.setState({mapCanvas});

  const bounds = getShapeBounds(
    // @ts-ignore flat() is available on arrays nowadays
    mapData.areaShape,
    3
  );

  /**
   * Trigger creation of a new route
   */
  const addNewTrafficRoute = (destination: ol.coordinate): void => {
    if (!mapData.areaCenter) {
      return;
    }
    dispatch(addTrafficRoute(mapData.trafficRoutes, mapData.areaCenter, destination));
  };
  const removeThisTrafficRoute = (route) => {
    dispatch(removeTrafficRoute(mapData.trafficRoutes, route))
  }
  
  return (
    <div className="map">
      <Canvas
        bounds={bounds}
        center={mapData.areaCenter}
        zoom={14}
        printVersion={true}
        onInit={canvas => {
          setMapCanvas(canvas);
          onInit();
        }}
      />

      {mapCanvas && <AreaShape
        mapCanvas={mapCanvas}
        shape={mapData.areaShape}
        edit={false}  
      />}
      
      <TrafficRoutes
        mapCanvas={mapCanvas}
        view={4}
        show={true}
        routes={mapData.trafficRoutes}
        onClick={addNewTrafficRoute}
        removeRoute={removeThisTrafficRoute}
      />
    </div>
  )
}

/**
 * The map container
 */
// class Map extends React.Component<IProps, IState> {
//   /**
//    * The map state
//    */
//   state: IState = {
//     mapCanvas: null
//   };

//   /**
//    * Handle the init of the OpenLayers Map
//    */
//   handleMapCanvasInit(mapCanvas: ol.Map): void {
//     this.setState({mapCanvas});
//   }

//   /**
//    * Render the Component
//    */
//   render(): JSX.Element | null {
//     const {areaShape, areaCenter} = this.props;
//     const {mapCanvas} = this.state;

//     if (!areaShape || !areaCenter) {
//       return null;
//     }

//     const bounds = getShapeBounds(
//       // @ts-ignore flat() is available on arrays nowadays
//       [...areaShape, ...this.props.trafficRoutes.map(({ path }) => path).flat()],
//       3
//       );

//     return (
//       <div className="map">
//         <Canvas
//           bounds={bounds}
//           center={areaCenter}
//           zoom={14}
//           printVersion={true}
//           onInit={this.handleMapCanvasInit.bind(this)}
//         />

//         {mapCanvas && <AreaShape
//           mapCanvas={mapCanvas}
//           shape={areaShape}
//           edit={false}  
//         />}
        
//         <TrafficRoutes
//           mapCanvas={mapCanvas}
//           show={true}
//           routes={this.props.trafficRoutes}
          
//           routes2={mapData.traffic.routes}
//         />
//       </div>
//     );
//   }
// }

/**
 * Map the received store to properties that are passed to the component.
 */
// function mapStoreToProps(store: IStore): IStoreProps {
//   return {
//     areaCenter: store.area.center,
//     areaShape: store.area.shape,
//     trafficRoutes: store.traffic.routes
//   };
// }

// export default connect(mapStoreToProps)(Map);

export default TrafficRoutesMap;
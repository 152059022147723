import {IAction} from '../../interfaces/action';
import {
  FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS,
  REMOVE_ECOLOGY_LAYER_INFORMATION
} from '../../constants/actions';

/**
 * Handle the ecology layers
 */
export default function(
  state: GeoJSON.FeatureCollection | null = null,
  action: IAction
): GeoJSON.FeatureCollection | null {
  switch (action.type) {
    case FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS: {
      return action.geoJSON;
    }

    case REMOVE_ECOLOGY_LAYER_INFORMATION: {
      return null;
    }
    default:
      return state;
  }
}

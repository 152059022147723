import {toLonLat} from "ol/proj";

/**
 * Get the bounds of a shape
 */
export default function(
  shape: ol.coordinate[],
  buffer?: number
): ol.coordinate {
  const bounds: ol.coordinate = {
    east: -Infinity,
    north: -Infinity,
    south: Infinity,
    west: Infinity
  };

  shape.forEach(coordinate => {
    coordinate = toLonLat(coordinate, "EPSG:3857")
    if (coordinate[1] > bounds.north) {
      bounds.north = coordinate[1];
    }

    if (coordinate[1] < bounds.south) {
      bounds.south = coordinate[1];
    }

    if (coordinate[0] > bounds.east) {
      bounds.east = coordinate[0];
    }

    if (coordinate[0] < bounds.west) {
      bounds.west = coordinate[0];
    }
  });

  if (buffer) {
    const latDiff = (bounds.north - bounds.south) * buffer;
    const lngDiff = (bounds.east - bounds.west) * buffer;
    bounds.north = bounds.north + latDiff;
    bounds.south = bounds.south - latDiff;
    bounds.east = bounds.east + lngDiff;
    bounds.west = bounds.west - lngDiff;
  }

  return bounds;
}
